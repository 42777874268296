import { useContext, useEffect, useState } from 'react';
import { PresentationType } from '../types/presentation-types';
import { listPresentations as apiListPresentations } from '../../src/graphql/queries';
import { useTranslation } from 'react-i18next';
import { doGraphQlOperation } from '../utils/graphql-utils';
import { ErrorContext } from '../contexts/error-context';
import { parseApiErrors } from '../utils/parse-api-errors';

export type useKpiDashboardTypes = {
  allpresentationsList: PresentationType[]
};

export function useKpiDashboard(): useKpiDashboardTypes {
  const [allpresentationsList, setAllPresentationsList] = useState<
    PresentationType[]
  >([]);
  const { t } = useTranslation();

  const { setErrorDialogState } = useContext(ErrorContext);

  const getAllPresentations = async () => {
    try {
      const { data } = await doGraphQlOperation<{
        listPresentations: PresentationType[];
      }>(apiListPresentations, undefined, { errorOnEmpty: true });
      data && setAllPresentationsList(data?.listPresentations);
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.g000'),
        additionalMessages: parseApiErrors(err)
      });
    }
  };

  useEffect(() => {
    getAllPresentations();
    // eslint-disable-next-line
  }, []);

  return { allpresentationsList };
}
