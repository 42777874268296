import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductBundle } from '../API';
import { ErrorContext } from '../contexts/error-context';
import { createProductBundle as apiCreateProductBundle } from '../graphql/mutations';
import { doGraphQlOperation } from '../utils/graphql-utils';

const useProductBundle = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { setErrorDialogState } = useContext(ErrorContext);

  const createProductBundle = async (productName: string) => {
    try {
      if (productName) {
        setIsLoading(true);
        await doGraphQlOperation<{ createProductBundle: ProductBundle }>(apiCreateProductBundle, { productName: productName });
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b003'),
        additionalMessages: err,
      });
    }
  };
  return { isLoading, createProductBundle };
};

export default useProductBundle;
