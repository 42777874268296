import { createInstance } from "@datapunt/matomo-tracker-react";

export const MATOMO_URL_BASE = 'https://madhat.matomo.cloud/';

export const createMatomoInstance = () => createInstance({
  urlBase: MATOMO_URL_BASE,
  siteId: getMatomoSiteId(),
});

export function getMatomoSiteId() {
  const env = process.env.REACT_APP_ENV;
  return (
    env === 'prod'
      ? 15
      : env === 'stage'
        ? 14
        : 13
  );
}