import Auth, { CognitoUser } from '@aws-amplify/auth';
import { ICredentials } from '@aws-amplify/core';
import {
  AuthUser,
  ConfirmSmsCodeType,
  MfaTypes
} from '../types/auth-types';
import { AuthServiceType } from '../types/auth.service';

export class OnlinePresenterAuthService implements AuthServiceType {
  public async authCurrentUser() {
    return await Auth.currentAuthenticatedUser() as CognitoUser | undefined;
  }

  public async getCurrentCredentials() {
    return await Auth.currentCredentials() as ICredentials | undefined;
  }

  public async signIn(email: string, password: string) {
    return await Auth.signIn(email, password);
  }

  public async signOut() {
    await Auth.signOut();
  }

  public async confirmSmsCode({ user, code }: ConfirmSmsCodeType) {
    await Auth.confirmSignIn(user, code, 'SMS_MFA');
  }

  public async confirmTotpCode({ user, code }: ConfirmSmsCodeType) {
    await Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA');
  }

  public async newPasswordChallenge(user: AuthUser, newPassword: string) {
    return await Auth.completeNewPassword(user, newPassword);
  }

  public async verifyTotpToken(user: AuthUser, challengeAnswer: string) {
    await Auth.verifyTotpToken(user, challengeAnswer);
    await Auth.setPreferredMFA(user, 'TOTP');
  }

  public async selectPreferredMFA(user: any, mfaMethod: MfaTypes) {
    return await Auth.setPreferredMFA(user, mfaMethod);
  }
}