import { GlobalState } from 'little-state-machine';
import { PresentationType } from '../types/presentation-types';

export default function updatePresentation(
  state: GlobalState,
  payload: Partial<PresentationType>
) {
  return {
    auth: state.auth,
    presentationCreator: state.presentationCreator,
    product: state.product,
    configuratorState: state.configuratorState,
    presentation: {
      ...state.presentation,
      ...payload,
    },
  };
}
