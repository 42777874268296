import { useContext, useState } from 'react';
import { ProductContext } from '../../../contexts/product-context';
import { ProductType } from '../../../types/product-types';
import { Box, Theme, Typography, makeStyles } from '@material-ui/core';
import { HELLA_COLORS } from '../../../theme';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ProgressIndicator from '../../general/progress-indicator';

const useStyles = makeStyles((theme: Theme) => ({
  productContainer: {
    width: 550,
    height: '80%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginRight: 16,
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: HELLA_COLORS.grey4,
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#878587',
    },
    '&::-webkit-scrollbar-track': {
      background: HELLA_COLORS.grey7,
      borderRadius: '5px',
      boxShadow: 'inset 0px 0px 0px 0px #F0F0F0',
    },
  },

  productItem: {
    width: 420,
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    padding: '30px 0 4px 30px ',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.common.black,
      fontWeight: 900,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  selectedProductItem: {
    color: theme.palette.common.black,
    fontWeight: 900,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  headline: {
    height: 100,
    padding: 30,
  },
}));

type ProducsAccessibilityListProps = {
  handleSelectedProduct: (val: string) => void;
  selectedCategoryTab?: number;
  isProductListFetched?: boolean;
};

enum CategoryEnum {
  ELECTRONICS = 1,
  LIGHTING = 2,
}


const ProducsAccessibilityList = ({selectedCategoryTab, handleSelectedProduct, isProductListFetched} : ProducsAccessibilityListProps) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = useState<string | undefined>(undefined);
  const { getProductsAsArray } = useContext(ProductContext);

  const productsList = () => {
    switch (selectedCategoryTab) {
      case CategoryEnum.ELECTRONICS:
        return getProductsAsArray().filter(p => p.category === 'Electronics');
      case CategoryEnum.LIGHTING:
        return getProductsAsArray().filter(p => p.category === 'Lighting');
      default:
        return getProductsAsArray();
    }
  };

  return (
    <Box>
      <Typography
        variant="h4"
        color="textSecondary"
        className={classes.headline}
      >
        {t('adminGlobalProductAccessibility.productList.label')}
      </Typography>
      <Box className={classes.productContainer}>
        
        
        {isProductListFetched ? productsList().sort((p1, p2) => p1.displayName.localeCompare(p2.displayName))
          .map((p: ProductType, idx: number) => {
            return (
              <Typography
                key={idx}
                className={clsx(
                  classes.productItem,
                  selectedProduct === p.name && classes.selectedProductItem
                )}
                variant="h5"
                onClick={() => {
                  setSelectedProduct(p.name);
                  handleSelectedProduct(p.name);
                }}
              >
                {p.displayName}
                {p.secondDisplayName}
              </Typography>
            );
          }) :        
          <Box width='100%' height='100%'	display='flex' justifyContent='center' alignItems='center'>
            <ProgressIndicator />
          </Box>  
          }
      </Box>
    </Box>
  );
};

export default ProducsAccessibilityList;
