import { Credentials } from 'aws-sdk';
import React, { createContext, useState } from 'react';
import { useAuth } from '../hooks/use-auth';
import { SignInInput } from '../types/auth-types';
import { AuthUser, ConfirmSmsCodeType } from '../types/auth-types';

interface AuthState {
  userCredentials: Credentials | null;
  user: AuthUser | null;
  loading: boolean;
  signIn(input: SignInInput): Promise<void>;
  signOut(): Promise<void>;
  confirmSmsCode({ user, code }: ConfirmSmsCodeType): Promise<void>;
  confirmTotpCode({ user, code }: ConfirmSmsCodeType): Promise<void>;
  newPasswordChallenge(password: string): Promise<void>;
  mfaAttemptCounter: number;
  setMfaAttemptCounter: React.Dispatch<React.SetStateAction<number>>;
  checkAuth: ReturnType<typeof useAuth>['checkAuth'];
}

export const AuthContext = createContext<AuthState>({
  userCredentials: null,
  user: null,
  loading: true,
  signIn: async (input) => {},
  signOut: async () => {},
  confirmSmsCode: async () => {},
  confirmTotpCode: async () => {},
  newPasswordChallenge: async () => {},
  mfaAttemptCounter: 0,
  setMfaAttemptCounter: () => {},
  checkAuth: async () => undefined,
});

interface AuthProps {
  children: any;
}

const AuthContextProvider = ({ children }: AuthProps) => {
  const {
    userCredentials,
    user,
    loading,
    signIn,
    signOut,
    confirmSmsCode,
    confirmTotpCode,
    newPasswordChallenge,
    checkAuth,
  } = useAuth();

  const [mfaAttemptCounter, setMfaAttemptCounter] = useState(1);

  return (
    <AuthContext.Provider
      value={{
        userCredentials,
        user,
        loading,
        signIn,
        signOut,
        confirmSmsCode,
        confirmTotpCode,
        newPasswordChallenge,
        setMfaAttemptCounter,
        mfaAttemptCounter,
        checkAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
