import { GraphQLResult } from "@aws-amplify/api-graphql";
import API, { graphqlOperation } from "@aws-amplify/api-graphql";

export async function doGraphQlOperation<T>(
  operation: string,
  variables?: { [key: string]: any },
  options?: { errorOnEmpty: boolean }
) {
  try {
    const res = (await API.graphql(graphqlOperation(operation, variables))) as GraphQLResult<T>;
    if ((options?.errorOnEmpty && !res.data) && res.errors?.length) {
      // eslint-disable-next-line
      throw { errors: res.errors };
    }
    return res;
  } catch (error: any) {
    throw error;
  }
}