import { Box, makeStyles, Typography } from "@material-ui/core";
import AddIcon from '../../assets/img/add-circle-filled.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px dashed ${theme.palette.grey[400]}`,
    cursor: 'pointer',
  }
}));

type Props = {
  label?: string;
  onButtonClick?: () => void;
}

function AdminAddContentCard({ label, onButtonClick }: Props) {
  const classes = useStyles();

  return (
    <Box
      width="100%" height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={classes.container}
      onClick={() => onButtonClick?.()}
    >
      <img src={AddIcon} alt="Add intro" />
      <Box mt={10}>
        <Typography>
          {label}
        </Typography>
      </Box>
    </Box>
  )
}

export default AdminAddContentCard;
