import { Box, Button, makeStyles, Paper, Typography } from "@material-ui/core";
import { useState } from "react";
import ProgressIndicator from "../../components/general/progress-indicator";
import { releaseOfflinePresenterVersion } from "../../graphql/mutations";
import { doGraphQlOperation } from "../../utils/graphql-utils";

const useStyles = makeStyles((theme) => ({
  Paper: {
    width: 400,
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btn: {
    minWidth: 300,
  }
}));

export default function AdminReleaseOfflinePresenterVersionPage() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [success, setSuccess] = useState(false);

  const releaseVersion = async () => {
    setLoading(true);
    setErrors([]);
    setSuccess(false);
    const res = await doGraphQlOperation<{ releaseOfflinePresenterVersion: boolean }>(releaseOfflinePresenterVersion);
    if (res.errors?.length || !res.data?.releaseOfflinePresenterVersion) {
      setErrors(res.errors?.map(e => e.message) || ['Error']);
    } else if (!!res.data?.releaseOfflinePresenterVersion) {
      setSuccess(true);
    }
    setLoading(false);
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Paper className={classes.Paper}>
        <Button className={classes.btn} variant="contained" onClick={() => releaseVersion()}>
          {loading ? (
            <ProgressIndicator color="light" />
          ) : (
            'Release new version'
          )}
        </Button>
      </Paper>
      <Box mt={8}>
        {success && (
          <Typography color="secondary">
            Deployment successful!
          </Typography>
        )}
        {errors.map(e => (
          <Typography color="error">
            {e}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}