import { AccountRequestUser } from "../types/user-api-types";

export const removeDuplicateAccountRequestsByEmail = (arr:AccountRequestUser[]) => {
  const uniqueData: AccountRequestUser[] = arr.reduce((uniqueArr, obj) => {
    const isDuplicate = (uniqueArr as AccountRequestUser[]).some(item => item.email === obj.email);
    if (!isDuplicate) {
      (uniqueArr as AccountRequestUser[]).push(obj);
    }
    return uniqueArr;
  }, []);

  return uniqueData;
};