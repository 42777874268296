import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Input,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HELLA_COLORS } from '../../../theme';
import SearchIcon from '../../../../src/assets/img/admin/Symbol_Search.svg';
import DeleteIcon from '../../../../src/assets/img/admin/delete-icon.svg';
import AddUserIcon from '../../../../src/assets/img/admin/Symbol_addUser.svg';
import { useState } from 'react';
import { User } from '../../../API';
import AdminAddProductToUserDialog from './admin-add-product-to-user-dialog';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  searchButton: {
    width: 46,
    height: 46,
    '& > span > svg > rect': {
      stroke: 'none',
    },
    '& > span > svg > g > path': {
      stroke: HELLA_COLORS.grey1,
    },
  },
  searchInput: {
    '&:hover > div > button': {
      opacity: 1,
    },
  },
  textFieldGrid: {
    alignSelf: 'center',
    minWidth: 300,
  },
  deleteIcon: {
    marginRight: 20,
    opacity: 0.2,
    pointerEvents: 'none',
  },
  deleteIconActive: {
    marginRight: 20,
    opacity: 1,
    cursor: 'pointer'
  },
  addUserButton: {
    background: HELLA_COLORS.grey2,
    '&:hover': {
       background: HELLA_COLORS.grey2,
    },
    '& > span > svg > g > rect': {
      stroke: 'none',
    },
  },
  addUserButtonDisabled: {
    background: HELLA_COLORS.grey2,
    opacity: 0.3,
    pointerEvents: 'none'
  },
  addUserIcon: {
    position: 'relative',
    top: '-1px',
    left: '-2px',
    '& > g > g > path': {
      strokeWidth: 0,
      fill: theme.palette.common.white,
    },
    '& > g > path': {
      strokeWidth: 0,
      fill: theme.palette.common.white,
    },
  },
  headline:{
    paddingLeft: 32
  }
}));

type ProductAccessibilityActionsProps = {
  handleSearchUser: (param: string) => void;
  handleDeleteUsers: ()=> void
  usersList: User[] | undefined;
  selectedProduct: string | undefined;
  isDeleteUserPossible?: boolean;
  searchInputText: string;
};
const ProductAccessibilityActions = ({
  handleSearchUser,
  handleDeleteUsers,
  usersList,
  selectedProduct,
  isDeleteUserPossible =  false,
  searchInputText
}: ProductAccessibilityActionsProps) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  return (
    <Box display="flex" flexDirection="column" >
      <Typography
        variant="h4"
        color="textSecondary"
        className={classes.headline}
      >
        {t('adminGlobalProductAccessibility.usersList.label')}
      </Typography>

      <Box paddingTop={16} paddingBottom={16}>
        <Box  display='flex' paddingLeft={12}  paddingRight={24}>  
          <Grid
            container
            spacing={1}
            alignItems="flex-end"
            className={classes.searchInput}
          >
            <Grid item>
              <IconButton
                className={classes.searchButton}
                size="small"
                disableRipple
              >
                <img src={SearchIcon} alt="SearchIcon" />
              </IconButton>
            </Grid>
            <Grid item className={classes.textFieldGrid}>
              <FormControl fullWidth>
                <Input
                 value={searchInputText}
                  fullWidth
                  id="searchInput"
                  onChange={(e) => handleSearchUser(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>

         { 
         <IconButton className={clsx(isDeleteUserPossible ? classes.deleteIconActive: classes.deleteIcon)} size="small" onClick={handleDeleteUsers}>
            <img src={DeleteIcon} alt="DeleteIcon" />
          </IconButton>}

          {<IconButton className={clsx(!selectedProduct ? classes.addUserButtonDisabled: classes.addUserButton)}  disableRipple onClick={() => setCreateDialogOpen(true)}>
            <img
              src={AddUserIcon}
              alt="AddUserIcon"
              className={classes.addUserIcon}
            />
          </IconButton>}
        </Box>
      </Box>

       
       <AdminAddProductToUserDialog
        selectedProduct = {selectedProduct}
        usersList={createDialogOpen ? usersList: []}
        open={createDialogOpen}
        onHandleClose = {() => setCreateDialogOpen(false)}
        onSubmit={()=> {}}        
      />
    </Box>
  );
};

export default ProductAccessibilityActions;
