import API, { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateUserInput,
  RequestAccountInput,
  RequestReactivationInput,
  UpdateUserInput,
  User as ApiUser
} from '../API';
import { ErrorContext } from '../contexts/error-context';
import {
  createUser as apiCreateUser,
  deleteAccountRequest as apiDeleteAccountRequest, deleteReactivationRequest as apiDeleteReactivationRequest, deleteUser as apiDeleteUser,
  enableUser as apiEnableUser,
  requestAccount,
  requestReactivation as apiRequestReactivation, resetUserMfa as apiResetUserMfa,
  resetUserPassword as apiResetUserPassword,
  updateUser as apiUpdateUser
} from '../graphql/mutations';
import {
  listAccountRequests as apiListAccountRequests,
  listUsers as apiListUsers
} from '../graphql/queries';
import {
  AccountRequestUser,
  BaseUser, CurrentSortProp,
  UserFilterProps
} from '../types/user-api-types';
import { doGraphQlOperation } from '../utils/graphql-utils';
import { parseApiErrors } from '../utils/parse-api-errors';
import { removeDuplicateAccountRequestsByEmail } from '../utils/remove-duplicate-items-array';

export enum UserRole {
  DEFAULT = '',
  HellaPresenterSuperAdmins = 'Product Owner',
  HellaPresenterAdmins = 'Power User',
  HellaPresenterContributors = 'Contributor',
  HellaPresenterEmployees = 'Employee',
}

export enum UserStatus {
  UNCONFIRMED = 'UNCONFIRMED',
  CONFIRMED = 'CONFIRMED',
  ARCHIVED = 'ARCHIVED',
  COMPROMISED = 'COMPROMISED',
  DISABLED = 'DISABLED',
  UNKNOWN = 'UNKNOWN',
  RESET_REQUIRED = 'RESET_REQUIRED',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
}

export const cognitoErrorToI18nKeyMap = {
  TooManyRequestsException: 'error.TooManyRequestsException',
  NotAuthorizedException: 'error.NotAuthorizedException',
  UsernameExistsException: 'error.UsernameExistsException',
  UnsupportedUserStateException: 'error.UnsupportedUserStateException',
  CodeDeliveryFailureException: 'error.CodeDeliveryFailureException',
  LimitExceededException: 'error.LimitExceededException',
};

export type UseUserApiResponse = ReturnType<typeof useUserApi>;

type Options = {
  unauthenticated?: boolean;
  disableUseEffect?: boolean;
};

export function useUserApi(options?: Options) {
  const { t } = useTranslation();
  const [users, setUsers] = useState<BaseUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<BaseUser[]>(users);
  const defaultFilter = {
    region: [],
    businessDivision: [],
    department: [],
    productCenter: [],
    userRole: [],
    status: [],
  };
  const [filter, setFilter] = useState<UserFilterProps>(defaultFilter);
  const [isUserlistLoading, setIsUserlistLoading] = useState(false);
  const [currentSortField, setCurrentSortField] = useState<CurrentSortProp>();
  const [searchInput, setSearchInput] = useState('');
  const [accountRequests, setAccountRequests] = useState<AccountRequestUser[]>([]);
  const { setErrorDialogState } = useContext(ErrorContext);

  // const getApiErrors = (err: any): ApiError => {
  //   return {
  //     errors:
  //       (err as any)?.errors?.map((e: any) => ({
  //         type: e.errorType,
  //         message: (cognitoErrorToI18nKeyMap as any)[e.errorType as string]
  //           ? t(
  //               `adminUserApi.${
  //                 (cognitoErrorToI18nKeyMap as any)[e.errorType as string]
  //               }`
  //             )
  //           : e.message,
  //       })) || [],
  //   };
  // };

  /** User CRUD */

  const getUsers = async () => {
    try {
      setIsUserlistLoading(true);
      const { data } = await doGraphQlOperation<{ listUsers: ApiUser[] }>(apiListUsers, undefined, { errorOnEmpty: true });
      if (data?.listUsers) {
        setUsers(
          data.listUsers.map<BaseUser>((user) => ({
            ...user,
            productCenter:
              user.productCenter ||
              t('adminUserManagement.field.productCenter.others'),
            status: user.enabled ? (user.status as UserStatus) : UserStatus.DISABLED,
            role:
              (user.role as UserRole) || UserRole.HellaPresenterContributors,
            creationDate: user.creationDate
              ? new Date(user.creationDate).toLocaleDateString()
              : null,
            creationDateRaw: user.creationDate ? new Date(user.creationDate) : undefined,
            enabled: user.enabled || undefined
          }))
        );
      }
      setIsUserlistLoading(false);
      return data?.listUsers;
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.f000'),
        additionalMessages: parseApiErrors(err)
      });
    }
  };

  const createUser = async (user: CreateUserInput) => {
    try {
      await doGraphQlOperation(apiCreateUser, { user }, { errorOnEmpty: true });
      getUsers();
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.f001'),
        additionalMessages: parseApiErrors(err)
      });
    }
  };

  const updateUser = async (userId: string, user: UpdateUserInput) => {
    try {
      await doGraphQlOperation(apiUpdateUser, { user, userId }, { errorOnEmpty: true });
      getUsers();
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.f002'),
        additionalMessages: parseApiErrors(err)
      });
    }
  };

  const deleteUser = async (userId: string) => {
    try {
      await doGraphQlOperation(apiDeleteUser, { userId }, { errorOnEmpty: true });
      getUsers();
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.f003'),
        additionalMessages: parseApiErrors(err)
      });
    }
  };

  const enableUser = async (userId: string, reactivationRequestId?: string) => {
    try {
      await doGraphQlOperation(apiEnableUser, { userId }, { errorOnEmpty: true });
      if (reactivationRequestId) {
        await deleteReactivationRequest(reactivationRequestId);
      }
      getUsers();
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.f009'),
        additionalMessages: parseApiErrors(err)
      });
    }
  };
  
  /** User Reset */
  const resetUserPassword = async (userId: string) => {
    try {
      await doGraphQlOperation(apiResetUserPassword, { userId }, { errorOnEmpty: true });
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.f004'),
        additionalMessages: parseApiErrors(err)
      });
    }
  };

  const resetUserTotp = async (userId: string) => {
    try {
      await doGraphQlOperation(apiResetUserMfa, { userId }, { errorOnEmpty: true });
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.f005'),
        additionalMessages: parseApiErrors(err)
      });
    }
  };

  /** AccountRequest CRUD */
  async function requestUserAccount(user: RequestAccountInput) {
    try {
      await API.graphql({
        query: requestAccount,
        variables: { account: user },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.f006'),
        additionalMessages: parseApiErrors(err)
      });
    }
  }


  const getAccountRequests = async () => {
    try {
      const { data } = await doGraphQlOperation<{ listAccountRequests: AccountRequestUser[] }>(apiListAccountRequests, undefined, { errorOnEmpty: true });
      if (data?.listAccountRequests) {

        const uniqueAccountRequests: AccountRequestUser[] = removeDuplicateAccountRequestsByEmail(data?.listAccountRequests);

        setAccountRequests(
          uniqueAccountRequests.map((r) => ({
            ...r,
            role: UserRole.HellaPresenterEmployees,
            productCenter:
              r.productCenter ||
              t('adminUserManagement.field.productCenter.others'),
          }))
        );
      }
      return data?.listAccountRequests;
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.f007'),
        additionalMessages: parseApiErrors(err)
      });
    }
  };

  // const acceptAccountRequest = async (accountRequestId: string) => {
  //   try {
  //     await API.graphql(graphqlOperation(apiAcceptAccountRequest, { accountRequestId }));
  //   } catch (err) {
  //     throw getApiErrors(err);
  //   }
  // }

  const deleteAccountRequest = async (accountRequestId: string) => {
    try {
      await doGraphQlOperation(apiDeleteAccountRequest, { accountRequestId }, { errorOnEmpty: true });
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.f008'),
        additionalMessages: parseApiErrors(err)
      });
    }
  };

  /** Reactivation Request */

  async function requestUserReactivation(user: RequestReactivationInput) {
    try {
      const request =  await API.graphql({
        query: apiRequestReactivation,
        variables: { account: user },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      if((request as any)?.data?.requestReactivation?.requestTyp){
        setErrorDialogState({
        open: true,
        errorSymbol: false,
        infoMessage: t('login.confirmedReactivationRequest'),
      });
     }


    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.f010'),
        additionalMessages: parseApiErrors(err)
      });
    }
  }

  const deleteReactivationRequest = async (reactivationRequestId: string) => {
    try {
      await doGraphQlOperation(apiDeleteReactivationRequest, { reactivationRequestId }, { errorOnEmpty: true });
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.f011'),
        additionalMessages: parseApiErrors(err)
      });
    }
  };

  /** User management dashboard */

  const addUsersFilter = (field: keyof UserFilterProps, value: string) => {
    const alreadySelected = !!filter[field]?.find(
      (v) => v === value.replace(/ /g, '')
    );

    if (!alreadySelected) {
      const currentFilterArr = filter[field];
      currentFilterArr?.push(value.replace(/ /g, '') as any);
      const newFilter = { ...filter, [field]: currentFilterArr };
      setFilter(newFilter);
    }
  };

  const deleteUsersFilter = (field: keyof UserFilterProps, value: string) => {
    const foundIdx = filter[field]?.findIndex((v) => v === value);
    if (foundIdx !== undefined && foundIdx > -1) {
      filter[field]?.splice(foundIdx, 1);
      setFilter({ ...filter });
    }
  };

  const filterUsers = () => {
    const searchInputLower = searchInput?.toLowerCase();
    if (
      Object.keys(filter).filter(
        (f) => !!filter?.[f as keyof UserFilterProps]?.length
      ).length
    ) {
      setFilteredUsers(
        users?.filter((u) => {
          if (
            (!filter.region.length ||
              (u.region && filter.region.includes(u.region))) &&
            (!filter.businessDivision.length ||
              (u.businessDivision &&
                filter.businessDivision.includes(u.businessDivision))) &&
            (!filter.department.length ||
              (u.department && filter.department.includes(u.department))) &&
            (!filter.productCenter.length ||
              (u.productCenter &&
                filter.productCenter.includes(u.productCenter))) &&
            (!filter.userRole?.length ||
              (u.role && filter.userRole?.includes(u.role))) &&
            (!filter.status.length ||
              (u.status && filter.status.includes(u.status)))
          ) {
            if (searchInputLower) {
              return (
                u.email?.toLowerCase().includes(searchInputLower) ||
                u.firstName?.toLowerCase().includes(searchInputLower) ||
                u.lastName?.toLowerCase().includes(searchInputLower)
              );
            }
            return true;
          }
          return false;
        })
      );
    } else {
      setFilteredUsers(
        searchInputLower
          ? users.filter(
            (u) =>
              u.email?.toLowerCase().includes(searchInputLower) ||
              u.firstName?.toLowerCase().includes(searchInputLower) ||
              u.lastName?.toLowerCase().includes(searchInputLower)
          )
          : users
      );
    }
    setCurrentSortField(undefined);
  };

  const resetFilter = (specificField?: keyof UserFilterProps) => {
    if (specificField) {
      filter[specificField] = [];
      setFilter({ ...filter });
    } else {
      setFilter(defaultFilter);
    }
  };

  const sortUsers = (field: keyof BaseUser, direction: 'asc' | 'desc') => {
    const searchField = field !== 'name' ? field : 'lastName';
    if (field === 'creationDate') {
      filteredUsers.sort((a, b) => (
        direction === 'asc'
          ? (a.creationDateRaw?.getTime() || Date.now()) - (b.creationDateRaw?.getTime() || Date.now())
          : (b.creationDateRaw?.getTime() || Date.now()) - (a.creationDateRaw?.getTime() || Date.now())
      ));
    } else {
      filteredUsers.sort((a, b) => {
        return direction === 'asc'
          ? (a[searchField] || '').localeCompare(b[searchField] || '')
          : (b[searchField] || '').localeCompare(a[searchField] || '');
      });
    }
    setFilteredUsers([...filteredUsers]);
    setCurrentSortField({ field: field, direction: direction });
  };

  useEffect(() => {
    if (!options?.unauthenticated && !options?.disableUseEffect && users) {
      filterUsers();
    }
    // eslint-disable-next-line
  }, [users, filter, searchInput]);

  useEffect(() => {
    if (!options?.unauthenticated && !options?.disableUseEffect) {
      try {
        getUsers();
        getAccountRequests();
      } catch (err) {
        console.error('API error');
      }
    }
    // eslint-disable-next-line
  }, []);

  return {
    users,
    filteredUsers,
    filter,
    isUserlistLoading,
    searchInput,
    currentSortField,
    accountRequests,
    getUsers,
    createUser,
    updateUser,
    deleteUser,
    resetUserPassword,
    resetUserTotp,
    requestUserAccount,
    getAccountRequests,
    deleteAccountRequest,
    addUsersFilter,
    deleteUsersFilter,
    resetFilter,
    sortUsers,
    setSearchInput,
    enableUser,
    requestUserReactivation,
    deleteReactivationRequest,
  };
}
