import { Box, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import DashboardTracking from '../../components/admin/kpi-dashboard-view/dashboard-tracking';
import CustomTab from '../../components/general/custom-tab';
import CustomTabs from '../../components/general/custom-tabs';
import AdminPageLayout from '../../components/Layouts/admin-page-layout';
import { UserStatus, useUserApi } from '../../hooks/use-user-api';
import { FadeInAnimation } from '../../theme';
import { BaseUser } from '../../types/user-api-types';
import { ReactComponent as LoadingIcon } from '../../assets/img/admin/Symbol_loading.svg';
import {
  ProductRateList,
  UsersBusinessDivisionType,
  CountryList,
  productCenterList,
  BusinessGroupType,
  BusinessGroupEnum,
} from '../../types/kpi-dashboard-types';
import { useKpiDashboard } from '../../hooks/use-kpi-dashboard-api';
import { PresentationType } from '../../types/presentation-types';
import { ProductContext } from '../../contexts/product-context';
import { useUserAttributes } from '../../hooks/use-user-attributes';
import { handleTimeTracking } from '../../utils/kpi-dashboard-utils';

const useStyles = makeStyles((theme: Theme) => ({
  tabsContainer: {
    width: '100%',
    marginBottom: theme.spacing(24),
  },
  contentRoot: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    margin: 'auto',
    paddingBottom: '50px',
  },
  loadingIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 60,
    height: 60,
    animation: `$rotate ease-in-out 1000ms infinite`,
    fill: 'white',
  },
  cardWrapper: {
    minWidth: '600px',
    padding: '20px',
    marginRight: '10px',
    paddingTop: '0',
    paddingLeft: '0',
    height: 'fit-content',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  fadeIn: {
    animation: '$fadeIn 1.0s ease-in-out forwards',
  },
  '@keyframes fadeIn': FadeInAnimation,
}));

const AdminKpiDashboardView = () => {

  const {businessDivision, businessNeed, department} = useUserAttributes();

  const electronicsValue = 'HELLA OE: Electronics';
  const lightingValue = 'HELLA OE: Lighting';

   const electronicsOptions = department.select.filter((option) => {
    if (option.dependsOn) {
      return option.dependsOn.some((dependency) => dependency.item === 'businessDivision' && dependency.value === electronicsValue);
    }
    return false;
  }).map((option) => option.name);
  
  const lightingOptions = department.select.filter((option) => {
    if (option.dependsOn) {
      return option.dependsOn.some((dependency) => dependency.item === 'businessDivision' && dependency.value === lightingValue);
    }
    return false;
  }).map((option) => option.name);


  const { getProductsAsArray } = useContext(ProductContext);
  const { allpresentationsList } = useKpiDashboard();
  const classes = useStyles();
  const { users, isUserlistLoading } = useUserApi();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const changeVisibleProducts = (index: number) => setSelectedTab(index);

  const handleUsersPerTime = (period: string) => users && handleTimeTracking('users', users, period);

  const handleUsersBusinessDivision = () => {
    let usersBusinessDivision: UsersBusinessDivisionType[] = [];
    businessDivision?.select?.map((el) => {

      const sizeBusinessDivision = users?.filter((u: BaseUser) => u?.businessDivision === el.name).length;
      const mainBusinessNeed = businessNeed.select.map((dpt) => users?.filter((u: BaseUser) => u.businessNeed === dpt.name && u.businessDivision === el.name));
      const usersBusinessGroupLighting = users.filter(u => u.businessDivision === 'HELLA OE: Lighting'); 
      const usersByBusinessGroupElectronics = users.filter(u => u.businessDivision === 'HELLA OE: Electronics'); 
         
      const evalueMainBusinessNeedTotalSize = () => {
        let list: productCenterList[] = [];
        let n = 0;
        businessNeed.select.forEach(b => {
            list.push({
               dpt: b.name === 'Customer Presentation - Technology deep-dive' ? 'Customer Presentation - Deep-dive':  b.name,
               n:  n + users.filter(pc => pc.businessNeed === b.name).length
            });
       });
       return list;
      }
    
      const dptListByBusinessGroup = (businessDivision: BusinessGroupType) => {
        let dptList: productCenterList[] = [];
        (businessDivision === BusinessGroupEnum.Lighting ? lightingOptions: electronicsOptions).forEach(lighting => {
          dptList.push({
                dpt: lighting,
                n:  (businessDivision === BusinessGroupEnum.Lighting  ? usersBusinessGroupLighting: usersByBusinessGroupElectronics).filter(el => el.department === lighting).length
             });
        });
        return dptList;
      }

      const dptListLighting = dptListByBusinessGroup(BusinessGroupEnum.Lighting );
      const dptListElectronics = dptListByBusinessGroup(BusinessGroupEnum.Electronics);
      const mainBusinessNeedList  =  evalueMainBusinessNeedTotalSize();

      return usersBusinessDivision?.push(
        ...[
          {
            label: el.name,
            sizeBusinessDivision: sizeBusinessDivision,
            businessNeed: mainBusinessNeed,
            dptListLighting,
            dptListElectronics,
            mainBusinessNeedList
          },
        ]
      );
    });
    return usersBusinessDivision;
  };
  
  //  users per region
  const qtdUserPerRegion = (r: string) =>   users?.filter((u: BaseUser) => u.region === r).length;
  const activeUser = () => users?.filter((u: BaseUser) => u.status === UserStatus.CONFIRMED).length;

  const usersRegionList = (region: string, countriesPerRegion: string[]) => {
    let usersSum: number = 0;
    let usersPerCountryList: CountryList[] = [];
    countriesPerRegion.forEach((region: string, idx: number) => {
      usersSum = usersSum + qtdUserPerRegion(countriesPerRegion[idx])!;
      usersPerCountryList.push(
        ...[
          {
            country: countriesPerRegion[idx],
            n: qtdUserPerRegion(countriesPerRegion[idx]),
          },
        ]
      );
    });
    return { region: region, list: usersPerCountryList, total: usersSum };
  };

  const asiaCountries: string[] = [
    'China',
    'India',
    'Japan',
    'Korea',
    'Others',
  ];
  const europeCountries: string[] = ['Europe'];
  const northAmericaCountries: string[] = ['USA', 'Mexico', 'Others'];
  const usersAsiaRegion = usersRegionList('Asia', asiaCountries);
  const usersEuropeRegion = usersRegionList('Europe', europeCountries);
  const usersAmericaRegion = usersRegionList('North and South America', northAmericaCountries);


  // ************************/
  // presentations Tracking
  //************************/  
  
  // presentations general
  const handlePresentationsPerTime = (period: string) => handleTimeTracking('presentations', allpresentationsList, period);

  // custom content
  const customContentsSize = () => {
    let sum: number = 0;
    let pdfSum: number = 0;
     allpresentationsList.forEach((p: PresentationType, idx: number) => {
      const productWithCustomContents = allpresentationsList[idx].products.filter((p) => p?.customContents?.length !== 0);
      const customContents = productWithCustomContents[0]?.customContents?.length || 0;
      sum = sum + customContents;
      const pdf = productWithCustomContents[0]?.customContents?.filter((c) => c?.type === 'pdf')?.length || 0;
      pdfSum = pdfSum + pdf;
    });
    return { pdfSum, sum };
  };

  // intros
  const presentationWithoutIntros = allpresentationsList.filter((p) => p?.introAssets?.length !== 0);
  const presentationWithIntros =  allpresentationsList.length - presentationWithoutIntros?.length;

  // product rate
  const qtdProduct = (product: string) => {
    let sum: number = 0;
    allpresentationsList.forEach((p: PresentationType, idx: number) => {
      const productFiltered = allpresentationsList[idx]?.products?.filter((p) => p?.productName === product);
      sum = sum + productFiltered?.length || 0;
    })
    return sum;
  };

  const productRate = () => {
    const productList = getProductsAsArray().map(p => p.name);
    let productRate: number = 0;
    let productRateList: ProductRateList[] = [];
    for (let i = 0; i < productList.length; i++) {
      productRate = productRate + qtdProduct(productList[i])!;
      productRateList.push(
        ...[{ product: productList[i], n: qtdProduct(productList[i]) }]
      );
    }
    return productRateList;
  };


  enum DashboardTabs {
    'User Tracking' = 0,
    'Presentations' = 1,
  }

  const UserTracking = () => {
    const totalUsers =
      usersAsiaRegion.total +
      usersEuropeRegion.total +
      usersAmericaRegion.total;

      const [selectUserTimeTracking, setSelectUserTimeTracking] = useState('MONTH');

    return (
      <DashboardTracking
        dashboardName="UserTracking"
        userTotal={users?.length}
        userActive={activeUser()}
        userNew={handleUsersPerTime(selectUserTimeTracking)}
        handleSelectUserTimeTracking={(val: string)=> setSelectUserTimeTracking(val)}
        usersBusinessDivision={handleUsersBusinessDivision()}
        asiaList={usersAsiaRegion}
        europeList={usersEuropeRegion}
        northAmericaList={usersAmericaRegion}
        totalUsersRegion={totalUsers}
      />
    );
  };


  const PresentationsTracking = () => {
    const [selectPresentaionTimeTracking, setSelectPresentationTimeTracking] = useState('MONTH');
    return (
      <DashboardTracking
        dashboardName="Presentations"
        presentationTotal={allpresentationsList.length}
        presentationNew={handlePresentationsPerTime(selectPresentaionTimeTracking)}
        handleSelectUserTimeTracking={(val: string)=> setSelectPresentationTimeTracking(val)}
        presentationWithoutIntros={presentationWithoutIntros?.length}
        presentationWithIntros={presentationWithIntros}
        customContentTotal={customContentsSize().sum}
        customContentPdfTotal={customContentsSize().pdfSum}
        productRateList={productRate()}
      />
    );
  };

  const RenderDashboard = () =>
    selectedTab === 0 ? <UserTracking /> : <PresentationsTracking />;
  const LoadingIndicator = () => (
    <LoadingIcon className={classes.loadingIcon} />
  );

  return (
    <>
      <AdminPageLayout
        headline={'KPI Dashboard'}
        description={'Summary report'}
      >
        <>
          {isUserlistLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Box className={clsx(classes.tabsContainer, classes.fadeIn)}>
                <CustomTabs
                  value={selectedTab}
                  onChange={(event, value: number) => {
                    changeVisibleProducts(
                      value === 0
                        ? DashboardTabs['User Tracking']
                        : DashboardTabs['Presentations']
                    );
                  }}
                  fullWidth
                  spacing={60}
                  fontcolor={'white'}
                >
                  <CustomTab label={'User Tracking'} />
                  <CustomTab label={'Presentations'} />
                </CustomTabs>
              </Box>

              <Box className={clsx(classes.contentRoot, classes.fadeIn)}>
                <RenderDashboard />
              </Box>
            </>
          )}
        </>
      </AdminPageLayout>
    </>
  );
};

export default AdminKpiDashboardView;
