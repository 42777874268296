import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HELLA_COLORS } from '../../../theme';
import { ListRegions, ProductRateList, UsersBusinessDivisionType } from '../../../types/kpi-dashboard-types';
import TrackingCard from './user-tracking-card';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    minHeight: 600,
    padding: 25,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: HELLA_COLORS.white,
  },
  gridContainer: {
    height: '100%',
  },
  gridItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  departmentContainer:{
    height: 250,
    marginTop: 20,
    overflowX: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.common.black,
      borderRadius: '0px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#878587',
    },
    '&::-webkit-scrollbar-track': {
      background: '#A8A6A8',
      borderRadius: '0px',
      boxShadow: 'inset 0px 0px 0px 0px #F0F0F0',
    },
  }
}));

type DashboardTrackingProps = {
  dashboardName: string | undefined;
  userTotal?: number;
  userActive?: number;
  userNew?: number;
  presentationTotal?: number;
  presentationNew?: number;
  usersBusinessDivision?: UsersBusinessDivisionType[] | undefined;
  presentationWithoutIntros?: number;
  presentationWithIntros?: number;
  customContentTotal?: number;
  customContentPdfTotal?: number;
  asiaList?: ListRegions;
  europeList?: ListRegions;
  northAmericaList?: ListRegions;
  totalUsersRegion?: number;
  productRateList?: ProductRateList[];
  handleSelectUserTimeTracking?: (param: string) => void;
};

const DashboardTracking = ({
  dashboardName,
  userTotal,
  userActive,
  userNew,
  usersBusinessDivision,
  presentationTotal,
  presentationNew,
  presentationWithoutIntros,
  presentationWithIntros,
  customContentTotal,
  customContentPdfTotal,
  asiaList,
  europeList,
  northAmericaList,
  totalUsersRegion,
  productRateList,
  handleSelectUserTimeTracking
}: DashboardTrackingProps) => {
  const classes = useStyles();

  const methods = useForm<any>();
  const {register, formState: { errors }, } = methods;
  const [selectBusinessDivision, setSelectBusinessDivision] = useState('HELLA OE: Electronics');

  const [selectUserTimeTracking, setSelectUserTimeTracking] = useState('MONTH');
  const [selectPresentationTimeTracking, setSelectPresentationTimeTracking] = useState('MONTH');


  type DptTypes = {
    dpt: string | undefined;
    n: number | undefined;
  };
  type MenuProps = {
    list: any;
  };
  function MenuList({ list }: MenuProps) {
    return list?.map((el: DptTypes, idx: number) => {
      return (
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          paddingRight="20px"
          key={idx}
        >
          <Typography key={idx} variant="h5" style={{ marginTop: 8 }}>
            {el?.dpt!}
          </Typography>
          <Typography key={idx} variant="h4" style={{ marginTop: 8 }}>
            {el?.n!}
          </Typography>
        </Box>
      );
    });
  }

  return dashboardName === 'UserTracking' ? (
    <Box sx={{ width: '100%' }} className={classes.container}>
      <Grid container spacing={10} className={classes.gridContainer}>
        <Grid item xs={6} className={classes.gridItems}>
          <Box display='flex' justifyContent='flex-end' width='100%' position='relative'>
          <TrackingCard
            card="generalActivity"
            title="General Activity"
            userTotal={userTotal}
            userActive={userActive}
            userNew={userNew}
          />
           <FormProvider {...methods}>
                    <form
                      style={{
                        marginRight: 20,
                        marginTop: 12,
                        position: 'absolute',
                      }}
                      >
                      <FormControl className={classes.formConttrol}>
                        <Select
                          MenuProps={{
                            disableScrollLock: true,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          {...register('userTimeTracking')}
                          value={selectUserTimeTracking}
                          onChange={(e: any) => {
                            handleSelectUserTimeTracking!(e.target.value);
                            setSelectUserTimeTracking(e.target.value)
                          }}
                          >
                          {['MONTH', 'QUATER', 'YEAR'].map(
                            (t: string, idx: number) => {
                              return (
                                <MenuItem
                                  key={idx}
                                  value={t}
                                  className={classes.listItem}
                                >
                                  {t}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </form>
                  </FormProvider>
          </Box>
        </Grid>

        <Grid item xs={6} className={classes.gridItems}>
          <TrackingCard
            card="region"
            title="Region"
            asiaList={asiaList}
            europeList={europeList}
            northAmericaList={northAmericaList}
            totalUsersRegion={totalUsersRegion}
          />
        </Grid>

        <Grid item xs={6} className={classes.gridItems}>
          <TrackingCard
            card="businessDivision"
            title="Business Group"
            businessDivision={usersBusinessDivision}
          />
        </Grid>

        <Grid item xs={6} className={classes.gridItems}>
          <Grid
            container
            className={classes.gridContainer}
            style={{ height: 256 }}
          >    

            <Grid item xs={6}>
              <Box
                style={{
                  backgroundColor: HELLA_COLORS.grey10,
                  borderRight: '9px solid white',
                }}
                width="100%"
                height={252}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  height="100%"
                  style={{ padding: '20px 0px 20px 20px' }}
                >
                  <Box display='flex' justifyContent='space-between'>
                  <Typography variant="h4">Department</Typography>
                  <FormProvider {...methods}>
                    <form
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '150px',
                        marginRight: 16
                      }}
                      >
                      <FormControl className={classes.formConttrol}>
                        <InputLabel>
                          <Typography variant="body1">
                            Business Group
                          </Typography>
                        </InputLabel>
                        <Select
                          MenuProps={{
                            disableScrollLock: true,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          {...register('businessDivision')}
                          value={selectBusinessDivision}
                          onChange={(e: any) => setSelectBusinessDivision(e.target.value)}
                          >
                          {['HELLA OE: Lighting', 'HELLA OE: Electronics'].map(
                            (b: string, idx: number) => {
                              return (
                                <MenuItem
                                  key={idx}
                                  value={b}
                                  className={classes.listItem}
                                >
                                  {b}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                        {selectBusinessDivision.length === 0 &&
                          errors.businessDivision && (
                            <Typography
                            variant="body1"
                            className={classes.error}
                            >
                              {errors.businessDivision.message?.toString()}
                            </Typography>
                          )}
                      </FormControl>
                    </form>
                  </FormProvider>
                 </Box>
                  <Box marginTop="38px" position="abolute" className={classes.departmentContainer}>
                    {selectBusinessDivision === 'HELLA OE: Lighting' && usersBusinessDivision &&  <MenuList  list={usersBusinessDivision![0].dptListLighting}/>}
                    {selectBusinessDivision === 'HELLA OE: Electronics' && usersBusinessDivision && <MenuList  list={usersBusinessDivision![0].dptListElectronics}/>}                 
                  </Box>
                </Box>
              </Box>
            </Grid>
            
            <Grid item xs={6}>
              <Box
                style={{
                  backgroundColor: HELLA_COLORS.grey10,
                  borderRight: '9px solid white',
                  marginLeft: 9
                }}
                width="100%"
                height={252}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  padding="20px"
                >
                  <Typography variant="h4">Main Business Need</Typography>
                </Box>
                <Box
                  width="100%"
                  height="100px"
                  style={{
                    paddingLeft: 20,
                    marginTop: 10,
                  }}
                >
                {selectBusinessDivision === 'HELLA OE: Lighting' && usersBusinessDivision &&
                   <MenuList list={usersBusinessDivision[0].mainBusinessNeedList} />
                }
                {selectBusinessDivision === 'HELLA OE: Electronics'  && usersBusinessDivision &&
                   <MenuList list={usersBusinessDivision[0].mainBusinessNeedList} />
                }
                </Box>
              </Box>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box sx={{ width: '100%' }} className={classes.container}>
      <Grid container spacing={10} className={classes.gridContainer}>
        <Grid
          item
          xs={6}
          className={classes.gridItems}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box display='flex' justifyContent='flex-end' width='100%' position='relative'>
            <TrackingCard
              card="generalActivityPresentations"
              title="General Activity"
              presentationTotal={presentationTotal}
              presentationNew={presentationNew}
              />

              <FormProvider {...methods}>
                    <form
                      style={{
                        marginRight: 20,
                        marginTop: 12,
                        position: 'absolute',
                      }}
                      >
                      <FormControl className={classes.formConttrol}>
                        <Select
                          MenuProps={{
                            disableScrollLock: true,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          {...register('presentionTimeTracking')}
                          value={selectPresentationTimeTracking}
                          onChange={(e: any) => {
                            handleSelectUserTimeTracking!(e.target.value);
                            setSelectPresentationTimeTracking(e.target.value)
                          }}
                          >
                          {['MONTH', 'QUATER', 'YEAR'].map(
                            (t: string, idx: number) => {
                              return (
                                <MenuItem
                                  key={idx}
                                  value={t}
                                  className={classes.listItem}
                                >
                                  {t}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </form>
                  </FormProvider>
          </Box>

          <Grid
            container
            spacing={6}
            className={classes.gridContainer}
            style={{ height: '50%' }}
          >
            <Grid item xs={6} style={{ paddingRight: 10 }}>
              <TrackingCard
                card="intros"
                title="Intros"
                presentationWithoutIntros={presentationWithoutIntros}
                presentationWithIntros={presentationWithIntros}
              />
            </Grid>

            <Grid item xs={6} style={{ paddingLeft: 10 }}>
              <TrackingCard
                card="customContent"
                title="Custom Content"
                customContentTotal={customContentTotal}
                customContentPdfTotal={customContentPdfTotal}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} className={classes.gridItems}>
          <TrackingCard
            card="productRate"
            title="Product Usage Rate"
            cardHeight="100%"
            productRateList={productRateList}
            presentationTotal={presentationTotal}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardTracking;
