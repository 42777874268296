import { ProductBundle } from "../API";
import { getProductBundles } from "../graphql/queries";
import { UserRole } from "../hooks/use-user-api";
import { AssetServiceType } from "../types/asset.service";
import { doGraphQlOperation } from "../utils/graphql-utils";
import { OnlinePresenterProductService } from "./online-presenter-product.service";

export class OfflinePresenterAssetService implements AssetServiceType {
  constructor(private localApiUrl: string, private assetBucketId: string, private userId: string, private userRole: UserRole) { }

  // eslint-disable-next-line
  public getAssetPath(fileName?: string | null, userCredentials?: string, userId?: string) {
    if (!fileName) {
      return '';
    }
    return `${this.localApiUrl}${this.localApiUrl[this.localApiUrl.length - 1] !== '/' ? '/' : ''}${this.userRole}/${fileName}`;
  }

  public async synchronizeAssets(presentationIds: string[], userCredentials: any) {
    try {
      const productBundles = await this.getProductBundles();
      if (!productBundles) {
        throw new Error('Error while getting product bundles.');
      }
      const productService = new OnlinePresenterProductService();
      const productConfigs = await productService.fetchProducts();
      for (const presentationId of presentationIds) {
        await fetch(this.localApiUrl + 'assets-manager/synchronize-presentation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: this.userId,
            userRole: this.userRole,
            presentationId,
            userCredentials,
            s3AssetBucketId: this.assetBucketId,
            productBundles,
            productConfigs,
          }),
        });
      }
    } catch (err) {
      throw err;
    }
  }

  public async deleteAssets(presentationIds: string[]) {
    try {
      for (const presentationId of presentationIds) {
        await fetch(this.localApiUrl + 'assets-manager/delete-presentation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: this.userId,
            userRole: this.userRole,
            presentationId,
          }),
        });
      }
    } catch (err) {
      throw err;
    }
  }

  public async getProductBundles() {
    const productBundles = await doGraphQlOperation<{ getProductBundles: ProductBundle[] }>(getProductBundles);
    if (!productBundles.data || productBundles.errors?.length) {
      return undefined;
    }
    return productBundles.data.getProductBundles;
  }
}
