import { useStateMachine } from 'little-state-machine';
import _ from 'lodash';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useInputValidation } from '../hooks/use-input-validation';
import updatePresentation from '../local-storage-actions/ls-update-presentation';
import updateProduct from '../local-storage-actions/ls-update-product';
import { DeepDiveDrawerProps } from '../types/deep-dive-types';
import { FullPresentationType, PresentationType } from '../types/presentation-types';
import { ProductCustomContentType, ProductType } from '../types/product-types';
import { ProductContext } from './product-context';

interface PresentationState {
  presentation?: FullPresentationType;
  setPresentation(data: PresentationType): void;
  product?: ProductType;
  setProduct(data: ProductType): void;
  drawerContent?: ReactNode;
  setDrawerContent: (content: ReactNode) => void;
  drawerProps: DeepDiveDrawerProps;
  setDrawerProps: (args: DeepDiveDrawerProps) => void;
}

export const PresentationContext = createContext<PresentationState>({
  setPresentation: () => undefined,
  setProduct: () => undefined,
  setDrawerContent: () => undefined,
  setDrawerProps: () => undefined,
  drawerProps: {
    hasToggle: false,
    isToggleDisabled: true,
    isDarkMode: false,
    isClosed: true,
  },
});

type Props = {
  children?: ReactNode;
};

function PresentationContextProvider({ children }: Props) {
  const [presentation, setPresentationRaw] = useState<FullPresentationType>();
  const { actions, state } = useStateMachine({
    updatePresentation,
    updateProduct,
  });

   const { products, getProductsAsArray } = useContext(ProductContext);
   const { isPdf } = useInputValidation();
     useEffect(() => {  
      setPresentationRaw({
      ...state.presentation,
      products: state.presentation.products?.reduce<ProductType[]>((arr, p) => {
        if (p) {
          let foundProduct = getProductsAsArray()?.find((pr: ProductType)=> pr.name === p.productName);       
          if (foundProduct) {
            foundProduct = _.cloneDeep(foundProduct);
            if (foundProduct?.content.deepDiveView && (p.customContents?.length || p.globalCustomContents?.length)) {
              foundProduct.content.deepDiveView.userCustomContentView = p.customContents?.reduce<ProductCustomContentType[]>((arr, c) => {
                if (!!c) {
                  arr.push({
                    name: c.fileName || '',
                    id: c.customContentId!,
                    fileUrl: c.uri || '',
                    fileType: c.uri ? (isPdf(c?.uri) ? 'pdf' : 'video') : 'pdf',
                    fileSize: c.fileSize ? Number((c?.fileSize / 1000000).toFixed(2)) : 0,
                  });
                }
                return arr;
              }, []) || undefined;
              foundProduct.content.deepDiveView.globalCustomContentView = p.globalCustomContents?.reduce<ProductCustomContentType[]>((arr, c) => {
                if (!!c && c.status === 'active') {
                  arr.push({
                    name: c.fileName || '',
                    id: c.globalCustomContentId!,
                    fileUrl: c.path || '',
                    fileType: c.path ? (isPdf(c?.path) ? 'pdf' : 'video') : 'pdf',
                    fileSize: c.fileSize ? Number((c?.fileSize / 1000000).toFixed(2)) : 0,
                  });
                }
                return arr;
              }, []) || undefined;
            }
            arr.push(foundProduct);
          }
        }
        return arr;
      }, []) || [],
    });
    // eslint-disable-next-line
  }, [state.presentation, products]);

  const product = state.product;
  const setPresentation = (p: PresentationType) => {
    actions.updatePresentation({ ...p });
  };
  const setProduct = (p: ProductType) => {
    actions.updateProduct(p);
  };
  const [drawerContent, setDrawerContent] = useState<ReactNode>('HULA');
  const [drawerProps, setDrawerProps] = useState<DeepDiveDrawerProps>({
    hasToggle: false,
    isToggleDisabled: true,
    isDarkMode: false,
    isClosed: true,
  });

  return (
    <PresentationContext.Provider
      value={{
        presentation,
        setPresentation,
        product,
        setProduct,
        drawerContent,
        setDrawerContent,
        drawerProps,
        setDrawerProps,
      }}
    >
      {children}
    </PresentationContext.Provider>
  );
}

export default PresentationContextProvider;
