import {
  makeStyles,
  Tab,
  TabProps,
  Theme,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    minWidth: 'fit-content',
    height: 30,
    minHeight: 30,
    padding: 0,
    textTransform: 'none',
    paddingBottom: theme.spacing(1),
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 3,
      transform: 'scaley(0.5)',
      zIndex: 100,
      borderRadius: '15px',
      transition: 'all 0.2s ease-in-out',
      backgroundColor: 'transparenz'
    },
    color: ({ color }) =>
      color === 'secondary' ? theme.palette.grey[600] : theme.palette.grey[400],
  },
  selected: {
    color: ({ color }) =>
      color === 'secondary'
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 3,
      transform: 'scaley(0.5)',
      zIndex: 100,
      borderRadius: '15px',
      transition: 'all 0.2s ease-in-out',
      backgroundColor: ({ color }) =>
        color === 'secondary'
          ? theme.palette.secondary.main
          : theme.palette.primary.main,
    },
  },
  text: {
    fontFamily: 'Hella-Light',
    transition: 'all 0.2s ease-in-out',
  }
}));

interface Props extends TabProps {
  color?: 'primary' | 'secondary';
}

function CustomTab(props: Props) {
  const classes = useStyles(props);

  return (
    <Tab
      classes={{ root: classes.root, selected: classes.selected }}
      disableRipple
      {...props}
      label={
        <Typography
          variant="h4"
          className={classes.text}
          style={{ fontWeight: props.selected ? 800 : 400 }}
        >
          {props.label}
        </Typography>
      }
    />
  );
}

export default CustomTab;
