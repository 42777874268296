import { Box, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import AdminNavbar from '../admin/admin-navbar';
import AdminNavigationDialog from '../admin/admin-navigation-dialog';

export type AdminPageLayoutProps = {
  children?: Element | JSX.Element;
  headline: string;
  description: string[] | string;
  enableFlexBox?: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 50,
    height: '100vh',
  },
}));

const AdminPageLayout = ({
  children,
  headline,
  description,
  enableFlexBox,
}: AdminPageLayoutProps) => {
  const classes = useStyles();
  const [openNavigation, setOpenNavigation] = useState<boolean>(false);
  return (
    <Box
      className={classes.root}
      display={enableFlexBox ? 'flex' : undefined}
      flexDirection="column"
    >
      <>
        <AdminNavbar
          headline={headline}
          description={description}
          openNavigation={() => setOpenNavigation(true)}
        />
        {children}

        <AdminNavigationDialog
          open={openNavigation}
          onClose={() => setOpenNavigation(false)}
        />
      </>
    </Box>
  );
};

export default AdminPageLayout;
