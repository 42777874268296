import {
  Box,
  Button,
  DialogProps,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HELLA_COLORS } from '../../../theme';
import AdminDialog from '../admin-dialog';
import { useContext, useEffect, useState } from 'react';
import { User } from '../../../API';
import { ProductContext } from '../../../contexts/product-context';
import { ErrorContext } from '../../../contexts/error-context';

const useStyles = makeStyles((theme) => ({
  createDialog: {
    minWidth: 400,
    width: '100%'
  },
  dialogTextField: {
    width: '100%',
    '& > div': {
      borderBottom: `1px solid ${HELLA_COLORS.grey5}`,
      '&::before': {
        content: 'none',
      },
      '&::after': {
        content: 'none',
      },
    },
    '&:first-of-type': {
      marginBottom: 28,
    },
  },
  buttonContainer: {
    width: '100%',
    height: 72,
    border: `1px dashed ${HELLA_COLORS.grey4}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 60,
    marginBottom: 58,
    cursor: 'pointer',
    '&:first-of-type': {
      marginBottom: theme.spacing(12),
    },
    '& > p': {
      fontFamily: 'Hella-Regular',
    },
  },
  addButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'transparent !important',
    '& > span > img': {
      filter: 'invert(1)',
    },
    '&:hover': {
      background: theme.palette.common.black,
    },
  },
  addButtonDisabled: {
    background: HELLA_COLORS.grey8,
  },
  uploadButton: {
    width: 140,
  },
  usersListContainer: {
    height: 140,
    marginTop: -30,
    overflowX: 'hidden',
    overflowY: 'scroll',
    marginBottom: 40,
    border: `1px solid ${HELLA_COLORS.grey8}`,
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.common.black,
      borderRadius: '0px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#878587',
    },
    '&::-webkit-scrollbar-track': {
      background: '#A8A6A8',
      borderRadius: '0px',
      boxShadow: 'inset 0px 0px 0px 0px #F0F0F0',
    },
  },
  userListItem: {
    filter: 'drop-shadow(2px 4px 16px rgba(0, 0, 0, 0.16))',
    height: 50,
    display: 'flex',
    justifyContent: 'start-flex',
    alignItems: 'center',
    paddingLeft: 16,
    borderBottom: `1px solid ${HELLA_COLORS.grey8}`,
    '&:hover': {
      backgroundColor: HELLA_COLORS.grey8,
      cursor: 'pointer',
    },
  },
}));

interface Props extends DialogProps {
  onHandleClose: () => void;
  usersList: User[] | undefined;
  selectedProduct: string | undefined;
}

function AdminAddProductToUserDialog({
  onHandleClose,
  usersList,
  selectedProduct,
  ...dialogProps
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>('');
  const [matchingEmails, setMatchingEmails] = useState<string[]>([]);
  const { setNewProductToUserAdded, allowProductForUser } = useContext(ProductContext);
  const [isAddUserBtnDisable, setIsAddUserBtnDisable] = useState(true);
  const { setErrorDialogState } = useContext(ErrorContext);

  const handleSearch = (event) => {
    const value = event.target.value;
    const matchingUsers = usersList?.filter((user) =>  user?.email?.toLowerCase().includes(value.toLowerCase()));
    const emails = matchingUsers?.map((user) => user?.email) as string[];
    setMatchingEmails(emails);
    setSearchText(value);
  };

  const handleSelect = (email: string) => {
    setSearchText(email);
    setMatchingEmails([]);
    setIsAddUserBtnDisable(false);
  };

  useEffect(() => {
    setSearchText('');
  }, [usersList]);


  type MessageType = {
    message: string;
  }

  type AddProductToUserResponse = string[] | MessageType | undefined; 

  const handleAllowProductForUser = () => {
    const userId = usersList?.filter((user) => user.email === searchText)[0]?.id;
    if (selectedProduct && userId) {
      allowProductForUser(selectedProduct, userId).then((res: AddProductToUserResponse) => {
        if(typeof res === 'object' && 'message' in res){
          setErrorDialogState({
            open: true,
            errorMessage: res?.message,
          });
        }
        if(Array.isArray(res)){
          setNewProductToUserAdded({
            product: selectedProduct,
            userId: userId,
          });
        }
      }).catch(err=> alert(err));
    }
  };

  return (
    <AdminDialog
      {...dialogProps}
      title={t('adminGlobalProductAccessibility.addUserToProductDialog.label')}
      description={t(
        'adminGlobalProductAccessibility.addUserToProductDialog.description'
      )}
      contentClassName={classes.createDialog}
      onClose={onHandleClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        position="relative"
        style={{
          height: 200,
          justifyContent: 'space-between',
        }}
      >
        <TextField
          className={classes.dialogTextField}
          label={t('adminGlobalProductAccessibility.searchInput.label')}
          value={searchText}
          onChange={handleSearch}
        />
        <Box>
          {matchingEmails.length > 0 && searchText.length > 0 && (
            <Box className={classes.usersListContainer}>
              {matchingEmails.map((email) => (
                <Typography
                  variant="body1"
                  key={email}
                  onClick={() => handleSelect(email)}
                  className={classes.userListItem}
                >
                  {email}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" width={300}>
        <Button
          onClick={() => onHandleClose()}
          className={classes.uploadButton}
        >
          {t('adminGlobalProductAccessibility.cancelButton.label')}
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            handleAllowProductForUser();
            onHandleClose();
          }}
          disabled={!isAddUserBtnDisable ? false : true}
          className={classes.uploadButton}
        >
          {t('adminGlobalProductAccessibility.addUserButton.label')}
        </Button>
      </Box>
    </AdminDialog>
  );
}

export default AdminAddProductToUserDialog;
