import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorContext } from '../contexts/error-context';
import {
  createGlobalCustomContent as apiAddGlobalCustomContent,
  confirmGlobalCustomContentUpload as apiConfirmGlobalCustomContentUpload,
  deleteGlobalCustomContent as apiDeleteGlobalCustomContent,
} from '../graphql/mutations';
import { listActiveGlobalCustomContent as apiListActiveGlobalCustomContent } from '../graphql/queries';
import {
  CreateGlobalCustomContentResponse,
  ListGlobalCustomContentsType,
} from '../types/global-custom-content-types';
import { doFetch } from '../utils/fetch-utils';
import { doGraphQlOperation } from '../utils/graphql-utils';
import { parseApiErrors } from '../utils/parse-api-errors';

export type CustomContentApiResponse = {
  fetchGlobalCustomContents: () => Promise<void>;
  addGlobalCustomContent(file: File, productName: string): Promise<void>;
  deleteGlobalCustomContent: (
    customContentId: string,
    productName: string
  ) => Promise<void>;
  listGlobalCustomContents: ListGlobalCustomContentsType[];
  isLoading: boolean;
  isFileUploading?: string | false;
};

export function useGlobalAdminCustomContent(): CustomContentApiResponse {
  const { setErrorDialogState } = useContext(ErrorContext);

  const { t } = useTranslation();
  const [listGlobalCustomContents, setListGlobalCustomContents] = useState<
    ListGlobalCustomContentsType[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState<string | false>(false);

  useEffect(() => {
    fetchGlobalCustomContents();
    // eslint-disable-next-line
  }, []);

  const fetchGlobalCustomContents = async () => {
    try {
      setIsLoading(true);
      const { data } = await doGraphQlOperation<{
        listActiveGlobalCustomContent: ListGlobalCustomContentsType[];
      }>(apiListActiveGlobalCustomContent, undefined, { errorOnEmpty: true });
      setListGlobalCustomContents(data?.listActiveGlobalCustomContent!);
      setIsLoading(false);
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b013'),
        additionalMessages: parseApiErrors(err)
      });
    }
  };

  const addGlobalCustomContent = async (file: File, productName: string) => {
    try {
      setIsFileUploading(productName);

      const globalCustomContentInput = {
        fileName: file.name,
        product: productName,
        type: file.type,
      };

      const { data } = await doGraphQlOperation<{ createGlobalCustomContent: CreateGlobalCustomContentResponse }>(
        apiAddGlobalCustomContent,
        { globalCustomContent: globalCustomContentInput },
        { errorOnEmpty: true }
      );

      const globalCustomContentId = data?.createGlobalCustomContent?.globalCustomContent?.globalCustomContentId!;
      if (data && globalCustomContentId) {
        try {
          await doFetch(data?.createGlobalCustomContent.uploadUrl, {
            method: 'PUT',
            body: file,
          }, { parseResponse: 'text' })
        } catch (err: any) {
          setErrorDialogState({
            open: true,
            errorMessage: t('errors.c002'),
            additionalMessages: parseApiErrors(err)
          });
        }

        try {
          await doGraphQlOperation<{
            confirmGlobalCustomContentUpload: boolean;
          }>(
            apiConfirmGlobalCustomContentUpload,
            { globalCustomContentId: globalCustomContentId },
            { errorOnEmpty: true }
          );
          setIsFileUploading(false);
          await fetchGlobalCustomContents();
        } catch (err: any) {
          setErrorDialogState({
            open: true,
            errorMessage: t('errors.c003'),
            additionalMessages: parseApiErrors(err),
          });
        }
      } else {
        setIsFileUploading(false);
      }
    } catch (err: any) {
      setIsFileUploading(false);
      setIsLoading(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.c001'),
        additionalMessages: parseApiErrors(err)
      });
    }
  };

  const deleteGlobalCustomContent = async (
    globalCustomContentId: string,
    productName: string
  ) => {
    try {
      setIsFileUploading(productName);
      await doGraphQlOperation<{ deleteGlobalCustomContent: boolean }>(apiDeleteGlobalCustomContent,
        { globalCustomContentId: globalCustomContentId },
        { errorOnEmpty: true });
      setIsLoading(false);
      await fetchGlobalCustomContents();
      setIsFileUploading(false);
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.c004'),
        additionalMessages: parseApiErrors(err)
      });
    }
  };

  return {
    addGlobalCustomContent,
    fetchGlobalCustomContents,
    deleteGlobalCustomContent,
    listGlobalCustomContents,
    isLoading,
    isFileUploading,
  };
}
