export enum ProductAssetPathPart {
  Base = 'Produktsets',
  UspOverview = 'USPs/Overview',
  UspVideos = 'USPs/Videos',
  SummaryImages = 'Summary/Images',
  TechnicalData = 'Technical-Data',
  TechnicalDataImages = 'Technical-Data/Images',
  Configurator = 'Configurator',
  ConfiguratorContents = 'Configurator/Contents',
  Xray = 'X-Ray',
  ExplosionView = 'Explosion-View',
  CoreFunctions = 'Core-Function',
  PermanentCustomContentsBase = 'Permanent_Custom_Content',
  PermanentCustomContents = 'Permanent_Custom_Content/Contents',
  FactsAndFigures = 'Facts-Figures/Images'
}

export type PathType = 'product-base'
  | 'usp-videos'
  | 'usp-overview'
  | 'summary-images'
  | 'technical-data'
  | 'technical-data-images'
  | 'xray'
  | 'perm-custom-contents'
  | 'perm-custom-contents-base'
  | 'explosion-view'
  | 'core-functions'
  | 'configurator-base'
  | 'configurator-contents'
  | 'facts-and-figures';

export function getFullProductAssetPath(productName: string, pathType: PathType, suffix?: string) {
  return `${getProductAssetPathPrefix(productName, pathType)}/${suffix || ''}`;
}

export function getProductAssetPathPrefix(productName: string, pathType: PathType) {
  const productBase = `${ProductAssetPathPart.Base}/${productName}`;
  switch (pathType) {
    case 'product-base':
      return productBase;
    case 'usp-videos':
      return `${productBase}/${ProductAssetPathPart.UspVideos}`;
    case 'usp-overview':
      return `${productBase}/${ProductAssetPathPart.UspOverview}`;
    case 'summary-images':
      return `${productBase}/${ProductAssetPathPart.SummaryImages}`;
    case 'technical-data':
      return `${productBase}/${ProductAssetPathPart.TechnicalData}`;
    case 'technical-data-images':
      return `${productBase}/${ProductAssetPathPart.TechnicalDataImages}`;
    case 'xray':
      return `${productBase}/${ProductAssetPathPart.Xray}`;
    case 'perm-custom-contents':
      return `${productBase}/${ProductAssetPathPart.PermanentCustomContents}`;
    case 'perm-custom-contents-base':
      return `${productBase}/${ProductAssetPathPart.PermanentCustomContentsBase}`;
    case 'explosion-view':
      return `${productBase}/${ProductAssetPathPart.ExplosionView}`;
    case 'core-functions':
      return `${productBase}/${ProductAssetPathPart.CoreFunctions}`;
    case 'configurator-base':
      return `${productBase}/${ProductAssetPathPart.Configurator}`;
    case 'configurator-contents':
      return `${productBase}/${ProductAssetPathPart.ConfiguratorContents}`;
    case 'facts-and-figures':
        return `${productBase}/${ProductAssetPathPart.FactsAndFigures}`;   
  }
}

// export function populateProductAssetPaths(productConfig: ProductType) {
//   productConfig.productImage = getFullProductAssetPath(productConfig.name, 'product-base', productConfig.productImage);
//   productConfig.backgroundImage = getFullProductAssetPath(productConfig.name, 'usp-overview', productConfig.backgroundImage);
//   productConfig.content.expertVideo = getFullProductAssetPath(productConfig.name, 'usp-videos', productConfig.content.expertVideo);

//   productConfig.content.productUsp.forEach(productUsp => {
//     productUsp.url = getFullProductAssetPath(productConfig.name, 'usp-videos', productUsp.url);
//   });

//   productConfig.content.productSummaryConfig?.columns.forEach(c => {
//     c.rows.forEach(r => {
//       r.tiles.forEach(t => {
//         if (t.backgroundImage?.src) {
//           t.backgroundImage.src = getFullProductAssetPath(productConfig.name, 'summary-images', t.backgroundImage.src);
//         }
//       })
//     })
//   });

//   if (productConfig.content.deepDiveView.explosionView?.modelURL) {
//     productConfig.content.deepDiveView.explosionView.modelURL = getFullProductAssetPath(
//       productConfig.name,
//       'explosion-view',
//       productConfig.content.deepDiveView.explosionView.modelURL
//     );
//   }

//   if (productConfig.content.deepDiveView.coreFunctionsView) {
//     productConfig.content.deepDiveView.coreFunctionsView.forEach(cf => {
//       [
//         cf.assetsLightsOff?.pure,
//         cf.assetsLightsOff?.metallicAppearance,
//         cf.assetsLightsOff?.stylingStructures,
//         cf.assetsLightsOff?.metallicStructures,
//         cf.assetsLightsOn?.pure,
//         cf.assetsLightsOn?.metallicAppearance,
//         cf.assetsLightsOn?.stylingStructures,
//         cf.assetsLightsOn?.metallicStructures,
//       ].map(arr => {
//         if (arr) {
//           arr = arr.map(text => (
//             getFullProductAssetPath(productConfig.name, 'core-functions', text)
//           ));
//         }
//       })
//     })
//   }

//   if (productConfig.content.deepDiveView.coreFunctionsView?.fields) {
//     productConfig.content.deepDiveView.coreFunctionsView?.fields.forEach(field => {
//       field.values?.forEach(value => {
//         value.options?.forEach(option => {
//           if (option.content1?.fileUrl) {
//             option.content1.fileUrl = 
//           }
//         });
//       });
//     });
//   }
// }