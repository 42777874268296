import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddIntroToPresentationResponse, IntroAsset } from '../API';
import { ErrorContext } from '../contexts/error-context';
import {
  addIntroAssetToPresentation as apiAddIntroAssetToPresentation,
  confirmIntroAssetUploadToPresentation as apiConfirmIntroAssetUploadToPresentation,
  removeIntroAssetFromPresentation as apiRemoveIntroAssetFromPresentation
} from '../graphql/mutations';
import { getPresentation as apiGetPresentation } from '../graphql/queries';
import { PresentationType } from '../types/presentation-types';
import { doGraphQlOperation } from '../utils/graphql-utils';
import { parseApiErrors } from '../utils/parse-api-errors';
import { useTracking } from './use-tracking';

export type useIntroApiResponse = {
  introAssets: (IntroAsset | null)[] | null | undefined;
  assetLoading: boolean;
  introError: unknown;
  introProgress: number;
  fetchIntroAssets: (presentationId: string) => Promise<void>;
  addIntroAsset(introAsset: File, name?: string, category?: string): Promise<void>;
  deleteIntroAsset: (introAssetId: string) => Promise<void>;
  confirmIntroAssetUpload: (introAssetId: string) => Promise<void>;
};

type useIntroApiProps = {
  presentationId: string | undefined | null
}

export function useIntroApi({ presentationId }: useIntroApiProps): useIntroApiResponse {
  const { t } = useTranslation();
  const [introAssets, setIntroAssets] = useState<(IntroAsset | null)[] | null | undefined>();
  const [assetLoading, setAssetLoading] = useState<boolean>(false);
  const [introError, setIntroError] = useState<unknown>();
  const [introProgress, setProgress] = useState<number>(0);
  const lastProgressUpdate = useRef(-10);
  const { setErrorDialogState } = useContext(ErrorContext);
  const { trackUploadIntroAsset } = useTracking();

  useEffect(() => {
    if (presentationId)
      fetchIntroAssets();
    // eslint-disable-next-line
  }, []);

  const fetchIntroAssets = async () => {
    try {
      setAssetLoading(true);
      const { data } = await doGraphQlOperation<{ getPresentation: PresentationType }>(apiGetPresentation, { presentationId: presentationId }, { errorOnEmpty: true });
      setIntroAssets(data?.getPresentation.introAssets);
      setAssetLoading(false);
    } catch (err) {
      setIntroError(err);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.e000'),
        additionalMessages: parseApiErrors(err),
      });
    }

  };
  const addIntroAsset = async (file: File, name?: string, category?: string) => {
    try {
      setAssetLoading(true);
      const assetInput = {
        fileName: file.name,
        name: name || file.name,
        category,
      };
      const { data } = await doGraphQlOperation<{ addIntroAssetToPresentation: AddIntroToPresentationResponse }>(
        apiAddIntroAssetToPresentation,
        { introAsset: assetInput, presentationId: presentationId },
        { errorOnEmpty: true }
      );

      if (data?.addIntroAssetToPresentation?.uploadUrl) {
        let request = new XMLHttpRequest();
        request.open("PUT", data?.addIntroAssetToPresentation.uploadUrl, true);
        request.setRequestHeader("Content-Type", file.type);
        request.upload.onprogress = (event) => {
          const percent_completed = (event.loaded / event.total) * 100;
          if (percent_completed >= lastProgressUpdate.current + 10 || percent_completed === 100) {
            lastProgressUpdate.current = percent_completed;
            setProgress(percent_completed);
          }
        }
        request.onerror = (error) => {
          setIntroError(error);
          setErrorDialogState({
            open: true,
            errorMessage: t('errors.e002'),
            additionalMessages: parseApiErrors(error),
          });
        };
        request.onloadend = async () => {
          if (data?.addIntroAssetToPresentation.introAsset && data?.addIntroAssetToPresentation.introAsset.introAssetId) {
            confirmIntroAssetUpload(data?.addIntroAssetToPresentation.introAsset.introAssetId);
            trackUploadIntroAsset();
          }
          lastProgressUpdate.current = -10;
        }
        request.send(file);
        if (introError) setIntroError(undefined)
      }
    } catch (err) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.e001'),
        additionalMessages: parseApiErrors(err),
      });
    }

  };

  const deleteIntroAsset = async (introAssetId: string) => {
    try {
      await doGraphQlOperation<{ removeIntroAssetFromPresentation: boolean }>(
        apiRemoveIntroAssetFromPresentation,
        { introAssetId: introAssetId, presentationId: presentationId },
        { errorOnEmpty: true }
      );
      await fetchIntroAssets();
    } catch (err) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.e004'),
        additionalMessages: parseApiErrors(err),
      });
    }
  };

  const confirmIntroAssetUpload = async (introAssetId: string) => {
    try {
      await doGraphQlOperation<{ confirmIntroAssetUploadToPresentation: boolean }>(
        apiConfirmIntroAssetUploadToPresentation,
        { introAssetId: introAssetId, presentationId: presentationId },
        { errorOnEmpty: true }
      );
      await fetchIntroAssets();
      if (introError) setIntroError(undefined)
    } catch (err) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.e003'),
        additionalMessages: parseApiErrors(err),
      });
    }
  }

  return {
    introAssets,
    assetLoading,
    introError,
    introProgress,
    fetchIntroAssets,
    addIntroAsset,
    deleteIntroAsset,
    confirmIntroAssetUpload,
  }
}