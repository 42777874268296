import {
  Box,
  Button, FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductBundle } from '../../API';
import LoadingIcon from '../../assets/img/admin/Symbol_loading.svg';
import { ProductContext } from '../../contexts/product-context';
import { getProductBundles } from '../../graphql/queries';
import useProductBundle from '../../hooks/use-product-bundles';
import { ProductType } from '../../types/product-types';
import { doGraphQlOperation } from '../../utils/graphql-utils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  gridItemCustom: {
    backgroundColor: theme.palette.common.white,
    height: 460,
  },
  loadingIcon: {
    width: 40,
    height: 40,
    padding: 8,
    animation: `$rotate ease-in-out 1000ms infinite`,
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

const AdminProductBundles = () => {
  const classes = useStyles();
  const { getProductsAsArray } = useContext(ProductContext);
  const [productName, setProductName] = useState('');
  const { isLoading, createProductBundle } = useProductBundle();
  const { t } = useTranslation();
  const [currentBundleVersions, setCurrentBundleVersions] = useState<ProductBundle[]>();

  useEffect(() => {
    fetchBundleVersions();
  }, []);

  const fetchBundleVersions = () => {
    doGraphQlOperation<{ getProductBundles: ProductBundle[] }>(getProductBundles)
      .then((res) => {
        if (res.data?.getProductBundles && !res.errors?.length) {
          setCurrentBundleVersions(res.data.getProductBundles);
        }
      });
  };

  const doCreateProductBundles = () => {
    createProductBundle(productName).then(() => fetchBundleVersions());
    setProductName('');
  };

  const productSelected = (p: string) => {
    setProductName(p);
  };
  const LoadingIndicator = () => (
    <img src={LoadingIcon} alt="LoadingIcon" className={classes.loadingIcon} />
  );

  return (
    <Box className={classes.container}>
      <Grid item md={3} className={classes.gridItemCustom}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          height="100%"
        >
          <Box
            pt={25}
            justifyContent="center"
            width="100%"
            style={{ marginBottom: 100 }}
          >
            <Typography variant="h3" color="textPrimary" align="center">
              {t('presentationListOffline.generateProductBundleHeadline')}
            </Typography>
          </Box>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: 200,
            }}
          >
            <FormControl>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                value={productName}
                onChange={(e: any) => productSelected(e.target.value)}
              >
                {getProductsAsArray().map((product: ProductType, idx: number) => {
                  return (
                    product ?
                      <MenuItem key={idx} value={product.name} disabled={isLoading}>
                        {product.name}
                      </MenuItem> :
                      <></>
                  );
                })}
              </Select>
            </FormControl>

            <Box display="flex" justifyContent="center">
              {isLoading ? <LoadingIndicator /> : <></>}
            </Box>

            <Button
              onClick={() => doCreateProductBundles()}
              style={{ marginTop: 100 }}
              variant="outlined"
              fullWidth
              disabled={isLoading}
            >
              {t('presentationListOffline.generateProductBundleBtn')}
            </Button>
          </form>
        </Box>
      </Grid>
      <Grid item md={3} className={classes.gridItemCustom} style={{ marginLeft: 32, overflow: 'hidden' }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100%"
          style={{ overflowY: 'auto' }}
          p={8}
        >
          <Typography variant="h4" style={{ marginBottom: 8 }}>
            Current versions
          </Typography>
          {currentBundleVersions?.map(b => (
            <Box width="100%" display="flex" justifyContent="space-between">
              <Typography>
                {b.productName}
              </Typography>
              <Typography>
                {!!b.createdAt && DateTime.fromISO(b.createdAt).toFormat('yyyy-MM-dd HH:mm')}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </Box>
  );
};

export default AdminProductBundles;
