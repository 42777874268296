import {
  GridJustification,
  makeStyles,
  Tabs,
  TabsProps,
  Theme,
} from '@material-ui/core';
import { ChangeEvent } from 'react';
import { FadeInAnimation } from '../../theme';

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    animation: '$fadeIn 0.5s ease-in-out forwards',
    '& .MuiButtonBase-root:after': {
      backgroundColor: ({ fontcolor }) => fontcolor,
    },
    flexGrow: (props) => (!props.disableFlexGrow ? 1 : 0),
    width: ({ width, fullWidth }) =>
      width ? width : fullWidth ? '100%' : 'fit-content',
    height: 'fit-content',
    minHeight: 'fit-content',
    color: ({ color }) =>
      color === 'secondary'
        ? theme.palette.secondary.main
        : theme.palette.primary.main,

    paddingBottom: 2,
    '& h4': {
      color: ({ fontcolor }) => fontcolor,
    },
  },

  flexContainer: {
    height: 28,
    display: 'flex',
    justifyContent: ({ justify }) => justify || 'flex-start',
    marginBottom: 2,
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 1,
      left: 0,
      width: '100%',
      height: 1,
      backgroundColor: ({ color }) =>
        color === 'secondary'
          ? theme.palette.grey[400]
          : theme.palette.grey[600],
    },
    '& > *:not(:last-child)': {
      marginRight: ({ spacing }) => theme.spacing(spacing || 0),
    },
  },
  '@media (max-width: 1100px)': {
    root: {
      '& h4': {
        fontSize: '16px',
      }
    }
  },
  '@keyframes fadeIn': FadeInAnimation,
}));

interface Props extends Omit<TabsProps, 'onChange'> {
  color?: 'primary' | 'secondary';
  fontcolor?: string;
  onChange?(event: ChangeEvent<{}>, val: any): void;
  width?: number | string;
  fullWidth?: boolean;
  spacing?: number;
  justify?: GridJustification;
  disableFlexGrow?: boolean;
}

function CustomTabs(props: Props) {
  const { fullWidth, disableFlexGrow, ...tabsProps } = props;
  const classes = useStyles(props);

  return (
    <Tabs
      onChange={props.onChange}
      TabIndicatorProps={{
        hidden: true,
      }}
      classes={{
        root: classes.root,
        flexContainer: classes.flexContainer,
      }}
      {...tabsProps}
    />
  );
}

export default CustomTabs;
