import { Box, Typography } from '@material-ui/core';
import { ReactNode } from 'react';
import { HELLA_COLORS } from '../../../theme';

type PercentUtilProps = {
  partialValue: number;
  totalValue: number;
  children: ReactNode;
};
const PercentUtil = ({
  partialValue,
  totalValue,
  children,
}: PercentUtilProps) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography style={{ color: HELLA_COLORS.grey5 }}>
        {((partialValue / totalValue) * 100).toFixed(1) + '%'}
      </Typography>
      {children}
    </Box>
  );
};

export default PercentUtil;
