import { Box, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PdfIcon } from '../../../assets/img/admin/pdf-icon.svg';
import { ReactComponent as PlayIcon } from '../../../assets/img/presentation/intro-play-icon.svg';
import { useAuth } from "../../../hooks/use-auth";
import { IntroAssetType } from "../../../types/presentation-intro-types";
import { FileType, getFileType } from '../../../utils/get-FileType';
import ProgressIndicator from "../../general/progress-indicator";
import { HOVER_DURATION } from "../../presentation-list/presentation-base-tile";

const useStyles = makeStyles((theme) => ({
  objectFit: {
    objectFit: 'cover',
  },
  textFileType: {
    color: theme.palette.grey[500],
  },
  textName: {
    fontFamily: 'Hella-Medium',
  },
  introInfoOverlay: {
    '&:hover': {
      '& > #global-intro-edit-overlay': {
        opacity: 1,
      }
    }
  },
  deleteOverlay: {
    opacity: 0,
    transition: theme.transitions.create('', { duration: HOVER_DURATION }),
  },
  editDeleteText: {
    marginTop: theme.spacing(6),
    whiteSpace: 'nowrap',
  },
  hoverActions: {
    cursor: 'pointer',
    width: 40,
    '&:hover': {
      '& > :first-child': {
        top: 0,
      },
      '&:hover $svgDeleteCircle': {
        stroke: '#fff',
      },
      '&:hover $svgDeleteC': {
        fill: '#fff',
      },
      '&:hover $editIcon': {
        fill: '#fff',
      },
      '&:hover $editIconWrapper': {
        border: '1px solid #fff',
      },
      '&:hover $editIconStroke': {
        stroke: '#fff',
      },
    },
  },
  iconBox: {
    top: 12.5,
    transition: theme.transitions.create('top', { duration: HOVER_DURATION }),
  },
  editDeleteSpacing: {
    marginLeft: theme.spacing(35),
  },
  svgDeleteB: {
    fill: 'none',
  },
  svgDeleteC: {
    fill: '#b2b2b2',
  },
  svgDeleteCircle: {
    stroke: '#b2b2b2',
  },
  textBox: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      duration: HOVER_DURATION,
    }),
    '&:hover': {
      opacity: 1,
    },
  },
}));

type Props = {
  introAsset: IntroAssetType;
  handleDelete(): void;
  handlePreview(type: FileType): void;
  isDeleting?: boolean;
}

function AdminGlobalIntroCard({ introAsset, handleDelete, handlePreview, isDeleting }: Props) {
  const classes = useStyles();
  const { signS3RequestSync } = useAuth();
  const { t } = useTranslation();

  const fileType: FileType | undefined = useMemo(() => {
    return (
      introAsset.type?.includes('video')
        ? 'video'
        : introAsset.type?.includes('pdf')
          ? 'pdf'
          : introAsset.fileName
            ? getFileType(introAsset.fileName)
            : undefined
    );
  }, [introAsset]);

  return (
    <Box width="100%" height="100%" position="relative">
      <Box
        position="absolute"
        top={0} left={0} right={0} bottom={0}
        zIndex={10}
      >
        {introAsset.path &&
          <>
            {fileType === 'image' ?
              <img
                src={signS3RequestSync(introAsset.path, {})}
                alt="Intro asset"
                width="100%" height="100%"
                className={classes.objectFit}
              /> :
              fileType === 'video' ?
                <video
                  src={signS3RequestSync(introAsset.path, {})}
                  width="100%" height="100%"
                  className={classes.objectFit}
                /> :
                <Box
                  width="100%" height="100%"
                  display="flex" justifyContent="center" alignItems="center"
                >
                  <PdfIcon width="50%" height="50%" />
                </Box>
            }
          </>
        }
      </Box>
      <Box
        position="absolute"
        left={0} bottom={0} right={0} top={0}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        className={classes.introInfoOverlay}
        zIndex={20}
      >
        <Box
          id="global-intro-edit-overlay"
          className={classes.deleteOverlay}
          flex={1}
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="rgba(0,0,0,0.6)"
        >
          {!isDeleting ?
            <Box
              height={65}
              position="relative"
              className={clsx(
                classes.hoverActions,
              )}
              onClick={() => handleDelete()}
            >
              <Box
                position="absolute"
                textAlign="center"
                className={classes.iconBox}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                >
                  <g
                    className={classes.svgDeleteCircle}
                    transform="rotate(-90 20 20)"
                    fill="none"
                  >
                    <circle cx="20" cy="20" r="20" stroke="none" />
                    <circle
                      className={classes.svgDeleteB}
                      cx="20"
                      cy="20"
                      r="19.5"
                    />
                  </g>
                  <path
                    className={classes.svgDeleteB}
                    d="M8.001 8.5h24v24h-24z"
                  />
                  <path
                    className={classes.svgDeleteC}
                    d="M23.902 11.764V8.5h-7.884v3.264H8.707v1.184h2.344l1.5 19.552h14.846l1.5-19.552h2.4v-1.184zm-1.155-2.109v2.079h-5.545V9.655zm4.968 3.235L26.3 31.258H13.621L12.235 12.89z"
                  />
                  <path
                    className={classes.svgDeleteC}
                    d="M19.417 16.688h1.086v11.2h-1.086zM23.329 16.64l1.084.059-.607 11.21-1.084-.058zM16.576 16.646l.607 11.21-1.084.06-.607-11.211z"
                  />
                </svg>
              </Box>
              <Box
                height="100%"
                width="100%"
                position="absolute"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-end"
                className={classes.textBox}
              >
                <Typography
                  color="secondary"
                  className={classes.editDeleteText}
                >
                  {t('presentationList.presentationTile.delete')}
                </Typography>
              </Box>
            </Box> :
            <ProgressIndicator color="light" />
          }
          {!isDeleting &&
            <Box
              height={65}
              ml={16}
              position="relative"
              className={clsx(
                classes.hoverActions,
              )}
              onClick={() => fileType && handlePreview(fileType)}
            >
              <Box
                position="absolute"
                textAlign="center"
                className={classes.iconBox}
              >
                <PlayIcon width={40} height={40} />
              </Box>
              <Box
                height="100%"
                width="100%"
                position="absolute"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-end"
                className={classes.textBox}
              >
                <Typography
                  color="secondary"
                  className={classes.editDeleteText}
                >
                  {t('adminGlobalIntroAssets.previewButton.label')}
                </Typography>
              </Box>
            </Box>
          }
        </Box>
        <Box
          height={100}
          bgcolor="rgba(0,0,0,0.8)"
          p={10}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Typography variant="caption" className={classes.textFileType}>
            {fileType ? t(`adminGlobalIntroAssets.intro.type.${fileType}`) : ''}
          </Typography>
          <Typography color="secondary" className={classes.textName}>
            {introAsset.name}
          </Typography>
        </Box>
      </Box>
    </Box>

  );
}

export default AdminGlobalIntroCard;
