import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const useStyles = makeStyles((theme) => ({
  pdfViewer: {
    height: '100%',
    '& .react-pdf__Page': {
      height: '100%',
    },
    '& .react-pdf__Page__svg': {
      width: '100% !important',
      height: '100% !important',
      objectFit: 'contain',
    },
    '& .react-pdf__Page__svg > svg': {
      width: '100%',
      height: '100%',
    },
  },
}));

type Props = {
  fileUrl: string;
  currentPage: number;
  setTotalPages?: (val: number) => void;
  onLoadSuccess?: () => void;
  className?: string;
}

const PresentationIntroPdfViewer = ({
  fileUrl,
  currentPage,
  setTotalPages,
  onLoadSuccess,
  className,
}: Props) => {
  const classes = useStyles();

  function onDocumentLoadSuccess(pdf: any) {
    setTotalPages?.(pdf?._pdfInfo?.numPages || 1);
    onLoadSuccess?.();
  }

  return (
    <Document
      file={fileUrl}
      className={clsx(classes.pdfViewer, className)}
      renderMode="svg"
      onLoadSuccess={onDocumentLoadSuccess}
    >
      <Page pageNumber={currentPage} />
    </Document>
  )
};

export default PresentationIntroPdfViewer;