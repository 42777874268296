import { createTheme, Theme } from '@material-ui/core/styles';
import { CSSProperties } from 'react';
import HellaUniversalBold from './fonts/HellaDINUniversal-Bold.woff2';
import HellaUniversalBoldItalic from './fonts/HellaDINUniversal-BoldItalic.woff2';
import HellaUniversalBoldExtraBlackItalic from './fonts/HellaDINUniversal-ExtraBlackItalic.woff2';
import HellaUniversalItalic from './fonts/HellaDINUniversal-Italic.woff2';
import HellaUniversalLight from './fonts/HellaDINUniversal-Light.woff2';
import HellaUniversalLightItalic from './fonts/HellaDINUniversal-LightItalic.woff2';
import HellaUniversalMedium from './fonts/HellaDINUniversal-Medium.woff2';
import HellaUniversalMediumItalic from './fonts/HellaDINUniversal-MediumItalic.woff2';
import HellaUniversalRegular from './fonts/HellaDINUniversal-Regular.woff2';
import HellaUniversalXBlack from './fonts/HellaDINUniversal-XBlack.woff2';
import { ExtendedColorOptions } from './types/theme';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: true;
    lg: true;
    xl: true;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    tertiary: ExtendedColorOptions;
  }
  interface PaletteOptions {
    tertiary: ExtendedColorOptions;
  }
}

export const FadeInAnimation = {
  '0%': {
    opacity: '0',
  },
  '100%': {
    opacity: '1',
  },
};

export const productTransition = 'all 0.3s ease-in';
export const productBoxShadow = '0px 0px 200px 80px black';

export const HELLA_COLORS = {
  primary: '#000',
  secondary: '#fff',
  white: '#fff',
  black: '#000000',
  tertiary: '#00f6ff',
  tertiaryLighter: '#19c8ff',
  tertiaryLight: '#2896fa',
  tertiaryMain: '#006eff',
  tertiaryDark: '#0046ff',
  tertiaryDarker: '#0000d2',
  tertiaryDarkest: '#0000a0',
  tertiary1: '#8688F5',
  trafficColorGreen: '#2de673',
  green1: '#3effc4',
  trafficColorYellow: '#fffa00',
  trafficColorRed: '#ff004b',
  grey1: '#3C3C3C',
  grey2: '#575757',
  grey3: '#6f6f6f',
  grey4: '#878787',
  grey5: '#9d9d9d',
  grey6: '#B2B2B2',
  grey7: '#C6C6C6',
  grey8: '#dadada',
  grey9: '#EDEDED',
  grey10: '#F5F5F5',
  grey11: '#2B2B2B',
  grey12: '#bdbdbd',
  darkYellow: '#fed34c',
  mintGreen: '#00B48F',
};

const SPACING = 2;

export const COLOR_DASH_GRADIENT_1 = `linear-gradient(90deg,
    ${HELLA_COLORS.tertiaryDarkest} 0%, 
    ${HELLA_COLORS.tertiaryDarker} 17%, 
    ${HELLA_COLORS.tertiaryDark} 35%, 
    ${HELLA_COLORS.tertiaryMain} 52%,
    ${HELLA_COLORS.tertiaryLight} 60%,
    ${HELLA_COLORS.tertiaryLighter} 72%,
    ${HELLA_COLORS.trafficColorYellow} 85%) 0% 0% no-repeat padding-box`;
export const COLOR_DASH_GRADIENT_2 = `linear-gradient(90deg,
    ${HELLA_COLORS.tertiaryDarkest} 0%, 
    ${HELLA_COLORS.tertiaryDarker} 5%, 
    ${HELLA_COLORS.tertiaryDark} 20%, 
    ${HELLA_COLORS.tertiaryMain} 27%,
    ${HELLA_COLORS.tertiaryLight} 37%,
    ${HELLA_COLORS.tertiaryLighter} 50%,
    ${HELLA_COLORS.trafficColorYellow} 70%) 0% 0% no-repeat padding-box`;

const HellaLight = {
  fontFamily: 'Hella-Light',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontDisplay: 'swap',
  src: `
    local('Hella'),
    local('Hella-Light'),
    url(${HellaUniversalLight}) format('woff2')
  `,
};

const HellaLightItalic = {
  fontFamily: 'Hella-LightItalic',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontDisplay: 'swap',
  src: `
    local('Hella'),
    local('Hella-LightItalic'),
    url(${HellaUniversalLightItalic}) format('woff2')
  `,
};
export const HellaRegular = {
  fontFamily: 'Hella-Regular',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontDisplay: 'swap',
  src: `
    local('Hella'),
    local('Hella-Regular'),
    url(${HellaUniversalRegular}) format('woff2')
  `,
};

const HellaItalic = {
  fontFamily: 'Hella-Italic',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontDisplay: 'swap',
  src: `
    local('Hella'),
    local('Hella-Italic'),
    url(${HellaUniversalItalic}) format('woff2')
  `,
};

const HellaMedium = {
  fontFamily: 'Hella-Medium',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontDisplay: 'swap',
  src: `
    local('Hella'),
    local('Hella-Medium'),
    url(${HellaUniversalMedium}) format('woff2')
  `,
};

const HellaMediumItalic = {
  fontFamily: 'Hella-MediumItalic',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontDisplay: 'swap',
  src: `
    local('Hella'),
    local('Hella-MediumItalic'),
    url(${HellaUniversalMediumItalic}) format('woff2')
  `,
};

const HellaBold = {
  fontFamily: 'Hella-Bold',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontDisplay: 'swap',
  src: `
    local('Hella'),
    local('Hella-Bold'),
    url(${HellaUniversalBold}) format('woff2')
  `,
};

const HellaBoldItalic = {
  fontFamily: 'Hella-BoldItalic',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontDisplay: 'swap',
  src: `
    local('Hella'),
    local('Hella-BoldItalic'),
    url(${HellaUniversalBoldItalic}) format('woff2')
  `,
};

const HellaBoldExtraBlackItalic = {
  fontFamily: 'Hella-BoldExtraBlackItalic',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontDisplay: 'swap',
  src: `
    local('Hella'),
    local('Hella-BoldExtraBlackItalic'),
    url(${HellaUniversalBoldExtraBlackItalic}) format('woff2')
  `,
};

const HellaXBlack = {
  fontFamily: 'Hella-XBlack',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontDisplay: 'swap',
  src: `
    local('Hella'),
    local('Hella-XBlack'),
    url(${HellaUniversalXBlack}) format('woff2')
  `,
};

const darkTheme: Theme = createTheme({
  typography: {
    fontFamily:
      'Hella-LightItalic, Hella-Regular,Hella-Italic,Hella-Medium,Hella-MediumItalic,Hella-Bold,Hella-BoldItalic,Hella-BoldExtraBlackItalic,Hella-XBlack',

    h1: {
      fontFamily: 'Hella-Light',
      fontSize: '80px',
      lineHeight: '80px',
    },
    h2: {
      fontFamily: 'Hella-Light',
      fontSize: '32px',
      lineHeight: '32px',
    },
    h3: {
      fontFamily: 'Hella-Light',
      fontSize: '24px',
      lineHeight: '24px',
    },
    h4: {
      fontFamily: 'Hella-Medium',
      fontSize: '20px',
      lineHeight: '20px',
    },
    h5: {
      fontFamily: 'Hella-Light',
      fontSize: '20px',
      lineHeight: '20px',
    },
    body1: {
      fontFamily: 'Hella-Light',
      fontSize: '16px',
      lineHeight: '16px',
    },
    body2: {
      fontFamily: 'Hella-Regular',
      fontSize: '14px',
      lineHeight: '14px',
    },
    subtitle1: {
      fontFamily: 'Hella-Medium',
      fontSize: '16px',
      lineHeight: '16px',
    },
    subtitle2: {
      fontFamily: 'Hella-Medium',
      fontSize: '14px',
      lineHeight: '14px',
    },
    button: {
      fontFamily: 'Hella-Medium',
      fontSize: '16px',
      lineHeight: '16px',
    },
    caption: {
      fontFamily: 'Hella-Regular',
      fontSize: '12px',
      lineHeight: '12px',
    },
  },
  palette: {
    type: 'dark',
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: '#000',
      default: '#000',
    },
    primary: {
      light: HELLA_COLORS.grey11,
      main: HELLA_COLORS.primary,
    },
    secondary: {
      main: HELLA_COLORS.secondary,
    },
    tertiary: {
      lighter: HELLA_COLORS.tertiaryLighter,
      light: HELLA_COLORS.tertiaryLight,
      main: HELLA_COLORS.tertiaryMain,
      dark: HELLA_COLORS.tertiaryDark,
      darker: HELLA_COLORS.tertiaryDarker,
      darkest: HELLA_COLORS.tertiaryDarkest,
    },
    error: {
      main: HELLA_COLORS.trafficColorRed,
    },
    warning: {
      main: HELLA_COLORS.trafficColorYellow,
    },
    info: {
      main: HELLA_COLORS.tertiaryDarker,
    },
    success: {
      main: HELLA_COLORS.trafficColorGreen,
    },
    grey: {
      50: HELLA_COLORS.grey10,
      100: HELLA_COLORS.grey9,
      200: HELLA_COLORS.grey8,
      300: HELLA_COLORS.grey7,
      400: HELLA_COLORS.grey6,
      500: HELLA_COLORS.grey5,
      600: HELLA_COLORS.grey4,
      700: HELLA_COLORS.grey3,
      800: HELLA_COLORS.grey2,
      900: HELLA_COLORS.grey1,
    },

    text: {
      primary: HELLA_COLORS.grey1,
      secondary: HELLA_COLORS.grey5,
      disabled: HELLA_COLORS.grey5,
      hint: HELLA_COLORS.trafficColorYellow,
    },
  },

  spacing: SPACING,

  breakpoints: {
    values: {
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },


  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          HellaLight,
          HellaLightItalic,
          HellaRegular,
          HellaItalic,
          HellaMedium,
          HellaMediumItalic,
          HellaBold,
          HellaBoldItalic,
          HellaBoldExtraBlackItalic,
          HellaXBlack,
        ] as unknown as CSSProperties,
      },
    },
    MuiButton: {
      root: {
        minHeight: '50px',
        fontFamily: 'Hella-Medium',
        fontSize: '16px',
        lineHeight: '20px',

        textTransform: 'none',
        borderRadius: 3,
        boxShadow: 'unset',
        '& .MuiButton-label': {
          padding: '0 20px',
        },
      },
      contained: {
        color:HELLA_COLORS.white,
        backgroundColor: HELLA_COLORS.grey1,
        boxShadow: 'unset',
        '&:disabled': {
          color: HELLA_COLORS.grey6,
          backgroundColor: HELLA_COLORS.grey8,
        },
        '&:hover': {
          color: HELLA_COLORS.white,
          backgroundColor: HELLA_COLORS.black,
          boxShadow: 'unset',
        },
      },
      containedPrimary: {
        color: HELLA_COLORS.white,
        backgroundColor: HELLA_COLORS.grey1,
        boxShadow: 'unset',
        '&:disabled': {
          color: HELLA_COLORS.grey6,
          backgroundColor: HELLA_COLORS.grey8,
        },
        '&:hover': {
          color: HELLA_COLORS.white,
          backgroundColor: HELLA_COLORS.black,
          boxShadow: 'unset',
        },
      },
      containedSecondary: {
        color: HELLA_COLORS.black,
        backgroundColor: HELLA_COLORS.grey9,
        boxShadow: 'unset',
        '&:disabled': {
          color: HELLA_COLORS.grey2,
          backgroundColor: HELLA_COLORS.grey1,
        },
        '&:hover': {
          color: HELLA_COLORS.black,
          backgroundColor: HELLA_COLORS.white,
        },
      },
      outlined: {
        border: `1px solid ${HELLA_COLORS.grey2}`,
        color: HELLA_COLORS.grey1,
        backgroundColor: HELLA_COLORS.white,
        boxShadow: 'unset',
        '&:disabled': {
          border: `1px solid ${HELLA_COLORS.grey8}`,
          color: HELLA_COLORS.grey8,
        },
        '&:hover': {
          border: `1px solid ${HELLA_COLORS.black}`,
          color: HELLA_COLORS.black,
        },
      },
      outlinedSecondary: {
        border: `1px solid ${HELLA_COLORS.grey6}`,
        color: HELLA_COLORS.grey9,
        backgroundColor: HELLA_COLORS.black,
        boxShadow: 'unset',
        '&:disabled': {
          border: `1px solid ${HELLA_COLORS.grey1}`,
          color: HELLA_COLORS.grey1,
        },
        '&:hover': {
          border: `1px solid ${HELLA_COLORS.white}`,
          color: HELLA_COLORS.white,
        },
      },
      text: {
        color: HELLA_COLORS.grey1,
        backgroundColor: HELLA_COLORS.white,
        boxShadow: 'unset',
        '&:disabled': {
          color: HELLA_COLORS.grey8,
        },
        '&:hover': {
          color: HELLA_COLORS.black,
        },
      },
      textSecondary: {
        color: HELLA_COLORS.grey9,
        backgroundColor: HELLA_COLORS.black,
        boxShadow: 'unset',
        '&:disabled': {
          color: HELLA_COLORS.grey1,
        },
        '&:hover': {
          color: HELLA_COLORS.white,
        },
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
      },
    },
    MuiChip: {
      root: {
        height: '100%',
        borderRadius: 2,
        paddingTop: SPACING * 4,
        paddingBottom: SPACING * 3,
        backgroundColor: HELLA_COLORS.primary,
        color: HELLA_COLORS.secondary,
      },
      label: {
        paddingLeft: SPACING * 5,
        paddingRight: SPACING * 5,
      },
      colorPrimary: {
        backgroundColor: HELLA_COLORS.primary,
        color: HELLA_COLORS.secondary,
      },
      colorSecondary: {
        backgroundColor: HELLA_COLORS.secondary,
        color: HELLA_COLORS.primary,
      },
    },
    MuiCard: {
      root: {
        backgroundColor: HELLA_COLORS.white,
        borderRadius: 0,
      },
    },
    MuiInput: {
      underline: {
        '&:before, &:after': {
          borderWidth: 2,
          transition: 'unset',
          borderBottom: `2px solid ${HELLA_COLORS.grey6}`,
        },
        '&:hover': {
          borderColor: 'unset',
        },
      },
    },
    MuiSwitch: {
      root: {
        width: 54,
        padding: 0,
        height: 24,
        borderRadius: 15,
        '& .Mui-checked': {
          transform: 'translateX(30px) !important',
        },
      },
      switchBase: {
        top: 2,
        bottom: 2,
        left: 2,
        right: 2,
        padding: 0,
        width: 20,
        height: 20,
      },
      thumb: {
        boxShadow: 'unset',
      },
      track: {
        width: '100%',
        backgroundColor: HELLA_COLORS.grey6,
        opacity: 1 + ' !important',
      },
      colorPrimary: {
        '&.Mui-disabled': {
          '& .MuiIconButton-label': {
            '& .MuiSwitch-thumb': {
              backgroundColor: HELLA_COLORS.grey6 + ' !important',
            },
          },
          '& + *': {
            backgroundColor: HELLA_COLORS.grey8 + ' !important',
          },
        },
        '&:not(.Mui-checked)': {
          '& .MuiIconButton-label': {
            '& .MuiSwitch-thumb': {
              backgroundColor: HELLA_COLORS.secondary + ' !important',
            },
          },
          '& + *': {
            backgroundColor: HELLA_COLORS.grey6 + ' !important',
          },
        },
        '&.Mui-checked': {
          '& + *': {
            backgroundColor: HELLA_COLORS.primary + ' !important',
          },
          '& .MuiIconButton-label': {
            '& .MuiSwitch-thumb': {
              backgroundColor: HELLA_COLORS.secondary + ' !important',
            },
          },
        },
      },
      colorSecondary: {
        '&.Mui-disabled': {
          '& .MuiIconButton-label': {
            '& .MuiSwitch-thumb': {
              backgroundColor: HELLA_COLORS.grey2 + ' !important',
            },
          },
          '& + *': {
            backgroundColor: HELLA_COLORS.grey1 + ' !important',
          },
        },
        '&:not(.Mui-checked)': {
          '& .MuiIconButton-label': {
            '& .MuiSwitch-thumb': {
              backgroundColor: HELLA_COLORS.primary + ' !important',
            },
          },
          '& + *': {
            backgroundColor: HELLA_COLORS.grey2 + ' !important',
          },
        },
        '&.Mui-checked': {
          '& + *': {
            backgroundColor: HELLA_COLORS.secondary + ' !important',
          },
          '& .MuiIconButton-label': {
            '& .MuiSwitch-thumb': {
              backgroundColor: HELLA_COLORS.primary + ' !important',
            },
          },
        },
      },
    },

    MuiPaper: {
      root: {
        color: HELLA_COLORS.black,
        backgroundColor: HELLA_COLORS.white,
      },
      elevation4: {
        boxShadow: 'none',
      },
    },

    MuiSelect: {
      icon: {
        color: HELLA_COLORS.grey6,
      },
    },

    MuiTooltip: {
      tooltip: {
        color: HELLA_COLORS.white,
        backgroundColor: HELLA_COLORS.grey3,
        fontSize: 16,
        padding: 15,
      },
    },
  },
});

export default darkTheme;
