export const abortMediaLoading = () => {
    let videos = Array.from(document.getElementsByTagName("video"));
    videos.forEach((video) => {
        video.src = "";
        video.load();
    });

    let images = Array.from(document.getElementsByTagName("img"));
    images.forEach((image) => {
        image.src = "";
    });
}