import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { COLOR_DASH_GRADIENT_1, COLOR_DASH_GRADIENT_2 } from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    transition: theme.transitions.create("all", { duration: '0.15s' }),
  },
  dash1: {
    background: COLOR_DASH_GRADIENT_1,
  },
  dash2: {
    background: COLOR_DASH_GRADIENT_2,
  },
  animation: {
    animation: '$dash',
  },
  defaultDuration: {
    animationDuration: (props: any) => (props.time === 'slow' ? '20s' : '2s'),
  },
  '@keyframes dash': {
    '0%': {
      width: '0%',
    },
    '100%': {
      width: '100%',
    },
  },
}));

type Props = {
  completed: boolean;
  variant?: 'fixed' | 'absolute';
  time?: 'fast' | 'medium' | 'slow';
  trackProgress?: number;
};

function ProgressBar({ completed, variant, time, trackProgress }: Props) {
  const classes = useStyles({ time });
  const [hideUI, setHideUi] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setHideUi(completed);
    }, 150)
  }, [completed])

  return (
    <Box
      position={variant || 'fixed'}
      bottom={0}
      left={0}
      right={0}
      width={trackProgress + "%" ?? "100%"}
      height={10}
      display={hideUI ? 'none' : 'flex'}
      flexDirection="column"
      alignItems="flex-start"
      zIndex={99999}
      className={classes.root}
    >
      <Box
        width="100%"
        height={5}
        className={clsx(
          classes.dash1,
          !trackProgress && classes.animation,
          classes.defaultDuration
        )}
      />
      <Box
        width="100%"
        height={5}
        className={clsx(
          classes.dash2,
          classes.animation,
          classes.defaultDuration
        )}
      />
    </Box>
  );
}

export default ProgressBar;
