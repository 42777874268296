export type FileType = 'video' | 'image' | 'pdf';

export const getFileType = (fileName: string): FileType | undefined => {
  let detectedType: FileType | undefined = undefined;
  fileName = fileName.toLowerCase();

  if (fileName.endsWith('.mp4')) {
    detectedType = 'video';
  } else if (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg') || fileName.endsWith('.png')) {
    detectedType = 'image';
  } else if (fileName.endsWith('.pdf')) {
    detectedType = 'pdf';
  }

  return detectedType;
};
