import {
  AddCustomContentToProductResponse,
  CustomContentInput,
  PresentationInput,
  SetCustomCoverImageForPresentationResponse
} from '../API';
import {
  addCustomContentToProduct,
  addProductToPresentation,
  confirmCustomContentUploadToProduct,
  createPresentation,
  deleteCustomContentFromProduct,
  deletePresentation,
  removeProductFromPresentation,
  setCoverImageForPresentation,
  setCustomCoverImageForPresentation,
  synchronizeGlobalCustomContentForProduct,
  updatePresentation
} from '../graphql/mutations';
import {
  createPresentationPdf,
  getPresentation,
  listActiveGlobalCustomContentForProduct,
  listCoverImages,
  listPresentationsForUser
} from '../graphql/queries';
import { XMLHttpRequestProps } from '../types/general-types';
import { CoverImageType } from '../types/global-cover-images-types';
import {
  GlobalCustomContentType,
  PresentationType
} from '../types/presentation-types';
import { PresentationServiceType } from '../types/presentation.service';
import { doGraphQlOperation } from '../utils/graphql-utils';
import { parseApiErrors } from '../utils/parse-api-errors';

export const PRESENTATION_SERVICE_ERRORS = {
  NoUploadUrl: 'No upload url',
  NoCustomContentId: 'No custom content ID',
};

export class OnlinePresenterPresentationService implements PresentationServiceType {

  public async listPresentations() {
    try {
      const res = await doGraphQlOperation<{
        listPresentationsForUser: PresentationType[];
      }>(listPresentationsForUser, undefined, { errorOnEmpty: true });
      return res.data?.listPresentationsForUser || [];
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async getPresentation(presentationId: string) {
    try {
      const res = await doGraphQlOperation<{
        getPresentation: PresentationType;
      }>(getPresentation, { presentationId }, { errorOnEmpty: true });
      return res.data?.getPresentation!;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async createPresentation(presentation: PresentationInput) {
    try {
      const res = await doGraphQlOperation<{
        createPresentation: PresentationType;
      }>(createPresentation, { presentation }, { errorOnEmpty: true });
      return res.data?.createPresentation!;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async updatePresentation(
    presentationId: string,
    presentation: PresentationInput
  ) {
    try {
      const res = await doGraphQlOperation<{
        updatePresentation: PresentationType;
      }>(updatePresentation, { presentationId, presentation }, { errorOnEmpty: true });
      return res.data?.updatePresentation!;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async deletePresentation(presentationId: string) {
    try {
      const res = await doGraphQlOperation<{
        deletePresentation: boolean;
      }>(deletePresentation, { presentationId }, { errorOnEmpty: true });
      return res.data?.deletePresentation!;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async listCoverImages() {
    try {
      const res = await doGraphQlOperation<{
        listCoverImages: CoverImageType[];
      }>(listCoverImages, undefined, { errorOnEmpty: true });
      return res.data?.listCoverImages!;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async setCoverImage(presentationId: string, coverImageId: string) {
    try {
      const res = await doGraphQlOperation<{
        setCoverImageForPresentation: PresentationType;
      }>(setCoverImageForPresentation, { presentationId, coverImageId }, { errorOnEmpty: true });
      return res.data?.setCoverImageForPresentation!;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async uploadCoverImage(
    presentationId: string,
    file: File,
    xmlHttpRequestProps: XMLHttpRequestProps
  ) {
    try {
      const res = await doGraphQlOperation<{
        setCustomCoverImageForPresentation: SetCustomCoverImageForPresentationResponse;
      }>(setCustomCoverImageForPresentation, {
        presentationId,
        coverImage: { fileName: file.name, name: file.name },
      }, { errorOnEmpty: true });
      const uploadUrl =
        res.data?.setCustomCoverImageForPresentation.uploadUrl;
      if (!uploadUrl) {
        throw parseApiErrors([
          { message: PRESENTATION_SERVICE_ERRORS.NoUploadUrl },
        ]);
      }
      const request = new XMLHttpRequest();
      request.open('PUT', uploadUrl, true);
      request.setRequestHeader('Content-Type', file.type);
      if (xmlHttpRequestProps.onprogress) {
        request.onprogress = xmlHttpRequestProps.onprogress;
      }
      if (xmlHttpRequestProps.onerror) {
        request.onerror = xmlHttpRequestProps.onerror;
      }
      if (xmlHttpRequestProps.onloadend) {
        request.onloadend = xmlHttpRequestProps.onloadend;
      }
      if (xmlHttpRequestProps.onloadstart) {
        request.onloadstart = xmlHttpRequestProps.onloadstart;
      }
      request.send(file);
      return res.data?.setCustomCoverImageForPresentation.presentation!;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async addProduct(presentationId: string, productName: string) {
    try {
      const res = await doGraphQlOperation<{
        addProductToPresentation: PresentationType;
      }>(addProductToPresentation, { presentationId, productName }, { errorOnEmpty: true });
      return res.data?.addProductToPresentation!;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async addProducts(presentationId: string, productNames: string[]) {
    try {
      let currentPresentation: PresentationType | undefined;
      for (const productName of productNames) {
        const res = await doGraphQlOperation<{
          addProductToPresentation: PresentationType;
        }>(addProductToPresentation, { presentationId, productName }, { errorOnEmpty: true });
        currentPresentation = res.data?.addProductToPresentation!;
      }
      return currentPresentation;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async deleteProduct(presentationId: string, productName: string) {
    try {
      const res = await doGraphQlOperation<{
        removeProductFromPresentation: PresentationType;
      }>(removeProductFromPresentation, {
        presentationId,
        productName,
      }, { errorOnEmpty: true });
      return res.data?.removeProductFromPresentation!;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async deleteProducts(presentationId: string, productNames: string[]) {
    try {
      let currentPresentation: PresentationType | undefined;
      for (const productName of productNames) {
        const res = await doGraphQlOperation<{
          removeProductFromPresentation: PresentationType;
        }>(removeProductFromPresentation, {
          presentationId,
          productName,
        }, { errorOnEmpty: true });
        currentPresentation = res.data?.removeProductFromPresentation!;
      }
      return currentPresentation;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async listActiveGlobalCustomContentsForProduct(productName: string) {
    try {
      const res = await doGraphQlOperation<{
        listActiveGlobalCustomContentForProduct: GlobalCustomContentType[];
      }>(listActiveGlobalCustomContentForProduct, { productName }, { errorOnEmpty: true });
      return res.data?.listActiveGlobalCustomContentForProduct!;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async uploadCustomContent(
    presentationId: string,
    productName: string,
    file: File,
    customContentInput: CustomContentInput,
    xmlHttpRequestProps: XMLHttpRequestProps
  ) {
    try {
      const res = await doGraphQlOperation<{
        addCustomContentToProduct: AddCustomContentToProductResponse;
      }>(addCustomContentToProduct, {
        customContent: customContentInput,
        presentationId,
        productName,
      }, { errorOnEmpty: true });
      const uploadUrl = res.data?.addCustomContentToProduct.uploadUrl;
      if (!uploadUrl) {
        throw parseApiErrors([
          { message: PRESENTATION_SERVICE_ERRORS.NoUploadUrl },
        ]);
      }
      const customContentId =
        res.data?.addCustomContentToProduct.customContentId;
      if (!customContentId) {
        throw parseApiErrors([
          { message: PRESENTATION_SERVICE_ERRORS.NoCustomContentId },
        ]);
      }
      const request = new XMLHttpRequest();
      request.open('PUT', uploadUrl, true);
      request.setRequestHeader('Content-Type', file.type);
      if (xmlHttpRequestProps.onprogress) {
        request.onprogress = xmlHttpRequestProps.onprogress;
      }
      if (xmlHttpRequestProps.onerror) {
        request.onerror = xmlHttpRequestProps.onerror;
      }
      if (xmlHttpRequestProps.onloadstart) {
        request.onloadstart = xmlHttpRequestProps.onloadstart;
      }
      request.onloadend = async (ev) => {
        await doGraphQlOperation<{
          confirmCustomContentUploadToProduct: boolean;
        }>(confirmCustomContentUploadToProduct, {
          customContentId,
          presentationId,
          productName,
        });
        xmlHttpRequestProps.onloadend?.(ev);
      };
      request.send(file);
      return res.data?.addCustomContentToProduct.presentation;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async deleteCustomContent(
    presentationId: string,
    productName: string,
    customContentId: string
  ) {
    try {
      const res = await doGraphQlOperation<{
        deleteCustomContentFromProduct: PresentationType;
      }>(deleteCustomContentFromProduct, {
        presentationId,
        productName,
        customContentId,
      }, { errorOnEmpty: true });
      return res.data?.deleteCustomContentFromProduct!;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async synchronizeGlobalCustomContentsForProduct(
    presentationId: string,
    productName: string
  ) {
    try {
      const res = await doGraphQlOperation<{
        synchronizeGlobalCustomContentForProduct: PresentationType;
      }>(synchronizeGlobalCustomContentForProduct, {
        presentationId,
        productName,
      }, { errorOnEmpty: true });
      return res.data?.synchronizeGlobalCustomContentForProduct!;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async synchronizeLocalPresentationList(list: PresentationType[]) {
    return undefined;
  }

  public async createPresentationPdf(presentationId: string): Promise<string> {
      const data = await doGraphQlOperation<{
        createPresentationPdf: string
      }>(createPresentationPdf, { presentationId });
      return data.data?.createPresentationPdf || '';
  }
}
