import { CognitoUserSession } from 'amazon-cognito-identity-js';

type Role = 'admin' | 'super-admin' | 'technical-admin' | 'contributor' | 'employee';

export function hasUserRole(role: Role, currentUserSession: CognitoUserSession) {
  const userGroups: string[] = currentUserSession?.getAccessToken()?.payload['cognito:groups'];
  if (!userGroups) {
    return false;
  }
  switch (role) {
    case 'technical-admin':
      return (
        userGroups.includes('HellaPresenterTechnicalAdmins')
      );
    case 'super-admin':
      return (
        userGroups.includes('HellaPresenterSuperAdmins') ||
        userGroups.includes('HellaPresenterTechnicalAdmins')
      );
    case 'admin':
      return (
        userGroups.includes('HellaPresenterAdmins') ||
        userGroups.includes('HellaPresenterSuperAdmins') ||
        userGroups.includes('HellaPresenterTechnicalAdmins')
      );
    case 'contributor':
      return (
        userGroups.includes('HellaPresenterContributors') ||
        userGroups.includes('HellaPresenterAdmins') ||
        userGroups.includes('HellaPresenterSuperAdmins') ||
        userGroups.includes('HellaPresenterTechnicalAdmins')
      );
    case 'employee':
      return (
        userGroups.includes('HellaPresenterEmployees') ||
        userGroups.includes('HellaPresenterContributors') ||
        userGroups.includes('HellaPresenterAdmins') ||
        userGroups.includes('HellaPresenterSuperAdmins') ||
        userGroups.includes('HellaPresenterTechnicalAdmins')
      );
    default:
      return false;
  }
}
