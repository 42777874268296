import { Box, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { ReactComponent as HomeIcon } from '../../assets/img/presentation/home-icon.svg';
import { Routes } from '../routing/routes';

const useStyles = makeStyles((theme) => ({
  HomeIcon: {
    cursor: 'pointer',
    zIndex: 1,
  },
}));

type Props = {
  to?: string;
};

function HomeButton({ to }: Props) {
  const classes = useStyles();
  const history = useHistory();

  const onClick = () => {
    history.push(to || Routes.Presentations);
  };

  return (
    <Box
      width={50}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={6.25}
      paddingLeft={0}
    >
      <HomeIcon className={classes.HomeIcon} onClick={() => onClick()} />
    </Box>
  );
}

export default HomeButton;
