import { ReactElement, ReactNode, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth-context';
import Spinner from '../spinner/spinner';
import { Routes } from './routes';

export interface AuthRouteProps extends RouteProps {
  unauthRedirect?: string;
}

/**
 * Checks for existing auth data. Redirects to unauthRedirect if user is not authenticated.
 * @param {AuthRouteProps} props Contains unauthRedirect, either path (string) or React element.
 * @returns {ReactElement}
 */
export default function AuthRoute(props: AuthRouteProps): ReactElement {
  const { unauthRedirect, children, ...routeProps } = props;
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return <Spinner />;
  }
  if (!loading && user) {
    return (
      <Route
        {...routeProps}
        render={() => (user ? children as ReactNode : <Redirect to={Routes.Login} />)}
      />
    );
  } else {
    return <Redirect to={Routes.Login} />;
  }
}
