/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPresentationPdf = /* GraphQL */ `
  query CreatePresentationPdf($presentationId: String!) {
    createPresentationPdf(presentationId: $presentationId)
  }
`;
export const getCurrentOfflinePresenterReleaseBuild = /* GraphQL */ `
  query GetCurrentOfflinePresenterReleaseBuild {
    getCurrentOfflinePresenterReleaseBuild
  }
`;
export const getPresentation = /* GraphQL */ `
  query GetPresentation($presentationId: String!) {
    getPresentation(presentationId: $presentationId) {
      clientName
      coverImage {
        coverImageId
        fileName
        name
        path
      }
      createdAt
      introAssets {
        category
        fileName
        fileSize
        introAssetId
        isGlobal
        name
        path
        type
      }
      presentationId
      products {
        category
        customContents {
          customContentId
          fileName
          fileSize
          product
          status
          type
          uri
        }
        globalCustomContents {
          fileName
          fileSize
          globalCustomContentId
          path
          product
          status
          type
        }
        productName
      }
      title
      userId
    }
  }
`;
export const getProductBundles = /* GraphQL */ `
  query GetProductBundles {
    getProductBundles {
      createdAt
      productName
      size
      zipKey
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($userId: String!) {
    getUser(userId: $userId) {
      businessDivision
      businessNeed
      creationDate
      department
      email
      enabled
      firstName
      id
      keyAccount
      lastName
      phone
      productCenter
      region
      role
      status
    }
  }
`;
export const listAccountRequests = /* GraphQL */ `
  query ListAccountRequests {
    listAccountRequests {
      businessDivision
      businessNeed
      department
      email
      firstName
      id
      keyAccount
      lastName
      phone
      productCenter
      region
      requestType
    }
  }
`;
export const listActiveGlobalCustomContent = /* GraphQL */ `
  query ListActiveGlobalCustomContent {
    listActiveGlobalCustomContent {
      fileName
      fileSize
      globalCustomContentId
      path
      product
      status
      type
    }
  }
`;
export const listActiveGlobalCustomContentForProduct = /* GraphQL */ `
  query ListActiveGlobalCustomContentForProduct($productName: String!) {
    listActiveGlobalCustomContentForProduct(productName: $productName) {
      fileName
      fileSize
      globalCustomContentId
      path
      product
      status
      type
    }
  }
`;
export const listCoverImages = /* GraphQL */ `
  query ListCoverImages {
    listCoverImages {
      coverImageId
      fileName
      name
      path
    }
  }
`;
export const listGlobalCustomContent = /* GraphQL */ `
  query ListGlobalCustomContent {
    listGlobalCustomContent {
      fileName
      fileSize
      globalCustomContentId
      path
      product
      status
      type
    }
  }
`;
export const listGlobalCustomContentForProduct = /* GraphQL */ `
  query ListGlobalCustomContentForProduct($productName: String!) {
    listGlobalCustomContentForProduct(productName: $productName) {
      fileName
      fileSize
      globalCustomContentId
      path
      product
      status
      type
    }
  }
`;
export const listGlobalIntroAssets = /* GraphQL */ `
  query ListGlobalIntroAssets {
    listGlobalIntroAssets {
      category
      fileName
      fileSize
      introAssetId
      isGlobal
      name
      path
      type
    }
  }
`;
export const listPresentations = /* GraphQL */ `
  query ListPresentations {
    listPresentations {
      clientName
      coverImage {
        coverImageId
        fileName
        name
        path
      }
      createdAt
      introAssets {
        category
        fileName
        fileSize
        introAssetId
        isGlobal
        name
        path
        type
      }
      presentationId
      products {
        category
        customContents {
          customContentId
          fileName
          fileSize
          product
          status
          type
          uri
        }
        globalCustomContents {
          fileName
          fileSize
          globalCustomContentId
          path
          product
          status
          type
        }
        productName
      }
      title
      userId
    }
  }
`;
export const listPresentationsForUser = /* GraphQL */ `
  query ListPresentationsForUser {
    listPresentationsForUser {
      clientName
      coverImage {
        coverImageId
        fileName
        name
        path
      }
      createdAt
      introAssets {
        category
        fileName
        fileSize
        introAssetId
        isGlobal
        name
        path
        type
      }
      presentationId
      products {
        category
        customContents {
          customContentId
          fileName
          fileSize
          product
          status
          type
          uri
        }
        globalCustomContents {
          fileName
          fileSize
          globalCustomContentId
          path
          product
          status
          type
        }
        productName
      }
      title
      userId
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers {
    listUsers {
      businessDivision
      businessNeed
      creationDate
      department
      email
      enabled
      firstName
      id
      keyAccount
      lastName
      phone
      productCenter
      region
      role
      status
    }
  }
`;
