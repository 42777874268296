import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/auth-context";
import { Auth } from "aws-amplify";
import { useEnvironment } from "../../hooks/use-environment";
import { Routes } from "./routes";

function AuthCheck() {
  const { user } = useContext(AuthContext);
  const { hasInternetConnection } = useEnvironment();
  const location = useLocation();

  const checkSession = async () => {
    if (hasInternetConnection) {
      try {
        await Auth.currentSession();
      } catch (err) {
        if (!location.pathname.startsWith(Routes.Login) && location.pathname !== '/') {
          window.location.href = '/';
        }
      }
    }
  }

  useEffect(() => {
    if (location.pathname && user) {
      checkSession();
    }
    // eslint-disable-next-line
  }, [location.pathname, user]);

  return (
    <></>
  )
}

export default AuthCheck;
