import { GlobalState } from 'little-state-machine';
import { ProductType } from '../types/product-types';

export default function updatePresentation(
  state: GlobalState,
  payload: ProductType
) {
  return {
    auth: state.auth,
    presentationCreator: state.presentationCreator,
    presentation: state.presentation,
    configuratorState: state.configuratorState,
    product: {
      ...payload,
    },
  };
}
