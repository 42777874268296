import { useMatomo } from "@datapunt/matomo-tracker-react";
import { MATOMO_ACTIONS, MATOMO_CATEGORIES } from "../config/matomo-config";

export function useTracking() {
  const { trackEvent } = useMatomo();

  // authentication

  const trackLogin = () => {
    trackEvent({
      category: MATOMO_CATEGORIES.authentication,
      action: MATOMO_ACTIONS.authenticationLogin,
    });
  };

  // createEditPresentation

  const trackCreatePresentation = () => {
    trackEvent({
      category: MATOMO_CATEGORIES.createEditPresentation,
      action: MATOMO_ACTIONS.createEditPresentationCreate,
    });
  };

  const trackSelectCoverImage = () => {
    trackEvent({
      category: MATOMO_CATEGORIES.createEditPresentation,
      action: MATOMO_ACTIONS.createEditPresentationSelectCoverImage,
    });
  };

  const trackUploadCoverImage = () => {
    trackEvent({
      category: MATOMO_CATEGORIES.createEditPresentation,
      action: MATOMO_ACTIONS.createEditPresentationUploadCoverImage,
    });
  };

  const trackUploadIntroAsset = () => {
    trackEvent({
      category: MATOMO_CATEGORIES.createEditPresentation,
      action: MATOMO_ACTIONS.createEditPresentationUploadIntroAsset,
    });
  };

  const trackSelectGlobalIntroAsset = () => {
    trackEvent({
      category: MATOMO_CATEGORIES.createEditPresentation,
      action: MATOMO_ACTIONS.createEditPresentationSelectGlobalIntroAsset,
    });
  };

  const trackAddProduct = (productName: string) => {
    trackEvent({
      category: MATOMO_CATEGORIES.createEditPresentation,
      action: `${MATOMO_ACTIONS.createEditPresentationAddProduct} (${productName})`,
    });
  };

  const trackUploadCustomContent = () => {
    trackEvent({
      category: MATOMO_CATEGORIES.createEditPresentation,
      action: MATOMO_ACTIONS.createEditPresentationUploadCustomContent,
    });
  };

  // presentation

  const trackStartPresentation = () => {
    trackEvent({
      category: MATOMO_CATEGORIES.presentation,
      action: MATOMO_ACTIONS.presentationStartPresentation,
    });
  };

  // product

  const trackProductView = (productName: string) => {
    trackEvent({
      category: productName,
      action: `${MATOMO_ACTIONS.productViewProduct} (${productName})`,
    });
  };

  const trackUspView = (uspNumber: 1 | 2 | 3, productName: string) => {
    const key: 'productViewUsp1' | 'productViewUsp2' | 'productViewUsp3' = `productViewUsp${uspNumber}`;
    trackEvent({
      category: productName,
      action: `${MATOMO_ACTIONS[key]} (${productName})`,
    });
  };

  // deepDive

  const trackDeepDiveView = (productName: string) => {
    trackEvent({
      category: productName,
      action: `${MATOMO_ACTIONS.deepDiveViewDeepDive} (${productName})`,
    });
  };

  const trackDeepDiveExplosionViewView = (productName: string) => {
    trackEvent({
      category: productName,
      action: `${MATOMO_ACTIONS.deepDiveViewExplosionView} (${productName})`,
    });
  };

  const trackDeepDiveCoreFeatureView = (productName: string) => {
    trackEvent({
      category: productName,
      action: `${MATOMO_ACTIONS.deepDiveViewCoreFeature} (${productName})`,
    });
  };

  const trackDeepDiveConfiguratorView = (productName: string) => {
    trackEvent({
      category: productName,
      action: `${MATOMO_ACTIONS.deepDiveViewConfigurator} (${productName})`,
    });
  };

  const trackDeepDiveTechnicalDataView = (productName: string) => {
    trackEvent({
      category: productName,
      action: `${MATOMO_ACTIONS.deepDiveViewTechnicalData} (${productName})`,
    });
  };

  const trackDeepDiveCustomContentsView = (productName: string) => {
    trackEvent({
      category: productName,
      action: `${MATOMO_ACTIONS.deepDiveViewCustomContents} (${productName})`,
    });
  };

  // offlinePresenter

  const trackOfflinePresenterDownload = () => {
    trackEvent({
      category: MATOMO_CATEGORIES.offlinePresenter,
      action: MATOMO_ACTIONS.offlinePresenterDownload,
    });
  };

  return {
    trackLogin,
    trackCreatePresentation,
    trackSelectCoverImage,
    trackUploadCoverImage,
    trackUploadIntroAsset,
    trackSelectGlobalIntroAsset,
    trackAddProduct,
    trackUploadCustomContent,
    trackStartPresentation,
    trackProductView,
    trackUspView,
    trackDeepDiveView,
    trackDeepDiveExplosionViewView,
    trackDeepDiveCoreFeatureView,
    trackDeepDiveConfiguratorView,
    trackDeepDiveTechnicalDataView,
    trackDeepDiveCustomContentsView,
    trackOfflinePresenterDownload,
  };
}