type VideoType = '.mp4';
type ImageType = '.jpg';

export function useInputValidation() {
  const isPdf = (data: File | string) => {
    return (
      typeof data === 'string'
        ? !!data.match(/\.[pdf]+$/i)?.length
        : data.type === 'application/pdf' || !!data.name.match(/\.[pdf]+$/i)?.length
    );
  };

  const isMp4 = (data: File | string, type?: VideoType) => {
    return (
      typeof data === 'string'
        ? !!data.match(/\.[mp4]+$/i)?.length
        : data.type === 'video/mp4' || !!data.name.match(/\.[mp4]+$/i)?.length
    );
  };

  const isJpg = (data: File | string, type?: ImageType) => {
    return (
      typeof data === 'string'
        ? !!data.match(/\.[jpg|jpeg]+$/i)?.length
        : data.type === 'image/jpg' || !!data.name.match(/\.[mp4]+$/i)?.length
    );
  };

  return { isPdf, isMp4, isJpg };
}
