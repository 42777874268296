/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addCustomContentToProduct = /* GraphQL */ `
  mutation AddCustomContentToProduct(
    $customContent: CustomContentInput!
    $presentationId: String!
    $productName: String!
  ) {
    addCustomContentToProduct(
      customContent: $customContent
      presentationId: $presentationId
      productName: $productName
    ) {
      customContentId
      presentation {
        clientName
        coverImage {
          coverImageId
          fileName
          name
          path
        }
        createdAt
        introAssets {
          category
          fileName
          fileSize
          introAssetId
          isGlobal
          name
          path
          type
        }
        presentationId
        products {
          category
          customContents {
            customContentId
            fileName
            fileSize
            product
            status
            type
            uri
          }
          globalCustomContents {
            fileName
            fileSize
            globalCustomContentId
            path
            product
            status
            type
          }
          productName
        }
        title
        userId
      }
      uploadUrl
    }
  }
`;
export const addGlobalIntroAssetToPresentation = /* GraphQL */ `
  mutation AddGlobalIntroAssetToPresentation(
    $introAssetId: String!
    $presentationId: String!
  ) {
    addGlobalIntroAssetToPresentation(
      introAssetId: $introAssetId
      presentationId: $presentationId
    ) {
      clientName
      coverImage {
        coverImageId
        fileName
        name
        path
      }
      createdAt
      introAssets {
        category
        fileName
        fileSize
        introAssetId
        isGlobal
        name
        path
        type
      }
      presentationId
      products {
        category
        customContents {
          customContentId
          fileName
          fileSize
          product
          status
          type
          uri
        }
        globalCustomContents {
          fileName
          fileSize
          globalCustomContentId
          path
          product
          status
          type
        }
        productName
      }
      title
      userId
    }
  }
`;
export const addIntroAssetToPresentation = /* GraphQL */ `
  mutation AddIntroAssetToPresentation(
    $introAsset: IntroAssetInput!
    $presentationId: String!
  ) {
    addIntroAssetToPresentation(
      introAsset: $introAsset
      presentationId: $presentationId
    ) {
      introAsset {
        category
        fileName
        fileSize
        introAssetId
        isGlobal
        name
        path
        type
      }
      presentation {
        clientName
        coverImage {
          coverImageId
          fileName
          name
          path
        }
        createdAt
        introAssets {
          category
          fileName
          fileSize
          introAssetId
          isGlobal
          name
          path
          type
        }
        presentationId
        products {
          category
          customContents {
            customContentId
            fileName
            fileSize
            product
            status
            type
            uri
          }
          globalCustomContents {
            fileName
            fileSize
            globalCustomContentId
            path
            product
            status
            type
          }
          productName
        }
        title
        userId
      }
      uploadUrl
    }
  }
`;
export const addProductToPresentation = /* GraphQL */ `
  mutation AddProductToPresentation(
    $presentationId: String!
    $productName: String!
  ) {
    addProductToPresentation(
      presentationId: $presentationId
      productName: $productName
    ) {
      clientName
      coverImage {
        coverImageId
        fileName
        name
        path
      }
      createdAt
      introAssets {
        category
        fileName
        fileSize
        introAssetId
        isGlobal
        name
        path
        type
      }
      presentationId
      products {
        category
        customContents {
          customContentId
          fileName
          fileSize
          product
          status
          type
          uri
        }
        globalCustomContents {
          fileName
          fileSize
          globalCustomContentId
          path
          product
          status
          type
        }
        productName
      }
      title
      userId
    }
  }
`;
export const confirmCustomContentUploadToProduct = /* GraphQL */ `
  mutation ConfirmCustomContentUploadToProduct(
    $customContentId: String!
    $presentationId: String!
    $productName: String!
  ) {
    confirmCustomContentUploadToProduct(
      customContentId: $customContentId
      presentationId: $presentationId
      productName: $productName
    )
  }
`;
export const confirmGlobalCustomContentUpload = /* GraphQL */ `
  mutation ConfirmGlobalCustomContentUpload($globalCustomContentId: String!) {
    confirmGlobalCustomContentUpload(
      globalCustomContentId: $globalCustomContentId
    )
  }
`;
export const confirmGlobalIntroAssetUpload = /* GraphQL */ `
  mutation ConfirmGlobalIntroAssetUpload($introAssetId: String!) {
    confirmGlobalIntroAssetUpload(introAssetId: $introAssetId)
  }
`;
export const confirmIntroAssetUploadToPresentation = /* GraphQL */ `
  mutation ConfirmIntroAssetUploadToPresentation(
    $introAssetId: String!
    $presentationId: String!
  ) {
    confirmIntroAssetUploadToPresentation(
      introAssetId: $introAssetId
      presentationId: $presentationId
    ) {
      clientName
      coverImage {
        coverImageId
        fileName
        name
        path
      }
      createdAt
      introAssets {
        category
        fileName
        fileSize
        introAssetId
        isGlobal
        name
        path
        type
      }
      presentationId
      products {
        category
        customContents {
          customContentId
          fileName
          fileSize
          product
          status
          type
          uri
        }
        globalCustomContents {
          fileName
          fileSize
          globalCustomContentId
          path
          product
          status
          type
        }
        productName
      }
      title
      userId
    }
  }
`;
export const createCoverImage = /* GraphQL */ `
  mutation CreateCoverImage($coverImage: CoverImageInput!) {
    createCoverImage(coverImage: $coverImage) {
      coverImage {
        coverImageId
        fileName
        name
        path
      }
      uploadUrl
    }
  }
`;
export const createGlobalCustomContent = /* GraphQL */ `
  mutation CreateGlobalCustomContent(
    $globalCustomContent: GlobalCustomContentInput!
  ) {
    createGlobalCustomContent(globalCustomContent: $globalCustomContent) {
      globalCustomContent {
        fileName
        fileSize
        globalCustomContentId
        path
        product
        status
        type
      }
      uploadUrl
    }
  }
`;
export const createGlobalIntroAsset = /* GraphQL */ `
  mutation CreateGlobalIntroAsset($introAsset: IntroAssetInput!) {
    createGlobalIntroAsset(introAsset: $introAsset) {
      introAsset {
        category
        fileName
        fileSize
        introAssetId
        isGlobal
        name
        path
        type
      }
      uploadUrl
    }
  }
`;
export const createPresentation = /* GraphQL */ `
  mutation CreatePresentation($presentation: PresentationInput!) {
    createPresentation(presentation: $presentation) {
      clientName
      coverImage {
        coverImageId
        fileName
        name
        path
      }
      createdAt
      introAssets {
        category
        fileName
        fileSize
        introAssetId
        isGlobal
        name
        path
        type
      }
      presentationId
      products {
        category
        customContents {
          customContentId
          fileName
          fileSize
          product
          status
          type
          uri
        }
        globalCustomContents {
          fileName
          fileSize
          globalCustomContentId
          path
          product
          status
          type
        }
        productName
      }
      title
      userId
    }
  }
`;
export const createProductBundle = /* GraphQL */ `
  mutation CreateProductBundle($productName: String!) {
    createProductBundle(productName: $productName) {
      createdAt
      productName
      size
      zipKey
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($user: CreateUserInput!) {
    createUser(user: $user) {
      businessDivision
      businessNeed
      creationDate
      department
      email
      enabled
      firstName
      id
      keyAccount
      lastName
      phone
      productCenter
      region
      role
      status
    }
  }
`;
export const deleteAccountRequest = /* GraphQL */ `
  mutation DeleteAccountRequest($accountRequestId: String!) {
    deleteAccountRequest(accountRequestId: $accountRequestId) {
      businessDivision
      businessNeed
      department
      email
      firstName
      id
      keyAccount
      lastName
      phone
      productCenter
      region
      requestType
    }
  }
`;
export const deleteCoverImage = /* GraphQL */ `
  mutation DeleteCoverImage($coverImageId: String!) {
    deleteCoverImage(coverImageId: $coverImageId)
  }
`;
export const deleteCustomContentFromProduct = /* GraphQL */ `
  mutation DeleteCustomContentFromProduct(
    $customContentId: String!
    $presentationId: String!
    $productName: String!
  ) {
    deleteCustomContentFromProduct(
      customContentId: $customContentId
      presentationId: $presentationId
      productName: $productName
    ) {
      clientName
      coverImage {
        coverImageId
        fileName
        name
        path
      }
      createdAt
      introAssets {
        category
        fileName
        fileSize
        introAssetId
        isGlobal
        name
        path
        type
      }
      presentationId
      products {
        category
        customContents {
          customContentId
          fileName
          fileSize
          product
          status
          type
          uri
        }
        globalCustomContents {
          fileName
          fileSize
          globalCustomContentId
          path
          product
          status
          type
        }
        productName
      }
      title
      userId
    }
  }
`;
export const deleteGlobalCustomContent = /* GraphQL */ `
  mutation DeleteGlobalCustomContent($globalCustomContentId: String!) {
    deleteGlobalCustomContent(globalCustomContentId: $globalCustomContentId)
  }
`;
export const deleteGlobalIntroAsset = /* GraphQL */ `
  mutation DeleteGlobalIntroAsset($introAssetId: String!) {
    deleteGlobalIntroAsset(introAssetId: $introAssetId)
  }
`;
export const deletePresentation = /* GraphQL */ `
  mutation DeletePresentation($presentationId: String!) {
    deletePresentation(presentationId: $presentationId)
  }
`;
export const deleteReactivationRequest = /* GraphQL */ `
  mutation DeleteReactivationRequest($reactivationRequestId: String!) {
    deleteReactivationRequest(reactivationRequestId: $reactivationRequestId) {
      businessDivision
      businessNeed
      department
      email
      firstName
      id
      keyAccount
      lastName
      phone
      productCenter
      region
      requestType
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($userId: String!) {
    deleteUser(userId: $userId)
  }
`;
export const enableUser = /* GraphQL */ `
  mutation EnableUser($userId: String!) {
    enableUser(userId: $userId)
  }
`;
export const releaseOfflinePresenterVersion = /* GraphQL */ `
  mutation ReleaseOfflinePresenterVersion {
    releaseOfflinePresenterVersion
  }
`;
export const removeIntroAssetFromPresentation = /* GraphQL */ `
  mutation RemoveIntroAssetFromPresentation(
    $introAssetId: String!
    $presentationId: String!
  ) {
    removeIntroAssetFromPresentation(
      introAssetId: $introAssetId
      presentationId: $presentationId
    ) {
      clientName
      coverImage {
        coverImageId
        fileName
        name
        path
      }
      createdAt
      introAssets {
        category
        fileName
        fileSize
        introAssetId
        isGlobal
        name
        path
        type
      }
      presentationId
      products {
        category
        customContents {
          customContentId
          fileName
          fileSize
          product
          status
          type
          uri
        }
        globalCustomContents {
          fileName
          fileSize
          globalCustomContentId
          path
          product
          status
          type
        }
        productName
      }
      title
      userId
    }
  }
`;
export const removeProductFromPresentation = /* GraphQL */ `
  mutation RemoveProductFromPresentation(
    $presentationId: String!
    $productName: String!
  ) {
    removeProductFromPresentation(
      presentationId: $presentationId
      productName: $productName
    ) {
      clientName
      coverImage {
        coverImageId
        fileName
        name
        path
      }
      createdAt
      introAssets {
        category
        fileName
        fileSize
        introAssetId
        isGlobal
        name
        path
        type
      }
      presentationId
      products {
        category
        customContents {
          customContentId
          fileName
          fileSize
          product
          status
          type
          uri
        }
        globalCustomContents {
          fileName
          fileSize
          globalCustomContentId
          path
          product
          status
          type
        }
        productName
      }
      title
      userId
    }
  }
`;
export const requestAccount = /* GraphQL */ `
  mutation RequestAccount($account: RequestAccountInput!) {
    requestAccount(account: $account) {
      businessDivision
      businessNeed
      department
      email
      firstName
      id
      keyAccount
      lastName
      phone
      productCenter
      region
      requestType
    }
  }
`;
export const requestReactivation = /* GraphQL */ `
  mutation RequestReactivation($account: RequestReactivationInput!) {
    requestReactivation(account: $account) {
      businessDivision
      businessNeed
      department
      email
      firstName
      id
      keyAccount
      lastName
      phone
      productCenter
      region
      requestType
    }
  }
`;
export const resetUserMfa = /* GraphQL */ `
  mutation ResetUserMfa($userId: String!) {
    resetUserMfa(userId: $userId)
  }
`;
export const resetUserPassword = /* GraphQL */ `
  mutation ResetUserPassword($userId: String!) {
    resetUserPassword(userId: $userId)
  }
`;
export const setCoverImageForPresentation = /* GraphQL */ `
  mutation SetCoverImageForPresentation(
    $coverImageId: String!
    $presentationId: String!
  ) {
    setCoverImageForPresentation(
      coverImageId: $coverImageId
      presentationId: $presentationId
    ) {
      clientName
      coverImage {
        coverImageId
        fileName
        name
        path
      }
      createdAt
      introAssets {
        category
        fileName
        fileSize
        introAssetId
        isGlobal
        name
        path
        type
      }
      presentationId
      products {
        category
        customContents {
          customContentId
          fileName
          fileSize
          product
          status
          type
          uri
        }
        globalCustomContents {
          fileName
          fileSize
          globalCustomContentId
          path
          product
          status
          type
        }
        productName
      }
      title
      userId
    }
  }
`;
export const setCustomCoverImageForPresentation = /* GraphQL */ `
  mutation SetCustomCoverImageForPresentation(
    $coverImage: CoverImageInput!
    $presentationId: String!
  ) {
    setCustomCoverImageForPresentation(
      coverImage: $coverImage
      presentationId: $presentationId
    ) {
      presentation {
        clientName
        coverImage {
          coverImageId
          fileName
          name
          path
        }
        createdAt
        introAssets {
          category
          fileName
          fileSize
          introAssetId
          isGlobal
          name
          path
          type
        }
        presentationId
        products {
          category
          customContents {
            customContentId
            fileName
            fileSize
            product
            status
            type
            uri
          }
          globalCustomContents {
            fileName
            fileSize
            globalCustomContentId
            path
            product
            status
            type
          }
          productName
        }
        title
        userId
      }
      uploadUrl
    }
  }
`;
export const synchronizeGlobalCustomContentForProduct = /* GraphQL */ `
  mutation SynchronizeGlobalCustomContentForProduct(
    $presentationId: String!
    $productName: String!
  ) {
    synchronizeGlobalCustomContentForProduct(
      presentationId: $presentationId
      productName: $productName
    ) {
      clientName
      coverImage {
        coverImageId
        fileName
        name
        path
      }
      createdAt
      introAssets {
        category
        fileName
        fileSize
        introAssetId
        isGlobal
        name
        path
        type
      }
      presentationId
      products {
        category
        customContents {
          customContentId
          fileName
          fileSize
          product
          status
          type
          uri
        }
        globalCustomContents {
          fileName
          fileSize
          globalCustomContentId
          path
          product
          status
          type
        }
        productName
      }
      title
      userId
    }
  }
`;
export const updatePresentation = /* GraphQL */ `
  mutation UpdatePresentation(
    $presentation: PresentationInput!
    $presentationId: String!
  ) {
    updatePresentation(
      presentation: $presentation
      presentationId: $presentationId
    ) {
      clientName
      coverImage {
        coverImageId
        fileName
        name
        path
      }
      createdAt
      introAssets {
        category
        fileName
        fileSize
        introAssetId
        isGlobal
        name
        path
        type
      }
      presentationId
      products {
        category
        customContents {
          customContentId
          fileName
          fileSize
          product
          status
          type
          uri
        }
        globalCustomContents {
          fileName
          fileSize
          globalCustomContentId
          path
          product
          status
          type
        }
        productName
      }
      title
      userId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($user: UpdateUserInput, $userId: String!) {
    updateUser(user: $user, userId: $userId) {
      businessDivision
      businessNeed
      creationDate
      department
      email
      enabled
      firstName
      id
      keyAccount
      lastName
      phone
      productCenter
      region
      role
      status
    }
  }
`;
