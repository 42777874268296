import Auth, { CognitoUser } from '@aws-amplify/auth';
import { ICredentials } from '@aws-amplify/core';
import { AuthServiceType } from '../types/auth.service';
import { OnlinePresenterAuthService } from './online-presenter-auth.service';

export class OfflinePresenterAuthService extends OnlinePresenterAuthService implements AuthServiceType {
  constructor(private localApiUrl: string, private hasInternetConnection: () => boolean) {
    super();
  }

  public async authCurrentUser() {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser() as CognitoUser;
      if (user && this.hasInternetConnection()) {
        await fetch(this.localApiUrl + 'auth/user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(user),
        });
      }
    } catch (err) {
      if (err === "The user is not authenticated" && this.hasInternetConnection()) {
        await fetch(this.localApiUrl + 'auth/logout-user', { method: 'POST' });
      }
    }
    if (!user && !this.hasInternetConnection()) {
      user = await (await fetch(this.localApiUrl + 'auth/user', { method: 'GET' })).json() as CognitoUser;
    }
    return user;
  }

  public async getCurrentCredentials() {
    try {
      if (!this.hasInternetConnection()) {
        return await (await fetch(this.localApiUrl + 'auth/credentials', { method: 'GET' })).json() as ICredentials;
      } else {
        const creds = await Auth.currentCredentials();
        if (creds && this.hasInternetConnection()) {
          await fetch(this.localApiUrl + 'auth/credentials', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(creds),
          });
        }
        return creds;
      }
    } catch (err) {
      return undefined;
    }
  }
}