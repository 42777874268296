import { Box, makeStyles, Radio, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '../../../../src/assets/img/delete-icon-outlined.svg';
import { useCoverImagesApi } from '../../../hooks/use-cover-images-api';
import { FadeInAnimation, HELLA_COLORS } from '../../../theme';

const useStyles = makeStyles((theme) => ({
  coverImageCard: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minWidth: 250,
    height: 290,
    maxHeight: 320,
  },
  coverImageCardContent: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      opacity: 1,
    },
    opacity: 0,
  },
  coverImageCardAction: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    '& > p': {
      color: 'white',
      fontSize: 12,
      opacity: 0,
      transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '& > img': {
      transform: 'translateY(16px)',
      transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '&:hover': {
      '& > img': {
        transform: 'translateY(-4px)',
      },
      '& > p': {
        opacity: 1,
      },
    },
  },
  coverImageRoot: {
    display: 'flex',
    height: '100%',
    flexFlow: 'column',
  },
  coverImageCheckBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(7),
  },
  radioButtonRoot: {
    '&:hover': {
      backgroundColor: `${HELLA_COLORS.grey8} !important`,
    },
  },
  radioButtonIcon: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.common.black}`,
    display: 'flex',
    width: 20,
    height: 20,
  },
  radioButtonCheckedIcon: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: 'black',
    margin: 'auto',
  },
  previewButton: {
    width: 250,
  },
  previewButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  fadeIn: {
    animation: '$fadeIn 0.5s ease-in-out forwards',
  },
  '@keyframes fadeIn': FadeInAnimation,
}));

type CoverImageCardType = {
  imageUrl: string;
  selected: boolean;
  onSelect: () => void;
  onDelete: () => void;
  disableMarginLeft?: boolean;
};

export default function AdminCoverImageCard({
  imageUrl,
  selected,
  onSelect,
  onDelete,
  disableMarginLeft,
}: CoverImageCardType) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isLoading } = useCoverImagesApi();
  return (
    <Box className={classes.coverImageRoot} ml={disableMarginLeft ? 0 : 8}>
      <Box
        className={classes.coverImageCard}
        style={{ backgroundImage: `url('${imageUrl}')` }}
      >
        <Box className={clsx(classes.coverImageCardContent, classes.fadeIn)}>
          <Box className={classes.coverImageCardAction} onClick={onDelete}>
            {!isLoading && <img src={DeleteIcon} alt="delete" />}
            <Typography>{t('generic.button.delete')}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.coverImageCheckBox}>
        <Radio
          className={classes.radioButtonRoot}
          checked={selected}
          onChange={onSelect}
          icon={<span className={classes.radioButtonIcon} />}
          checkedIcon={
            <span className={classes.radioButtonIcon}>
              <span className={classes.radioButtonCheckedIcon} />
            </span>
          }
          disableRipple
        />
      </Box>
    </Box>
  );
}
