import {
  makeStyles,
  Stepper
} from '@material-ui/core';
import { MouseEvent } from 'react';
import { useHistory } from 'react-router';
import { abortMediaLoading } from '../../utils/abort-loading';
import { Routes } from '../routing/routes';
import ProductCustomStep from './product-stepper/product-custom-step';

const useStyles = makeStyles((theme) => ({
  Stepper: {
    width: '100%',
    maxWidth: 450,
    backgroundColor: 'transparent',
    padding: 0,
    paddingTop: theme.spacing(10),
    alignItems: 'flex-start',
    position: 'absolute',
    bottom: '3%',
    left: '50px',
  },
  '@media (max-width: 1100px)': {
    Stepper: {
      paddingRight: theme.spacing(20),
    },
  },
}));

type PresentationBottomStepperProps = {
  currentStep: number;
  disableIntro: boolean;
};


function PresentationBottomStepper({ currentStep, disableIntro }: PresentationBottomStepperProps) {
  const classes = useStyles();
  const history = useHistory();

  const toStart = (e: MouseEvent) => history.push(Routes.PresentationStart);
  const toIntro = (e: MouseEvent) => history.push(Routes.PresentationIntro);
  const toProducts = (e: MouseEvent) =>
    history.push(Routes.PresentationProducts);

  const handleStepClick = (action: "toStart" | "toIntro" | "toProducts", event: MouseEvent) => {
    abortMediaLoading();

    switch (action) {
      case "toStart":
        toStart(event);
        break;
      case "toIntro":
        toIntro(event);
        break;
      case "toProducts":
        toProducts(event);
        break;
    }
  }

  return (
    <Stepper
      className={classes.Stepper}
      orientation="horizontal"
      activeStep={currentStep}
    >
      <ProductCustomStep
        onClick={(event) => { currentStep !== 0 && handleStepClick("toStart", event) }}
        activeStep={currentStep}
        stepIndex={0}
      />
      {!disableIntro &&
        <ProductCustomStep
          onClick={(event) => { currentStep !== 1 && handleStepClick("toIntro", event) }}
          activeStep={currentStep}
          stepIndex={1}
        />
      }
      <ProductCustomStep
        onClick={(event) => { currentStep !== 2 && handleStepClick("toProducts", event) }}
        activeStep={currentStep}
        stepIndex={2}
      />
    </Stepper>
  );
}


export default PresentationBottomStepper;