import { createContext, ReactNode, useState } from "react";
import ErrorDialog from "../components/general/error-dialog";
import { ErrorDialogProps } from "../types/general-types";

export type ErrorContextProps = {
  errorDialogState: ErrorDialogProps;
  setErrorDialogState(val: ErrorDialogProps): void;
}

export const ErrorContext = createContext<ErrorContextProps>({
  errorDialogState: { open: false },
  setErrorDialogState: () => undefined,
});

export type ErrorContextProviderProps = {
  children?: ReactNode;
}

export const ErrorContextProvider = ({ children }: ErrorContextProviderProps) => {
  const [errorDialogState, setErrorDialogState] = useState<ErrorDialogProps>({ open: false });

  return (
    <ErrorContext.Provider value={{
      errorDialogState,
      setErrorDialogState,
    }}>
      {children}
      <ErrorDialog onClose={() => setErrorDialogState({ open: false })} {...errorDialogState} />
    </ErrorContext.Provider>
  );
}