import { Box, Dialog, DialogProps, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { FadeInAnimation } from "../../theme";
import { elementFadingIn } from "../../utils/element-fading";
import { FileType } from "../../utils/get-FileType";
import PresentationIntroOverlay from "../presentation/intro-overlay/presentation-intro-overlay";
import PresentationIntroPdfViewer from "../presentation/presentation-intro-pdf-viewer";

const useStyles = makeStyles((theme) => ({
  container: {
    '&:hover': {
      '& > #overlay-container': {
        opacity: 1,
      }
    },
    overflowX: 'hidden',
  },
  overlayContainer: {
    opacity: 0,
    transition: 'opacity 0.25s ease-in-out',
  },
  fadeIn: {
    animation: '$fadeIn 0.5s ease-in-out forwards',
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      opacity: 0,
      visibility: 'hidden',
      transition: theme.transitions.create("all", { duration: '0.3s' })
    }
  },
  mediaHidden: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'all 0.5s',
  },
  '@keyframes fadeIn': FadeInAnimation,
}));

interface Props extends DialogProps {
  name?: string;
  src?: string;
  type?: FileType;
}

function PreviewDialog({ name, src, type, ...dialogProps }: Props) {
  const classes = useStyles();
  const imageRef = useRef<HTMLImageElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [currentPdfPage, setCurrentPdfPage] = useState<number>(1);
  const [pdfTotalPages, setTotalPages] = useState(1);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);

  const onResumeVideo = () => {
    videoRef.current?.play();
  };

  const onPauseVideo = () => {
    videoRef.current?.pause();
    setVideoIsPlaying(false);
  };

  useEffect(() => {
    if (dialogProps.open) {
      setCurrentPdfPage(1);
      setVideoIsPlaying(false);
    }
  }, [dialogProps.open]);

  return (
    <Dialog {...dialogProps} fullScreen>
      <Box width="100%" height="100%" className={clsx(classes.container, classes.fadeIn)}>
        {type === 'video'
          ? (
            <Box
              position="relative"
              width="100%" height="100%"
              onClick={onPauseVideo}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <video
                ref={videoRef}
                width="100%"
                height="100%"
                autoPlay={false}
                onPlay={(e) => setVideoIsPlaying(true)}
                onLoadedData={() => {
                  if (videoRef.current) {
                    elementFadingIn(videoRef.current)
                  }
                }}
                onEnded={() => setVideoIsPlaying(false)}
                className={classes.mediaHidden}
                style={{ objectFit: 'cover' }}
                src={src}
              />
            </Box>
          ) : type === 'image' ? (
            <Box className={classes.imageWrapper}>
              <img
                src={src}
                alt=""
                style={{ height: '100%' }}
                ref={imageRef}
                className={classes.mediaHidden}
                onLoad={() => {
                  if (imageRef.current) {
                    elementFadingIn(imageRef.current)
                  }
                }}
              />
            </Box>
          ) : type === 'pdf' ? (
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <PresentationIntroPdfViewer
                fileUrl={src || ''}
                currentPage={currentPdfPage}
                setTotalPages={setTotalPages}
              />
            </Box>
          ) : <></>
        }
        <Box
          id="overlay-container"
          className={classes.overlayContainer}
          position="absolute"
          top={0} bottom={0} left={0} right={0}
          width="100%" height="100%"
          style={{ pointerEvents: 'none' }}
        >
          <PresentationIntroOverlay
            onPrevious={() => undefined}
            onNext={() => undefined}
            label={name}
            pdf={type === 'pdf' ? {
              currentPage: currentPdfPage,
              totalPages: pdfTotalPages,
              onNext: () => setCurrentPdfPage(currentPdfPage + 1),
              onPrevious: () => setCurrentPdfPage(currentPdfPage - 1),
            } : undefined}
            video={type === 'video' ? {
              isPlaying: videoIsPlaying,
              onPlay: onResumeVideo,
            } : undefined}
            onClose={() => dialogProps.onClose?.({}, 'backdropClick')}
          />
        </Box>
      </Box>
    </Dialog>
  )
}

export default PreviewDialog;
