import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import GlobalIntroAssetsIcon from '../../../src/assets/img/admin/admin-intro-icon.svg';
import CoverImagesIcon from '../../../src/assets/img/admin/Symbol_Images.svg';
import CustomContentsIcon from '../../../src/assets/img/admin/Symbol_ProductSetting.svg';
import kpiDashboardsIcon from '../../../src/assets/img/admin/Symbol_KPI.svg';
import UserManagementIcon from '../../../src/assets/img/admin/Symbol_Users.svg';
import ProductAccessibilityIcon from '../../../src/assets/img/admin/Symbol_Product_Accessibility.svg';
import { ReactComponent as CloseIcon } from '../../../src/assets/img/close-icon.svg';
import { HELLA_COLORS } from '../../theme';
import { Routes } from '../routing/routes';

import useAdminRole from '../../hooks/use-admin-role';

export type AdminNavigationDialogProps = {
  open: boolean;
  onClose?: () => void;
  customTabProps?: TabProps[];
};

type TabProps = {
  title: string;
  route?: string;
  img: string;
  disabled?: boolean;
  onClick?(): void;
};

const useStyles = makeStyles<Theme>((theme) => ({
  tabCard: {
    color: HELLA_COLORS.grey6,
    border: `1px solid ${HELLA_COLORS.grey6}`,
    width: 290,
    height: 290,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',
    '& > img': {
      transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '& > p': {
      marginTop: theme.spacing(15),
      color: HELLA_COLORS.grey2,
      fontSize: 24,
      transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  tabCardDisabled: {
    opacity: 0.5,
    cursor: 'unset',
  },
  tabCardHover: {
    '&:hover': {
      border: `1px solid ${theme.palette.common.black}`,
      '& > img': {
        filter: 'contrast(5)',
      },
      '& > p': {
        color: theme.palette.common.black,
      },
    },
  },
  dialogRoot: {
    '& > div > div': {
      borderRadius: 0,
    },
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: (isUserAdmin) => isUserAdmin ? 'wrap': undefined,
    backgroundColor: theme.palette.common.white,
    '& > div': {
      marginRight: theme.spacing(8),
      marginTop: theme.spacing(8),
    },
    '& > div:last-child': {
      marginRight: 16,
    },
    borderRadius: 0,
    padding: '25px 100px 75px 100px',
  },
  dialogActions: {
    background: theme.palette.common.white,
  },
  closeButton: {
    '& > span': {
      width: 24,
    },
    '& svg > path': {
      fill: HELLA_COLORS.black,
    }
  },
}));

const TabCard = ({ title, route, img, disabled, onClick }: TabProps) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box
      className={clsx(
        classes.tabCard,
        disabled ? classes.tabCardDisabled : classes.tabCardHover
      )}
      onClick={() =>
        onClick?.() || (!disabled && route ? history.push(route) : undefined)
      }
    >
      <img src={img} alt={title} width={80} />
      <Typography align="center">{title}</Typography>
    </Box>
  );
};

const AdminNavigationDialog = ({
  open,
  onClose,
  customTabProps,
}: AdminNavigationDialogProps) => {
  const { t } = useTranslation();
  const { isAdmin: isUserSuperAdmin } = useAdminRole('super-admin');
  const classes = useStyles(isUserSuperAdmin);

  const tabs: TabProps[] = customTabProps || [
    {
      title: t('adminNavBar.tab.userManagement'),
      route: Routes.AdminUserManagement,
      img: UserManagementIcon,
      disabled: isUserSuperAdmin ? false: true
    },
    {
      title: t('adminNavBar.tab.coverImages'),
      route: Routes.AdminCoverImages,
      img: CoverImagesIcon,
      disabled: false
    },
    {
      title: t('adminNavBar.tab.globalIntroAssets'),
      route: Routes.AdminGlobalIntroAssets,
      img: GlobalIntroAssetsIcon,
      disabled: false
    },
    {
      title: t('adminNavBar.tab.customContent'),
      route: Routes.AdminCustomContents,
      img: CustomContentsIcon,
      disabled: false
    },
    {
      title: t('adminNavBar.tab.kpidashboard'),
      route: Routes.AdminKpiDashboardView,
      img: kpiDashboardsIcon,
      disabled: false
    },
    {
      title: t('adminNavBar.tab.productAccessibility'),
      route: Routes.AdminProductAccessibility,
      img: ProductAccessibilityIcon,
      disabled: isUserSuperAdmin ? false: true
    },
  ];


  const filteredTab = tabs.filter(t => !isUserSuperAdmin? t.title !== "User Management" && t.title !== "Product Accessibility": true);
 

  return (
    <Dialog open={open} fullWidth maxWidth="lg" className={classes.dialogRoot}>
      <DialogActions className={classes.dialogActions}>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogContent}>
        {filteredTab.map((tab, idx) => (
            <TabCard
              key={tab.title}
              title={tab.title}
              route={tab.route}
              img={tab.img}
              onClick={() =>  tab.onClick?.()}
              disabled={tab.disabled}
            />
          ))}
      </DialogContent>
    </Dialog>
  );
};

export default AdminNavigationDialog;
