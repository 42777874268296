import { Box, makeStyles, Theme } from '@material-ui/core';
import { HELLA_COLORS } from '../../../theme';

const useStyles = makeStyles<Theme, TrackingCellProps>((theme) => ({
  circle: {
    marginRight: 10,
    height: 20,
    width: 20,
    backgroundColor: (props) =>
      props.circleColor === 'tertiaryDarkest'
        ? HELLA_COLORS.tertiaryDarkest
        : props.circleColor === 'tertiaryDarker'
        ? HELLA_COLORS.tertiaryDarker
        : props.circleColor === 'tertiaryDark'
        ? HELLA_COLORS.tertiaryDark
        : props.circleColor === 'tertiaryMain'
        ? HELLA_COLORS.tertiaryMain
        : HELLA_COLORS.tertiaryLight,
    borderRadius: '50%',
  },
}));

type TrackingCellProps = {
  circleColor?: string;
};

const Circle = ({ circleColor }: TrackingCellProps) => {
  const classes = useStyles({ circleColor });
  return <Box className={classes.circle}></Box>;
};

export default Circle;
