export async function doFetch(input: RequestInfo, init?: RequestInit | undefined, options?: { parseResponse: 'json' | 'text' }) {
  const res = await fetch(input, init);
  if (!res.ok) {
    throw res.statusText;
  }
  if (options?.parseResponse === 'text') {
    return await res.text();
  } else {
    return await res.json();
  }
}
