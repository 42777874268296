import { Auth } from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { AUTH_TYPE } from 'aws-appsync';
import { Resource } from 'i18next';
import { createStore, StateMachineProvider } from 'little-state-machine';
import { Suspense } from 'react';
import Router from './components/routing/router';
import Spinner from './components/spinner/spinner';
import AuthContextProvider from './contexts/auth-context';
import { ErrorContextProvider } from './contexts/error-context';
import PresentationContextProvider from './contexts/presentation-context';
import { ProductContextProvider } from './contexts/product-context';
import initI18n from './i18n/i18n';
import translations from './i18n/translations.json';
import darkTheme from './theme';
import { createMatomoInstance } from './utils/matomo-utils';

initI18n(translations as Resource, 'en');

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    apiKey: process.env.REACT_APP_APPSYNC_API_KEY,
  },
  API: {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT,
    aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION,
    aws_appsync_authenticationType: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_API_KEY,
    jwtToken: async () =>
      (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
});

createStore(
  {
    auth: {
      email: '',
      phoneNumber: '',
    },
    presentationCreator: {
      title: '',
      clientName: '',
      createdAt: '',
      coverImage: { path: '' },
      products: [],
    },
    presentation: {
      title: '',
      clientName: '',
      createdAt: '',
      coverImage: { path: '' },
      products: [],
    },
  },
  {
    name: 'store',
    middleWares: [],
    storageType: sessionStorage,
  }
);

const matomoInstance = createMatomoInstance();

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {/** @ts-ignore */}
      <MatomoProvider value={matomoInstance}>
        <StateMachineProvider>
          <ErrorContextProvider>
            <AuthContextProvider>
              <ProductContextProvider>
                <PresentationContextProvider>
                  <Suspense fallback={<Spinner />}>
                    <Router />
                  </Suspense>
                </PresentationContextProvider>
              </ProductContextProvider>
            </AuthContextProvider>
          </ErrorContextProvider>
        </StateMachineProvider>
      </MatomoProvider>
    </ThemeProvider >
  );
}

export default App;
