import {
  Box,
  makeStyles,
  Typography,
  Button,
  IconButton,
  Theme,
} from '@material-ui/core';
import DeleteIcon from '../../../src/assets/img/admin/delete-icon.svg';
import LoadingIcon from '../../../src/assets/img/admin/Symbol_loading.svg';
import ReplayIcon from '../../../src/assets/img/admin/noun_replay_2093775.svg';
import AddIcon from '../../../src/assets/img/add-icon.svg';
import { useRef } from 'react';
import clsx from 'clsx';
import { FadeInAnimation, HELLA_COLORS } from '../../theme';
import { ReactComponent as PdfIcon } from '../../assets/img/admin/pdf-icon.svg';
import { ReactComponent as VideoIcon } from '../../assets/img/admin/video-icon.svg';
import { FilesProduct } from '../../../src/types/global-custom-content-types';
import { getFileType } from '../../utils/get-FileType';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: '100%',
    background: 'white',
    padding: theme.spacing(30),
    minHeight: (props: any) => props.previewButton ? 605 : 550 ,
  },
  headline: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(17),
  },
  files: {
    minHeight: '280px',
    maxHeight: '280px',
    marginBottom: theme.spacing(8),
  },
  file: {
    borderBottom: `1px solid ${theme.palette.common.black}`,
    padding: '13px 0',
    display: 'flex',
    '& > div:first-of-type': {
      display: 'flex',
      minWidth: 70,
      maxWidth: 70,
      minHeight: 40,
      maxHeight: 40,
      '& > img': {
        width: '100%',
        objectFit: 'contain',
      },
      '& > video': {
        width: '100%',
        objectFit: 'contain',
      },
    },
    '& > div:nth-of-type(2)': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingLeft: theme.spacing(10),
      '& > p': {
        marginBottom: theme.spacing(4),
        color: theme.palette.common.black,
      },
      '& > span': {
        color: HELLA_COLORS.grey4,
      },
    },
    '& > div:last-of-type': {
      display: 'flex',
    },
  },
  uploadActions: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(15),
    '& > button': {
      width: 'calc(100% - 15px)',
      '&:first-of-type': {
        marginRight: theme.spacing(8),
      },
      '&:last-of-type': {
        marginLeft: theme.spacing(8),
      },
    },
  },
  previewButton: {
    width: '100%',
  },
  delete: {
    color: HELLA_COLORS.grey8,
    cursor: (props: any) =>
      props.isCustomContentUploading ? 'unset' : 'pointer',
  },
  buttonContainer: {
    width: '100%',
    height: (props) => props.previewButton ? 200 : 175,
    border: `1px dashed ${HELLA_COLORS.grey4}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    
    '&:first-of-type': {
      marginBottom: theme.spacing(12),
    },
    '& > p': {
      fontFamily: 'Hella-Regular',
    },
  },
  addButton: {
    background: HELLA_COLORS.grey1,
    marginBottom: theme.spacing(10),
    '& > span > img': {
      filter: 'invert(1)',
    },
    '&:hover': {
      background: theme.palette.common.black,
    },
  },
  addButtonDisabled: {
    background: HELLA_COLORS.grey8,
  },
  replayIcon: {
    marginRight: theme.spacing(5),
    cursor: 'pointer',
  },
  loadingIcon: {
    marginRight: theme.spacing(10),
    animation: `$rotate ease-in-out 1000ms infinite`,
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  fadeIn: {
    animation: '$fadeIn 0.5s ease-in-out forwards',
  },
  '@keyframes fadeIn': FadeInAnimation,
  '@media (max-height: 900px)': {
    card: {
      padding: 40,
      minHeight: (props: any) => props.previewButton ? 505 : 450 ,
    },
    files: {
      maxHeight: '217px',
      minHeight: '217px',
      marginBottom: '16px'
    },
    buttonContainer: {
      height: (props) => props.previewButton ? 165 : 140,
    }
  },
}));

type AdminContentCardProp = {
  title: string;
  files: FilesProduct[] | null;
  previewButton?: boolean;
  imageButton?: boolean;
  pdfButton?: boolean;
  loading?: boolean;
  isCustomContentUploading?: string | false;
  error?: boolean;
  onDelete?: (file: any, productName: string) => void;
  selectImage?: (filePath: any) => void;
  selectVideo?: (filePath: any) => void;
  selectPdf?: (filePath: any) => void;
  openPreview?: () => void;
};

const AdminContentCard = ({
  title,
  files,
  previewButton = true,
  imageButton = true,
  pdfButton = false,
  loading = false,
  isCustomContentUploading,
  error = false,
  onDelete,
  selectImage,
  selectVideo,
  selectPdf,
  openPreview,
}: AdminContentCardProp) => {
  const classes = useStyles({ isCustomContentUploading, previewButton });
  const videoInputRef = useRef<HTMLInputElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const pdfInputRef = useRef<HTMLInputElement>(null);
  return (
    <Box
      className={clsx(classes.card, classes.fadeIn)}
    >
      <Box className={classes.headline}>
        <Typography variant="h3">{title}</Typography>
      </Box>
      {files && files.length > 0 ? (
        <>
          <Box
            className={classes.files}
            style={{ overflowY: files.length > 3 ? 'scroll' : 'unset' }}
          >
            {files &&
              files.map((file, fileIdx) => (
                <Box
                  className={classes.file}
                  key={'file_' + file.fileName + '_' + fileIdx}
                >
                  <Box>
                    {file.type === 'application/pdf' ? (
                      <PdfIcon />
                    ) : (
                      <VideoIcon />
                    )}
                  </Box>
                  <Box>
                    <Typography>
                      {file?.fileName?.length! > 25
                        ? file?.fileName?.substring(0, 25) + '...'
                        : file.fileName}
                    </Typography>
                    <span style={{ color: error ? '#FF0000' : '#878787' }}>
                      {error
                        ? 'Something went wrong. Please try again'
                        : `${(file?.fileSize! as number / 1000000).toFixed(2)} MB`}
                    </span>
                  </Box>
                  <Box>
                    {error && (
                      <img
                        className={classes.replayIcon}
                        src={ReplayIcon}
                        alt="ReplayIcon"
                        onClick={() => {
                          if (file && file.fileName && getFileType(file.fileName) === 'image' && selectImage) {
                            selectImage(file);
                          } else if (file && file.fileName && getFileType(file.fileName) === 'video' && selectVideo) {
                            selectVideo(file);
                          } else if (file && file.fileName && getFileType(file.fileName) === 'pdf' && selectPdf) {
                            selectPdf(file);
                          }
                        }}
                      />
                    )}
                    <img
                      className={classes.delete}
                      src={DeleteIcon}
                      alt="deleteIcon"
                      onClick={() => onDelete!(file.globalCustomContentId, title)}
                    />
                  </Box>
                </Box>
              ))}

            <Box display="flex" justifyContent="center" marginTop="15px">
              {isCustomContentUploading === title && (
                <img
                  className={classes.loadingIcon}
                  src={LoadingIcon}
                  alt="LoadingIcon"
                />
              )}
            </Box>
          </Box>

          <Box className={classes.uploadActions}>
            {pdfButton && (
              <Button
                variant="outlined"
                onClick={() => pdfInputRef.current?.click()}
                disabled={!!isCustomContentUploading}
              >
                + PDF
                <input
                  ref={pdfInputRef}
                  type="file"
                  name="file input pdf"
                  style={{ display: 'none' }}
                  accept=".pdf"
                  onChange={() => {
                    if (pdfInputRef!.current!.files) {
                      let file = pdfInputRef.current?.files[0];
                      if (file && selectPdf) selectPdf(file);
                    }
                  }}
                />
              </Button>
            )}
            {imageButton && (
              <Button
                variant="outlined"
                onClick={() => imageInputRef.current?.click()}
                disabled={!!isCustomContentUploading}
              >
                + Image
                <input
                  ref={imageInputRef}
                  type="file"
                  name="file input image"
                  style={{ display: 'none' }}
                  accept=".jpg"
                  onChange={() => {
                    if (imageInputRef!.current!.files) {
                      let file = imageInputRef.current?.files[0];
                      if (file && selectImage) selectImage(file);
                    }
                  }}
                />
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => videoInputRef.current?.click()}
              disabled={!!isCustomContentUploading}
            >
              + Video
              <input
                ref={videoInputRef}
                type="file"
                name="file input video"
                style={{ display: 'none' }}
                accept=".mp4"
                onChange={() => {
                  if (videoInputRef!.current!.files) {
                    let file = videoInputRef?.current?.files[0];
                    if (file && selectVideo) selectVideo(file);
                  }
                }}
              />
            </Button>
          </Box>
          {previewButton && (
            <Button
              variant="contained"
              className={classes.previewButton}
              onClick={openPreview}
            >
              Preview
            </Button>
          )}
        </>
      ) : (
        <Box>
          {pdfButton && (
            <Box
              className={classes.buttonContainer}
            >
              <>
                <IconButton
                  className={clsx(
                    classes.addButton,
                    isCustomContentUploading && classes.addButtonDisabled
                  )}
                  onClick={() => pdfInputRef.current?.click()}
                >
                  <img src={AddIcon} alt="AddIcon" />
                  <input
                    disabled={!!isCustomContentUploading}
                    ref={pdfInputRef}
                    type="file"
                    name="file input image"
                    style={{ display: 'none' }}
                    accept=".pdf"
                    onChange={() => {
                      if (pdfInputRef!.current!.files) {
                        let file = pdfInputRef?.current?.files[0];
                        if (file && selectPdf) selectPdf(file);
                      }
                    }}
                  />
                </IconButton>
                <Typography
                  className={clsx(isCustomContentUploading && classes.delete)}
                >
                  Add an PDF(.pdf)
                </Typography>
              </>
            </Box>
          )}
          {imageButton && (
            <Box
              className={classes.buttonContainer}
            >
              <IconButton
                className={classes.addButton}
                onClick={() => imageInputRef.current?.click()}
              >
                <img src={AddIcon} alt="AddIcon" />
                <input
                  ref={imageInputRef}
                  type="file"
                  name="file input image"
                  style={{ display: 'none' }}
                  accept=".jpg"
                  onChange={() => {
                    if (imageInputRef!.current!.files) {
                      let file = imageInputRef?.current?.files[0];
                      if (file && selectImage) selectImage(file);
                    }
                  }}
                />
              </IconButton>
              <Typography>Add an image(.jpg)</Typography>
            </Box>
          )}
          <Box
            className={classes.buttonContainer}
          >
            <IconButton
              className={clsx(
                classes.addButton,
                isCustomContentUploading && classes.addButtonDisabled
              )}
              onClick={() => videoInputRef.current?.click()}
            >
              <img src={AddIcon} alt="AddIcon" />
              <input
                disabled={!!isCustomContentUploading}
                ref={videoInputRef}
                type="file"
                name="file input video"
                style={{ display: 'none' }}
                accept=".mp4"
                onChange={() => {
                  if (videoInputRef!.current!.files) {
                    let file = videoInputRef?.current?.files[0];
                    if (file && selectVideo) selectVideo(file);
                  }
                }}
              />
            </IconButton>
            <Typography
              className={clsx(isCustomContentUploading && classes.delete)}
            >
              Add an video(.mp4)
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AdminContentCard;
