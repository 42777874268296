import { Time } from "../types/kpi-dashboard-types";
import { PresentationType } from "../types/presentation-types";
import { BaseUser } from "../types/user-api-types";

const transformDateToObjectDate = (datum: string) => {
  const dateString = datum; // '6/9/2021';
  const dateParts: any = dateString.split('/');
  // month is 0-based, that's why we need dataParts[1] - 1
  const dateObject: any = new Date(+dateParts[2],  dateParts[1] - 1, +dateParts[0]);
  return dateObject;
};


const trackingDate = (t: number, period: string) => {
  const now = new Date();
  return period === ('DAY' || 'WEEK')
    ? new Date(now.getFullYear(), now.getMonth(), now.getDate() - t)
    : new Date(now.getFullYear(), now.getMonth() - t, now.getDate());
};

export const  handleTimeTracking = (
  type: 'users' | 'presentations',
  data:  (PresentationType | BaseUser)[],
  period: string
) => {
    return data.filter(el => {
        const creationDate = type === 'users' ? (el as BaseUser)?.creationDate! :(el as PresentationType)?.createdAt!;
        const creationDateObject = type === 'users'? transformDateToObjectDate(creationDate.replaceAll('.', '/')): ( el as PresentationType)?.createdAt!;
        if (period === 'DAY')
          return trackingDate(Time.DAY, period) < creationDateObject;
        if (period === 'WEEK')
          return trackingDate(Time.WEEK, period) < creationDateObject;
        if (period === 'MONTH')
          return trackingDate(Time.MONTH, period) < creationDateObject;
        if (period === 'QUATER')
          return trackingDate(Time.QUATER, period) < creationDateObject;
        if (period === 'YEAR')
          return trackingDate(Time.YEAR, period) < creationDateObject;
        return undefined;
      }).length;
}