import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PresentationInput } from '../API';
import { AuthContext } from '../contexts/auth-context';
import { ErrorContext } from '../contexts/error-context';
import { ProductContext } from '../contexts/product-context';
import { useInputValidation } from '../hooks/use-input-validation';
import { OfflinePresenterPresentationService } from '../services/offline-presenter-presentation.service';
import { OnlinePresenterPresentationService } from '../services/online-presenter-presentation.service';
import { CoverImageType } from '../types/global-cover-images-types';
import { PresentationType } from '../types/presentation-types';
import { PresentationServiceType } from '../types/presentation.service';
import { getOfflineAssetRole } from '../utils/offline-asset-roles';
import { parseApiErrors } from '../utils/parse-api-errors';
import { useEnvironment } from './use-environment';
import { useIntroApi, useIntroApiResponse } from './use-intro-api';
import { useTracking } from './use-tracking';

export type UsePresentationApiResponse = ReturnType<typeof usePresentationApi> &
  useIntroApiResponse;

export function usePresentationApi() {
  const [presentationList, setPresentationList] = useState<PresentationType[]>(
    []
  );
  const { getProductsAsArray, refreshProductData } = useContext(ProductContext)

  const [remotePresentationList, setRemotePresentationList] =
    useState<PresentationType[]>();
  const [alteredPresentation, setAlteredPresentation] = useState<
    PresentationType | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const { t } = useTranslation();
  const { isPdf } = useInputValidation();
  const {
    introAssets,
    assetLoading,
    introError,
    introProgress,
    fetchIntroAssets,
    addIntroAsset,
    deleteIntroAsset,
    confirmIntroAssetUpload,
  } = useIntroApi({ presentationId: alteredPresentation?.presentationId });
  const [customContentProgress, setCustomContentProgress] = useState<number>(0);
  const lastCustomContentProgress = useRef(-10);
  const [coverImageProgress, setCoverImageProgress] = useState<number>(0);
  const lastCoverImageProgress = useRef(-10);
  const { isOfflinePresenter, localApiUrl, hasInternetConnection, getCurrentLocalVersion } =
    useEnvironment();
  const { user } = useContext(AuthContext);
  const presentationService: PresentationServiceType = useMemo(() => {
    return (isOfflinePresenter && user?.attributes?.['custom:role'])
      ? new OfflinePresenterPresentationService(
        localApiUrl,
        user.getUsername?.() || (user as any).username,
        getOfflineAssetRole(user.attributes['custom:role']),
        getCurrentLocalVersion()
      )
      : new OnlinePresenterPresentationService();
  }, [isOfflinePresenter, localApiUrl, getCurrentLocalVersion, user]);
  const { setErrorDialogState } = useContext(ErrorContext);
  const { trackCreatePresentation, trackSelectCoverImage, trackUploadCoverImage, trackAddProduct, trackUploadCustomContent } = useTracking();

  useEffect(() => {
    fetchPresentations()
      .catch((err) => {
        setErrorDialogState({
          open: true,
          errorMessage: err?.message || '',
        });
      });
    // eslint-disable-next-line
  }, [hasInternetConnection]);

  useEffect(() => {
    if (introError) throw parseApiErrors(introError);
  }, [introError]);

  const detectDeletedPresentations = async (
    offlinePresentationList: PresentationType[] | undefined
  ) => {
    if (offlinePresentationList && hasInternetConnection) {
      const onlineServices = new OnlinePresenterPresentationService();
      const onlinePresentationList = await onlineServices.listPresentations();
      const updatedList: PresentationType[] = [];

      if (onlinePresentationList) {
        offlinePresentationList.forEach((offlinePresentation) => {
          let presentationDeleted = true;
          for (const onlinePresentation of onlinePresentationList) {
            if (
              offlinePresentation.presentationId ===
              onlinePresentation.presentationId
            ) {
              presentationDeleted = false;
              break;
            }
          }

          offlinePresentation.presentationDeleted = presentationDeleted;
          updatedList.push(offlinePresentation);
        });
      }
      return updatedList;
    }

    return offlinePresentationList;
  };

  const synchronizePresentations = async () => {
    try {
      setIsLoading(true);
      const onlineServices = new OnlinePresenterPresentationService();
      const onlinePresentationList = await onlineServices.listPresentations();

      if (onlinePresentationList && 0 < onlinePresentationList.length) {
        const successSynchronization =
          await presentationService.synchronizeLocalPresentationList(onlinePresentationList);

        if (successSynchronization) {
          await fetchPresentations();
          refreshProductData();
        }
        setIsLoading(false);
      }
    } catch (err: any) {
      isLoading && setIsLoading(false);
      throw new Error(t('presentationApi.error.synchronizePresentations'));
    }
  };

  const fetchPresentations = async () => {
    try {
      const data = await presentationService.listPresentations();
      const checkedData = await detectDeletedPresentations(data);
      setPresentationList(checkedData || []);
      if (hasInternetConnection && isOfflinePresenter) {
        const onlineServices = new OnlinePresenterPresentationService();
        const onlinePresentationList = await onlineServices.listPresentations();
        if (onlinePresentationList && 0 < onlinePresentationList.length) {
          setRemotePresentationList(onlinePresentationList);
        }
      }
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b000'),
        additionalMessages: err,
      });
    }
  };

  const fetchPresentation = async (presentationId: string) => {
    try {
      const data = await presentationService.getPresentation(presentationId);
      setAlteredPresentation(data!);
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b001'),
        additionalMessages: err,
      });
    }
  };

  const createPresentation = async (presentation: PresentationInput) => {  
    try {
      setIsLoading(true);
      const data = await presentationService.createPresentation(presentation);
      if (!data) {
        throw new Error(t('presentationApi.error.createPresentation'));
      }
      setAlteredPresentation(data);
      setIsLoading(false);
      trackCreatePresentation();
      return data;
    } catch (err: any) {
      setIsLoading(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b001'),
        additionalMessages: err,
      });
    }
  };

  const createPlaceholderPresentation = async () => {
    try {
      setIsLoading(true);
      let pres = await createPresentation({
        clientName: 'Hella',
        coverImage: {},
        title: 'Product Overview',
      });

      if (pres?.presentationId) {
        try {
          await addProducts(pres.presentationId, getProductsAsArray().map(p => p.name));
          setIsLoading(false);
        } catch (err: any) {
          throw err;
        }
      }
    } catch (err: any) {
      throw err;
    }
  };

  const updatePresentation = async (
    presentationId: string,
    presentation: PresentationInput
  ) => {
    try {
      setIsLoading(true);
      const data = await presentationService.updatePresentation(
        presentationId,
        presentation
      );
      if (!data) {
        throw new Error(t('presentationApi.error.updatePresentation'));
      }
      setAlteredPresentation(data);
      setIsLoading(false);
      return data;
    } catch (err: any) {
      setIsLoading(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b003'),
        additionalMessages: err,
      });
    }
  };

  const deletePresentation = async (presentationId: string) => {
    try {
      setIsLoading(true);
      const data = await presentationService.deletePresentation(presentationId);
      if (!data) {
        throw new Error(t('presentationApi.error.deletePresentation'));
      }
      setIsLoading(false);
      await fetchPresentations();
    } catch (err: any) {
      setIsLoading(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b004'),
        additionalMessages: err,
      });
    }
  };

  const getCoverImages = async (): Promise<CoverImageType[] | undefined> => {
    try {
      const data = await presentationService.listCoverImages();
      if (!data) {
        throw new Error(t('presentationApi.error.noCoverImages'));
      }
      return data;
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b005'),
        additionalMessages: err,
      });
    }
  };

  const setCoverImage = async (
    presentationId: string,
    coverImageId: string
  ) => {
    try {
      setIsLoading(true);
      const data = await presentationService.setCoverImage(
        presentationId,
        coverImageId
      );
      if (!data) {
        throw new Error(t('presentationApi.error.setCoverImage'));
      }
      setAlteredPresentation(data);
      setIsLoading(false);
      trackSelectCoverImage();
    } catch (err: any) {
      setIsLoading(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b006'),
        additionalMessages: err,
      });
    }
  };

  const uploadCoverImage = async (presentationId: string, file: File) => {
    try {
      setIsLoading(true);
      const data = await presentationService.uploadCoverImage(
        presentationId,
        file,
        {
          onprogress: (ev) => {
            const percent_completed = (ev.loaded / ev.total) * 100;
            if (
              percent_completed >= lastCoverImageProgress.current + 10 ||
              percent_completed === 100
            ) {
              lastCoverImageProgress.current = percent_completed;
              setCoverImageProgress(percent_completed);
            }
          },
          onerror: (ev) => {
            setErrorDialogState({
              open: true,
              errorMessage: t('errors.b008'),
              // additionalMessages: parseApiErrors(ev.target),
            });
          },
          onloadend: (ev) => {
            lastCoverImageProgress.current = 0;
            setCoverImageProgress(0);
            setIsFileUploading(false);
          },
        }
      );
      if (!data) {
        throw new Error(t('presentationApi.error.setCoverImage'));
      }
      setIsLoading(false);
      trackUploadCoverImage();
      return data;
    } catch (err: any) {
      setIsFileUploading(false);
      setIsLoading(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b007'),
        additionalMessages: err,
      });
    }
  };

  const addProduct = async (presentationId: string, productName: string) => {
    try {
      setIsLoading(true);
      const data = await presentationService.addProduct(
        presentationId,
        productName
      );
      if (!data) {
        throw new Error(t('presentationApi.error.addProduct'));
      }
      setAlteredPresentation(data);
      setIsLoading(false);
      trackAddProduct(productName);
    } catch (err: any) {
      setIsLoading(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b009'),
        additionalMessages: err,
      });
    }
  };

  const addProducts = async (
    presentationId: string,
    productNames: string[]
  ) => {
    try {
      setIsLoading(true);
      const data = await presentationService.addProducts(
        presentationId,
        productNames
      );
      if (data) {
        setAlteredPresentation(data);
      }
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b010'),
        additionalMessages: err,
      });
    }
  };

  const deleteProduct = async (presentationId: string, productName: string) => {
    try {
      setIsLoading(true);
      const data = await presentationService.deleteProduct(
        presentationId,
        productName
      );
      if (!data) {
        throw new Error(t('presentationApi.error.deleteProduct'));
      }
      setAlteredPresentation(data);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b011'),
        additionalMessages: err,
      });
    }
  };

  const deleteProducts = async (
    presentationId: string,
    productNames: string[]
  ) => {
    try {
      setIsLoading(true);
      const data = await presentationService.deleteProducts(
        presentationId,
        productNames
      );
      if (data) {
        setAlteredPresentation(data);
      }
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b012'),
        additionalMessages: err,
      });
    }
  };

  const getActiveGlobalCustomContentsForProduct = async (
    productName: string
  ) => {
    try {
      setIsLoading(true);
      const data =
        await presentationService.listActiveGlobalCustomContentsForProduct(
          productName
        );
      setIsLoading(false);
      return data;
    } catch (err: any) {
      setIsLoading(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b013'),
        additionalMessages: err,
      });
    }
  };

  const addCustomContent = async (
    presentationId: string,
    productName: string,
    file: File
  ) => {
    try {
      setIsLoading(true);
      const customContentInput = {
        fileName: file.name,
        type: isPdf(file) ? 'pdf' : 'video',
      };
      const data = await presentationService.uploadCustomContent(
        presentationId,
        productName,
        file,
        customContentInput,
        {
          onloadstart: () => {
            setIsFileUploading(true);
          },
          onprogress: (ev) => {
            const percent_completed = (ev.loaded / ev.total) * 100;
            if (
              percent_completed >= lastCustomContentProgress.current + 10 ||
              percent_completed === 100
            ) {
              lastCustomContentProgress.current = percent_completed;
              setCustomContentProgress(percent_completed);
            }
          },
          onerror: () => {
            setErrorDialogState({
              open: true,
              errorMessage: t('errors.b015'),
              // additionalMessages: err,
            });
          },
          onloadend: () => {
            setIsFileUploading(false);
            lastCustomContentProgress.current = -10;
            setCustomContentProgress(0);
          },
        }
      );
      if (data) {
        setAlteredPresentation(data as PresentationType);
      }
      setIsLoading(false);
      trackUploadCustomContent();
    } catch (err: any) {
      setIsLoading(false);
      setIsFileUploading(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b014'),
        additionalMessages: err,
      });

      if (Object.keys(user?.storage).length === 0) {
        window.location.href = '/';
      }
    }
  };

  const deleteCustomContent = async (
    presentationId: string,
    productName: string,
    customContentId: string
  ) => {
    try {
      setIsLoading(true);
      const data = await presentationService.deleteCustomContent(
        presentationId,
        productName,
        customContentId
      );
      if (!data) {
        throw new Error(t('presentationApi.error.deleteCustomContent'));
      }
      setAlteredPresentation(data);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b017'),
        additionalMessages: err,
      });

      if (Object.keys(user?.storage).length === 0) {
        window.location.href = '/';
      }
    }
  };

  const synchronizeGlobalCustomContentsForProduct = async (
    presentationId: string,
    productName: string
  ) => {
    try {
      setIsLoading(true);
      const data =
        await presentationService.synchronizeGlobalCustomContentsForProduct(
          presentationId,
          productName
        );
      if (!data) {
        throw new Error(
          t('presentationApi.error.synchronizeGlobalCustomContents')
        );
      }
      setAlteredPresentation(data);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.b018'),
        additionalMessages: err,
      });

      if (Object.keys(user?.storage).length === 0) {
        window.location.href = '/';
      }
    }
  };

  const getLatestSyncVersion = async () => {
    return await presentationService.getLatestSyncVersion?.() || undefined;
  }

  const createPresentationPdf = async (presentationId: string) => {
    return await presentationService.createPresentationPdf(presentationId);
  }

  return {
    presentationList,
    remotePresentationList,
    setPresentationList,
    fetchPresentations,
    fetchPresentation,
    alteredPresentation,
    setAlteredPresentation,
    createPresentation,
    updatePresentation,
    deletePresentation,
    addProduct,
    addProducts,
    deleteProduct,
    deleteProducts,
    getActiveGlobalCustomContentsForProduct,
    addCustomContent,
    deleteCustomContent,
    synchronizeGlobalCustomContentsForProduct,
    getCoverImages,
    setCoverImage,
    uploadCoverImage,
    isLoading,
    isFileUploading,
    introAssets,
    introError,
    assetLoading,
    introProgress,
    fetchIntroAssets,
    addIntroAsset,
    deleteIntroAsset,
    confirmIntroAssetUpload,
    customContentProgress,
    coverImageProgress,
    synchronizePresentations,
    createPlaceholderPresentation,
    getLatestSyncVersion,
    createPresentationPdf,
  };
}
