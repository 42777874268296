import { useTranslation } from 'react-i18next';
import AdminPageLayout from '../../components/Layouts/admin-page-layout';
import { FadeInAnimation, HELLA_COLORS } from '../../theme';
import clsx from 'clsx';
import CustomTabs from '../../components/general/custom-tabs';
import {
  Box,
  Theme,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import CustomTab from '../../components/general/custom-tab';
import { useContext, useEffect, useState } from 'react';
import ProducsAccessibilityList from '../../components/admin/product-accessibility-view/products-list';
import ProductAccessibilityActions from '../../components/admin/product-accessibility-view/product-accessibility-actions';
import CustomCheckbox from '../../components/general/custom-checkbox';
import { ProductContext } from '../../contexts/product-context';
import { UserRole, UserStatus, useUserApi } from '../../hooks/use-user-api';
import { User } from '../../API';
import TableHeader from '../../components/admin/product-accessibility-view/table-header-users-per-product';
import ProgressIndicator from '../../components/general/progress-indicator';

const useStyles = makeStyles((theme: Theme) => ({
  tabsContainer: {
    width: '100%',
    marginBottom: theme.spacing(16),
  },
  radioButtonRoot: {
    '&:hover': {
      backgroundColor: `${HELLA_COLORS.grey8} !important`,
    },
  },
  radioButtonIcon: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.common.black}`,
    display: 'flex',
    width: 20,
    height: 20,
  },
  radioButtonCheckedIcon: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: 'black',
    margin: 'auto',
  },
  fadeIn: {
    animation: '$fadeIn 0.5s ease-in-out forwards',
  },
  '@keyframes fadeIn': FadeInAnimation,
  '@media (max-height: 900px)': {
    contentRoot: {
      height: 490,
      overflowY: 'hidden',
      overflowX: 'scroll',
    },
  },
}));

const AdminProductAccessibilityPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const { newProductToUserAdded, getAllowedUsersForProduct, updateUserListForProduct, getUserListForProduct, isProductListFetched } = useContext(ProductContext);
  const { getUsers } = useUserApi();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [usersList, setUsersList] = useState<User[] | undefined>([]);
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const [selectedUsersId, setSelectedUsersId] = useState<string[]>([]);
  const [filteredUsersListForProduct, setFilteredUsersListForProduct] = useState<User[]>([]);
  const [matchedUsersListForProduct, setMatcheddUsersListForProduct] = useState<User[]>([]);
  const [userSearchInput, setUserSearchInput] = useState('');
  const [isUserListSorted, setIsUserListSorted] = useState(true);
  const [loading, setLoading] = useState(false);

  const getFilteredUsersListForProduct = (productName: string) => {
    getAllowedUsersForProduct(productName).then((usersId) => {
      const filteredUsersListForProduct = usersList?.filter((user: User) => usersId?.includes(user?.id!));
      setFilteredUsersListForProduct(filteredUsersListForProduct!);
    });
  };

  useEffect(() => {
    getUsers().then(users => {
      const confirmedAndEnabledNoAdminUsers = users?.filter(u => {
        const okStatus = (
          u.status === UserStatus.CONFIRMED ||
          u.status === UserStatus.DISABLED ||
          u.status === UserStatus.FORCE_CHANGE_PASSWORD ||
          u.status === UserStatus.RESET_REQUIRED
        );
        return (u.role !== UserRole.HellaPresenterSuperAdmins && okStatus);
      });
      setUsersList(confirmedAndEnabledNoAdminUsers);
    });
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    getFilteredUsersListForProduct(newProductToUserAdded?.product);
    setSelectedUsersId([]);
    // eslint-disable-next-line
  }, [newProductToUserAdded]);

  useEffect(() => {
    setLoading(true);
    getUserListForProduct(selectedProduct)
      .finally(() => {
        setSelectedUsersId([]);
        setMatcheddUsersListForProduct([]);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [selectedProduct]);

  useEffect(() => {
    const matchedUserList = filteredUsersListForProduct.filter(user => user.firstName?.toLocaleLowerCase().includes(userSearchInput));
    setMatcheddUsersListForProduct(matchedUserList);
    // eslint-disable-next-line
  }, [userSearchInput])


  const handleDeleteUsers = async () => {
    if (selectedProduct && !!selectedUsersId.length) {
      const removedUsersFromMatchedList = (matchedUsersListForProduct).filter((user) => !selectedUsersId.includes(user?.id!));
      const removedUsersFromProductList = (filteredUsersListForProduct).filter((user) => !selectedUsersId.includes(user?.id!));
      const removedUsersId = removedUsersFromProductList.map(u => u?.id!);
      updateUserListForProduct(selectedProduct, removedUsersId);
      setFilteredUsersListForProduct(removedUsersFromProductList);
      setMatcheddUsersListForProduct(removedUsersFromMatchedList);
      setUserSearchInput('');
      setSelectedUsersId([]);
    }
  };

  return (
    <>
      <AdminPageLayout
        headline={t('adminGlobalProductAccessibility.page.headline')}
        description={t('adminGlobalProductAccessibility.page.description')}
      >
        <>
          <Box className={clsx(classes.tabsContainer, classes.fadeIn)}>
            <CustomTabs
              value={selectedTab}
              onChange={(event, value: number) => setSelectedTab(value)}
              fullWidth
              spacing={60}
              fontcolor={HELLA_COLORS.white}
            >
              <CustomTab label={t('adminCustomContents.tab.allProducts')} />
              <CustomTab label={t('adminCustomContents.tab.ge')} />
              <CustomTab label={t('adminCustomContents.tab.gl')} />
            </CustomTabs>
          </Box>
          <Box
            style={{
              height: '80%',
              width: '100%',
              backgroundColor: theme.palette.common.white,
              display: 'flex',
            }}
          >
            <ProducsAccessibilityList
              isProductListFetched={isProductListFetched}
              selectedCategoryTab={selectedTab}
              handleSelectedProduct={(product) => {
                setSelectedProduct(product);
                getFilteredUsersListForProduct(product);
              }}
            />
            <Box
              width="100%"
              border="none"
              borderLeft={`2px solid ${HELLA_COLORS.grey9}`}
              height="100%"
              paddingTop={16}
              display="flex"
              flexDirection="column"
              overflow="hidden"
            >
              <ProductAccessibilityActions
                searchInputText={userSearchInput}
                selectedProduct={selectedProduct}
                usersList={usersList}
                isDeleteUserPossible={!!selectedProduct && !!selectedUsersId.length}
                handleSearchUser={(userSearch) => setUserSearchInput(userSearch)}
                handleDeleteUsers={() => handleDeleteUsers()}
              />

              <TableHeader handleSortUser={(bool: boolean) => setIsUserListSorted(bool)} />

              <Box flex={1} padding={8} overflow="auto" position="relative">
                <Box
                  position="absolute"
                  top={0} left={0} right={0} bottom={0}
                  style={{ backgroundColor: 'rgba(255,255,255,0.5)', opacity: loading ? 1 : 0, transition: 'opacity 0.25s' }}
                  display="flex" justifyContent="center" alignItems="center"
                >
                  <ProgressIndicator />
                </Box>

                {!!filteredUsersListForProduct?.length && ((!!matchedUsersListForProduct.length && !!userSearchInput.length) ? matchedUsersListForProduct : filteredUsersListForProduct)
                  ?.sort((u1: User, u2: User) => isUserListSorted ? (u1?.email ?? '').localeCompare(u2?.email!) : (u2?.email ?? '').localeCompare(u1?.email!))
                  .map((user: User) => {
                    return (
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        marginBottom={8}
                        key={user.id}
                      >
                        <CustomCheckbox
                          style={{ marginRight: 42, marginLeft: 4 }}
                          checked={!!selectedUsersId.includes(user?.id!)}
                          onClick={() => {
                            setSelectedUsersId([...selectedUsersId, user?.id!])
                            if (selectedUsersId.includes(user?.id!)) {
                              const removeUserFromSelectedList = selectedUsersId.filter(id => id !== user?.id);
                              setSelectedUsersId(removeUserFromSelectedList)
                            }
                          }
                          }
                        />
                        <Box marginLeft={10}>
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 'bold' }}
                          >
                            {user.firstName}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ marginTop: 8 }}
                          >
                            {user.email}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>

            </Box>
          </Box>
        </>
      </AdminPageLayout>
    </>
  );
};

export default AdminProductAccessibilityPage;
