import Auth from '@aws-amplify/auth';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountRequestType } from '../API';
import { ErrorContext } from '../contexts/error-context';
import { EnvironmentService } from '../services/environment.service';
import { OfflinePresenterAssetService } from '../services/offline-presenter-asset.service';
import { OfflinePresenterAuthService } from '../services/offline-presenter-auth.service';
import { OnlinePresenterAssetService } from '../services/online-presenter-asset.service';
import { OnlinePresenterAuthService } from '../services/online-presenter-auth.service';
import { AssetServiceType } from '../types/asset.service';
import {
  AuthUser,
  ConfirmSmsCodeType,
  MfaTypes,
  SignInInput
} from '../types/auth-types';
import { AuthServiceType } from '../types/auth.service';
import { ErrorDialogProps } from '../types/general-types';
import { getOfflineAssetRole } from '../utils/offline-asset-roles';
import { PathType, getFullProductAssetPath } from '../utils/product-utils';
import { useTracking } from './use-tracking';
import { useUserApi } from './use-user-api';

export function useAuth() {
  const environmentService = useMemo(() => new EnvironmentService(), []);
  const [user, setUser] = useState<AuthUser | null>(null);
  const [userCredentials, setUserCredentials] = useState<any>(null);
  const authService: AuthServiceType = useMemo(
    () =>
      environmentService.isOfflinePresenter
        ? new OfflinePresenterAuthService(
          environmentService.localApiUrl,
          environmentService.hasInternetConnection
        )
        : new OnlinePresenterAuthService(),
    [environmentService]
  );
  const assetService: AssetServiceType | undefined = useMemo(
    () =>
      environmentService.isOfflinePresenter
        ? (user?.attributes?.['custom:role']
          ? new OfflinePresenterAssetService(
            environmentService.localApiUrl,
            environmentService.assetBucketId,
            user?.getUsername?.() || (user as any)?.username,
            getOfflineAssetRole(user.attributes['custom:role']),
          )
          : undefined
        )
        : new OnlinePresenterAssetService(),
    [environmentService, user]
  );
  const { setErrorDialogState } = useContext(ErrorContext);
  const { t } = useTranslation();
  const { trackLogin } = useTracking();
  const { requestUserReactivation } = useUserApi({ disableUseEffect: true });

  // console.debug('||| in useAuth hook - USER', user);
  // console.debug('||| PREFFERED MFA', user?.preferredMFA);
  // console.debug('||| PHONE', user?.attributes?.phone_number);
  // console.debug('||| PHONE VERIFIED', user?.attributes?.phone_number_verified);

  const [loading, setLoading] = useState<boolean>(true);

  let mountedRef = useRef<boolean>();

  useEffect(() => {
    mountedRef.current = true;
    checkAuth();
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, [authService]);

  const checkAuth = async () => {
    try {
      const currentUser = await authService.authCurrentUser();
      if (mountedRef.current) {
        if (!currentUser) {
          setUserCredentials(null);
          setUser(null);
          setLoading(false);
          return;
        }
        const creds = await authService.getCurrentCredentials();
        if (creds) {
          setUserCredentials(Auth.essentialCredentials(creds));
          setUser(currentUser);
          setLoading(false);
        } else {
          setUserCredentials(null);
          setUser(null);
          setLoading(false);
        }
      }
    } catch (err) {
      if (mountedRef.current) {
        setUserCredentials(null);
        setUser(null);
        setLoading(false);
      }
      if (
        !window.location.href.includes('login') &&
        err === 'The user is not authenticated'
      ) {
        window.location.href = '/';
      }
    }
  };

  const signS3RequestSync = (
    filename: string | null = '',
    config: {
      productName?: string,
      pathType?: PathType,
    },
    onlyUseOnlineService?: boolean
  ) => {
    if (config && config.productName && config.pathType && filename) {
      filename = getFullProductAssetPath(config.productName, config.pathType, filename);
    }
    if (onlyUseOnlineService) {
      return new OnlinePresenterAssetService().getAssetPath(filename, userCredentials);
    }
    return assetService?.getAssetPath(filename || null, userCredentials, user?.getUsername?.() || (user as any)?.username) || '';
  };

  const signIn = useCallback(
    async ({ email, password }: SignInInput) => {
      try {
        const signInStatus = await authService.signIn(email, password);
        setUser(signInStatus);
        trackLogin();
      } catch (error: any) {
        const errorProps: ErrorDialogProps = { errorMessage: error.message, additionalMessages: t('errors.a002'), open: true };
        if (error?.message.toLowerCase().includes('user is disabled')) {
          errorProps.errorMessage = t('login.error.userDisabled');
          errorProps.forceDisplayCancelButton = true;
          errorProps.buttonText = 'Request Reactivation';
          errorProps.buttonAction = () => requestUserReactivation({ email, requestType: AccountRequestType.REACTIVATION });
        }
        setErrorDialogState(errorProps);
      }
    },
    [setUser, authService, setErrorDialogState, t, trackLogin, requestUserReactivation]
  );

  async function confirmSmsCode(confirmData: ConfirmSmsCodeType) {
    try {
      if (user !== null) {
        await authService.confirmSmsCode(confirmData);
      } else {
        console.debug('user not defined');
        setErrorDialogState({ errorMessage: 'You need to go back to login again.', additionalMessages: t('errors.a004'), open: true });
      }
    } catch (error: any) {
      setErrorDialogState({ errorMessage: error.message || '', additionalMessages: t('errors.a004'), open: true });
      console.debug('confirmSignInMFA ERROR', error);
      throw error;
    }
  }

  async function confirmTotpCode(confirmData: ConfirmSmsCodeType) {
    try {
      if (user !== null) {
        await authService.confirmTotpCode(confirmData);
      } else {
        setErrorDialogState({ errorMessage: 'You need to go back to login again.', additionalMessages: t('errors.a005'), open: true });
      }
    } catch (error: any) {
      setErrorDialogState({ errorMessage: error.message || '', additionalMessages: t('errors.a005'), open: true });
      console.debug('confirmSignInMFA ERROR', error);
      throw error;
    }
  }

  async function newPasswordChallenge(newPassword: string) {
    try {
      const setNewPassword = await authService.newPasswordChallenge(
        user,
        newPassword
      );
      console.debug('password is set:', setNewPassword);
    } catch (error: any) {
      setErrorDialogState({ errorMessage: error.message || '', additionalMessages: t('errors.a006'), open: true });
      console.debug('CHALLENGE ERROR', error);
    }
  }

  const signOut = useCallback(async () => {
    await authService.signOut();
    setUserCredentials(null);
    setUser(null);
  }, [setUser, authService]);

  async function verifyTotpToken(user: any, challengeAnswer: string) {
    try {
      await authService.verifyTotpToken(user, challengeAnswer);
    } catch (err: any) {
      setErrorDialogState({ errorMessage: err.message || '', additionalMessages: t('errors.a007'), open: true });
      console.debug('verifyTotpToken ERROR', err);
      throw err;
    }
  }

  async function selectPreferredMFA(user: any, mfaMethod: MfaTypes) {
    try {
      await authService.selectPreferredMFA(user, mfaMethod);
    } catch (err: any) {
      setErrorDialogState({ errorMessage: err.message || '', additionalMessages: t('errors.a008'), open: true });
      console.debug('setPreferredMFA ERRROR:', err);
    }
  }

  return {
    userCredentials,
    user,
    loading,
    signIn,
    signOut,
    confirmSmsCode,
    confirmTotpCode,
    newPasswordChallenge,
    verifyTotpToken,
    // totpCode,
    selectPreferredMFA,
    signS3RequestSync,
    checkAuth,
  };
}
