import Auth from '@aws-amplify/auth';
import { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { hasUserRole } from '../../utils/user-utils';
import { Routes } from './routes';

type Props = {
  path: string;
  exact: boolean;
  children: JSX.Element;
};

const AdminAuthRoute = ({ children, path, exact }: Props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    (async function checkAdminUserSession() {
      try {
        const currentUserSession = await Auth.currentSession();
        setIsLoggedIn(
          currentUserSession.isValid() && hasUserRole('admin', currentUserSession)
        );
      } catch (error) {
        setIsLoggedIn(false);
      }
    })();
  }, []);

  return isLoggedIn ? (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  ) : (
    <Redirect to={Routes.Login} />
  );
};
export default AdminAuthRoute;
