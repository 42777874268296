export const elementFadingIn = (element: HTMLImageElement|HTMLVideoElement|HTMLDivElement) => {
    if(element) {
        element.style.visibility = "visible";
        element.style.opacity = "1";
    }
};

export const elementFadingOut = (element: HTMLImageElement|HTMLVideoElement|HTMLDivElement) => {
    if(element) {
        element.style.visibility = "hidden";
        element.style.opacity = "0";
    }
}