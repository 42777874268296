/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CustomContentInput = {
  fileName: string,
  type: string,
};

export type AddCustomContentToProductResponse = {
  __typename: "AddCustomContentToProductResponse",
  customContentId?: string | null,
  presentation?: Presentation | null,
  uploadUrl?: string | null,
};

export type Presentation = {
  __typename: "Presentation",
  clientName?: string | null,
  coverImage?: CoverImage | null,
  createdAt?: string | null,
  introAssets?:  Array<IntroAsset | null > | null,
  presentationId?: string | null,
  products?:  Array<Product | null > | null,
  title?: string | null,
  userId?: string | null,
};

export type CoverImage = {
  __typename: "CoverImage",
  coverImageId?: string | null,
  fileName?: string | null,
  name?: string | null,
  path?: string | null,
};

export type IntroAsset = {
  __typename: "IntroAsset",
  category?: string | null,
  fileName?: string | null,
  fileSize?: number | null,
  introAssetId?: string | null,
  isGlobal?: boolean | null,
  name?: string | null,
  path?: string | null,
  type?: string | null,
};

export type Product = {
  __typename: "Product",
  category?: string | null,
  customContents?:  Array<CustomContent | null > | null,
  globalCustomContents?:  Array<GlobalCustomContent | null > | null,
  productName: string,
};

export type CustomContent = {
  __typename: "CustomContent",
  customContentId?: string | null,
  fileName?: string | null,
  fileSize?: number | null,
  product?: string | null,
  status?: string | null,
  type?: string | null,
  uri?: string | null,
};

export type GlobalCustomContent = {
  __typename: "GlobalCustomContent",
  fileName?: string | null,
  fileSize?: number | null,
  globalCustomContentId?: string | null,
  path?: string | null,
  product?: string | null,
  status?: string | null,
  type?: string | null,
};

export type IntroAssetInput = {
  category?: string | null,
  fileName?: string | null,
  name?: string | null,
  type?: string | null,
};

export type AddIntroToPresentationResponse = {
  __typename: "AddIntroToPresentationResponse",
  introAsset?: IntroAsset | null,
  presentation?: Presentation | null,
  uploadUrl?: string | null,
};

export type CoverImageInput = {
  fileName?: string | null,
  name?: string | null,
};

export type CreateCoverImageResponse = {
  __typename: "CreateCoverImageResponse",
  coverImage?: CoverImage | null,
  uploadUrl?: string | null,
};

export type GlobalCustomContentInput = {
  fileName: string,
  product: string,
  type: string,
};

export type CreateGlobalCustomContentResponse = {
  __typename: "CreateGlobalCustomContentResponse",
  globalCustomContent?: GlobalCustomContent | null,
  uploadUrl?: string | null,
};

export type CreateGlobalIntroAssetResponse = {
  __typename: "CreateGlobalIntroAssetResponse",
  introAsset?: IntroAsset | null,
  uploadUrl?: string | null,
};

export type PresentationInput = {
  clientName?: string | null,
  coverImage?: CoverImageInput | null,
  title?: string | null,
};

export type ProductBundle = {
  __typename: "ProductBundle",
  createdAt?: string | null,
  productName?: string | null,
  size?: number | null,
  zipKey?: string | null,
};

export type CreateUserInput = {
  businessDivision?: string | null,
  businessNeed?: string | null,
  department?: string | null,
  email: string,
  firstName?: string | null,
  keyAccount?: string | null,
  lastName?: string | null,
  phone?: string | null,
  productCenter?: string | null,
  region?: string | null,
  role: string,
};

export type User = {
  __typename: "User",
  businessDivision?: string | null,
  businessNeed?: string | null,
  creationDate?: string | null,
  department?: string | null,
  email?: string | null,
  enabled?: boolean | null,
  firstName?: string | null,
  id?: string | null,
  keyAccount?: string | null,
  lastName?: string | null,
  phone?: string | null,
  productCenter?: string | null,
  region?: string | null,
  role?: string | null,
  status?: string | null,
};

export type AccountRequest = {
  __typename: "AccountRequest",
  businessDivision?: string | null,
  businessNeed?: string | null,
  department?: string | null,
  email?: string | null,
  firstName?: string | null,
  id?: string | null,
  keyAccount?: string | null,
  lastName?: string | null,
  phone?: string | null,
  productCenter?: string | null,
  region?: string | null,
  requestType?: AccountRequestType | null,
};

export enum AccountRequestType {
  REACTIVATION = "REACTIVATION",
  REGISTRATION = "REGISTRATION",
}


export type RequestAccountInput = {
  businessDivision?: string | null,
  businessNeed?: string | null,
  department?: string | null,
  email?: string | null,
  firstName?: string | null,
  keyAccount?: string | null,
  lastName?: string | null,
  phone?: string | null,
  productCenter?: string | null,
  region?: string | null,
};

export type RequestReactivationInput = {
  email?: string | null,
  requestType?: AccountRequestType | null,
};

export type SetCustomCoverImageForPresentationResponse = {
  __typename: "SetCustomCoverImageForPresentationResponse",
  presentation?: Presentation | null,
  uploadUrl?: string | null,
};

export type UpdateUserInput = {
  businessDivision?: string | null,
  businessNeed?: string | null,
  department?: string | null,
  email?: string | null,
  firstName?: string | null,
  keyAccount?: string | null,
  lastName?: string | null,
  phone?: string | null,
  productCenter?: string | null,
  region?: string | null,
  role?: string | null,
};

export type AddCustomContentToProductMutationVariables = {
  customContent: CustomContentInput,
  presentationId: string,
  productName: string,
};

export type AddCustomContentToProductMutation = {
  addCustomContentToProduct?:  {
    __typename: "AddCustomContentToProductResponse",
    customContentId?: string | null,
    presentation?:  {
      __typename: "Presentation",
      clientName?: string | null,
      coverImage?:  {
        __typename: "CoverImage",
        coverImageId?: string | null,
        fileName?: string | null,
        name?: string | null,
        path?: string | null,
      } | null,
      createdAt?: string | null,
      introAssets?:  Array< {
        __typename: "IntroAsset",
        category?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        introAssetId?: string | null,
        isGlobal?: boolean | null,
        name?: string | null,
        path?: string | null,
        type?: string | null,
      } | null > | null,
      presentationId?: string | null,
      products?:  Array< {
        __typename: "Product",
        category?: string | null,
        customContents?:  Array< {
          __typename: "CustomContent",
          customContentId?: string | null,
          fileName?: string | null,
          fileSize?: number | null,
          product?: string | null,
          status?: string | null,
          type?: string | null,
          uri?: string | null,
        } | null > | null,
        globalCustomContents?:  Array< {
          __typename: "GlobalCustomContent",
          fileName?: string | null,
          fileSize?: number | null,
          globalCustomContentId?: string | null,
          path?: string | null,
          product?: string | null,
          status?: string | null,
          type?: string | null,
        } | null > | null,
        productName: string,
      } | null > | null,
      title?: string | null,
      userId?: string | null,
    } | null,
    uploadUrl?: string | null,
  } | null,
};

export type AddGlobalIntroAssetToPresentationMutationVariables = {
  introAssetId: string,
  presentationId: string,
};

export type AddGlobalIntroAssetToPresentationMutation = {
  addGlobalIntroAssetToPresentation?:  {
    __typename: "Presentation",
    clientName?: string | null,
    coverImage?:  {
      __typename: "CoverImage",
      coverImageId?: string | null,
      fileName?: string | null,
      name?: string | null,
      path?: string | null,
    } | null,
    createdAt?: string | null,
    introAssets?:  Array< {
      __typename: "IntroAsset",
      category?: string | null,
      fileName?: string | null,
      fileSize?: number | null,
      introAssetId?: string | null,
      isGlobal?: boolean | null,
      name?: string | null,
      path?: string | null,
      type?: string | null,
    } | null > | null,
    presentationId?: string | null,
    products?:  Array< {
      __typename: "Product",
      category?: string | null,
      customContents?:  Array< {
        __typename: "CustomContent",
        customContentId?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
        uri?: string | null,
      } | null > | null,
      globalCustomContents?:  Array< {
        __typename: "GlobalCustomContent",
        fileName?: string | null,
        fileSize?: number | null,
        globalCustomContentId?: string | null,
        path?: string | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
      } | null > | null,
      productName: string,
    } | null > | null,
    title?: string | null,
    userId?: string | null,
  } | null,
};

export type AddIntroAssetToPresentationMutationVariables = {
  introAsset: IntroAssetInput,
  presentationId: string,
};

export type AddIntroAssetToPresentationMutation = {
  addIntroAssetToPresentation?:  {
    __typename: "AddIntroToPresentationResponse",
    introAsset?:  {
      __typename: "IntroAsset",
      category?: string | null,
      fileName?: string | null,
      fileSize?: number | null,
      introAssetId?: string | null,
      isGlobal?: boolean | null,
      name?: string | null,
      path?: string | null,
      type?: string | null,
    } | null,
    presentation?:  {
      __typename: "Presentation",
      clientName?: string | null,
      coverImage?:  {
        __typename: "CoverImage",
        coverImageId?: string | null,
        fileName?: string | null,
        name?: string | null,
        path?: string | null,
      } | null,
      createdAt?: string | null,
      introAssets?:  Array< {
        __typename: "IntroAsset",
        category?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        introAssetId?: string | null,
        isGlobal?: boolean | null,
        name?: string | null,
        path?: string | null,
        type?: string | null,
      } | null > | null,
      presentationId?: string | null,
      products?:  Array< {
        __typename: "Product",
        category?: string | null,
        customContents?:  Array< {
          __typename: "CustomContent",
          customContentId?: string | null,
          fileName?: string | null,
          fileSize?: number | null,
          product?: string | null,
          status?: string | null,
          type?: string | null,
          uri?: string | null,
        } | null > | null,
        globalCustomContents?:  Array< {
          __typename: "GlobalCustomContent",
          fileName?: string | null,
          fileSize?: number | null,
          globalCustomContentId?: string | null,
          path?: string | null,
          product?: string | null,
          status?: string | null,
          type?: string | null,
        } | null > | null,
        productName: string,
      } | null > | null,
      title?: string | null,
      userId?: string | null,
    } | null,
    uploadUrl?: string | null,
  } | null,
};

export type AddProductToPresentationMutationVariables = {
  presentationId: string,
  productName: string,
};

export type AddProductToPresentationMutation = {
  addProductToPresentation?:  {
    __typename: "Presentation",
    clientName?: string | null,
    coverImage?:  {
      __typename: "CoverImage",
      coverImageId?: string | null,
      fileName?: string | null,
      name?: string | null,
      path?: string | null,
    } | null,
    createdAt?: string | null,
    introAssets?:  Array< {
      __typename: "IntroAsset",
      category?: string | null,
      fileName?: string | null,
      fileSize?: number | null,
      introAssetId?: string | null,
      isGlobal?: boolean | null,
      name?: string | null,
      path?: string | null,
      type?: string | null,
    } | null > | null,
    presentationId?: string | null,
    products?:  Array< {
      __typename: "Product",
      category?: string | null,
      customContents?:  Array< {
        __typename: "CustomContent",
        customContentId?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
        uri?: string | null,
      } | null > | null,
      globalCustomContents?:  Array< {
        __typename: "GlobalCustomContent",
        fileName?: string | null,
        fileSize?: number | null,
        globalCustomContentId?: string | null,
        path?: string | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
      } | null > | null,
      productName: string,
    } | null > | null,
    title?: string | null,
    userId?: string | null,
  } | null,
};

export type ConfirmCustomContentUploadToProductMutationVariables = {
  customContentId: string,
  presentationId: string,
  productName: string,
};

export type ConfirmCustomContentUploadToProductMutation = {
  confirmCustomContentUploadToProduct?: boolean | null,
};

export type ConfirmGlobalCustomContentUploadMutationVariables = {
  globalCustomContentId: string,
};

export type ConfirmGlobalCustomContentUploadMutation = {
  confirmGlobalCustomContentUpload?: boolean | null,
};

export type ConfirmGlobalIntroAssetUploadMutationVariables = {
  introAssetId: string,
};

export type ConfirmGlobalIntroAssetUploadMutation = {
  confirmGlobalIntroAssetUpload?: boolean | null,
};

export type ConfirmIntroAssetUploadToPresentationMutationVariables = {
  introAssetId: string,
  presentationId: string,
};

export type ConfirmIntroAssetUploadToPresentationMutation = {
  confirmIntroAssetUploadToPresentation?:  {
    __typename: "Presentation",
    clientName?: string | null,
    coverImage?:  {
      __typename: "CoverImage",
      coverImageId?: string | null,
      fileName?: string | null,
      name?: string | null,
      path?: string | null,
    } | null,
    createdAt?: string | null,
    introAssets?:  Array< {
      __typename: "IntroAsset",
      category?: string | null,
      fileName?: string | null,
      fileSize?: number | null,
      introAssetId?: string | null,
      isGlobal?: boolean | null,
      name?: string | null,
      path?: string | null,
      type?: string | null,
    } | null > | null,
    presentationId?: string | null,
    products?:  Array< {
      __typename: "Product",
      category?: string | null,
      customContents?:  Array< {
        __typename: "CustomContent",
        customContentId?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
        uri?: string | null,
      } | null > | null,
      globalCustomContents?:  Array< {
        __typename: "GlobalCustomContent",
        fileName?: string | null,
        fileSize?: number | null,
        globalCustomContentId?: string | null,
        path?: string | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
      } | null > | null,
      productName: string,
    } | null > | null,
    title?: string | null,
    userId?: string | null,
  } | null,
};

export type CreateCoverImageMutationVariables = {
  coverImage: CoverImageInput,
};

export type CreateCoverImageMutation = {
  createCoverImage?:  {
    __typename: "CreateCoverImageResponse",
    coverImage?:  {
      __typename: "CoverImage",
      coverImageId?: string | null,
      fileName?: string | null,
      name?: string | null,
      path?: string | null,
    } | null,
    uploadUrl?: string | null,
  } | null,
};

export type CreateGlobalCustomContentMutationVariables = {
  globalCustomContent: GlobalCustomContentInput,
};

export type CreateGlobalCustomContentMutation = {
  createGlobalCustomContent?:  {
    __typename: "CreateGlobalCustomContentResponse",
    globalCustomContent?:  {
      __typename: "GlobalCustomContent",
      fileName?: string | null,
      fileSize?: number | null,
      globalCustomContentId?: string | null,
      path?: string | null,
      product?: string | null,
      status?: string | null,
      type?: string | null,
    } | null,
    uploadUrl?: string | null,
  } | null,
};

export type CreateGlobalIntroAssetMutationVariables = {
  introAsset: IntroAssetInput,
};

export type CreateGlobalIntroAssetMutation = {
  createGlobalIntroAsset?:  {
    __typename: "CreateGlobalIntroAssetResponse",
    introAsset?:  {
      __typename: "IntroAsset",
      category?: string | null,
      fileName?: string | null,
      fileSize?: number | null,
      introAssetId?: string | null,
      isGlobal?: boolean | null,
      name?: string | null,
      path?: string | null,
      type?: string | null,
    } | null,
    uploadUrl?: string | null,
  } | null,
};

export type CreatePresentationMutationVariables = {
  presentation: PresentationInput,
};

export type CreatePresentationMutation = {
  createPresentation?:  {
    __typename: "Presentation",
    clientName?: string | null,
    coverImage?:  {
      __typename: "CoverImage",
      coverImageId?: string | null,
      fileName?: string | null,
      name?: string | null,
      path?: string | null,
    } | null,
    createdAt?: string | null,
    introAssets?:  Array< {
      __typename: "IntroAsset",
      category?: string | null,
      fileName?: string | null,
      fileSize?: number | null,
      introAssetId?: string | null,
      isGlobal?: boolean | null,
      name?: string | null,
      path?: string | null,
      type?: string | null,
    } | null > | null,
    presentationId?: string | null,
    products?:  Array< {
      __typename: "Product",
      category?: string | null,
      customContents?:  Array< {
        __typename: "CustomContent",
        customContentId?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
        uri?: string | null,
      } | null > | null,
      globalCustomContents?:  Array< {
        __typename: "GlobalCustomContent",
        fileName?: string | null,
        fileSize?: number | null,
        globalCustomContentId?: string | null,
        path?: string | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
      } | null > | null,
      productName: string,
    } | null > | null,
    title?: string | null,
    userId?: string | null,
  } | null,
};

export type CreateProductBundleMutationVariables = {
  productName: string,
};

export type CreateProductBundleMutation = {
  createProductBundle?:  {
    __typename: "ProductBundle",
    createdAt?: string | null,
    productName?: string | null,
    size?: number | null,
    zipKey?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  user: CreateUserInput,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    businessDivision?: string | null,
    businessNeed?: string | null,
    creationDate?: string | null,
    department?: string | null,
    email?: string | null,
    enabled?: boolean | null,
    firstName?: string | null,
    id?: string | null,
    keyAccount?: string | null,
    lastName?: string | null,
    phone?: string | null,
    productCenter?: string | null,
    region?: string | null,
    role?: string | null,
    status?: string | null,
  } | null,
};

export type DeleteAccountRequestMutationVariables = {
  accountRequestId: string,
};

export type DeleteAccountRequestMutation = {
  deleteAccountRequest?:  {
    __typename: "AccountRequest",
    businessDivision?: string | null,
    businessNeed?: string | null,
    department?: string | null,
    email?: string | null,
    firstName?: string | null,
    id?: string | null,
    keyAccount?: string | null,
    lastName?: string | null,
    phone?: string | null,
    productCenter?: string | null,
    region?: string | null,
    requestType?: AccountRequestType | null,
  } | null,
};

export type DeleteCoverImageMutationVariables = {
  coverImageId: string,
};

export type DeleteCoverImageMutation = {
  deleteCoverImage?: boolean | null,
};

export type DeleteCustomContentFromProductMutationVariables = {
  customContentId: string,
  presentationId: string,
  productName: string,
};

export type DeleteCustomContentFromProductMutation = {
  deleteCustomContentFromProduct?:  {
    __typename: "Presentation",
    clientName?: string | null,
    coverImage?:  {
      __typename: "CoverImage",
      coverImageId?: string | null,
      fileName?: string | null,
      name?: string | null,
      path?: string | null,
    } | null,
    createdAt?: string | null,
    introAssets?:  Array< {
      __typename: "IntroAsset",
      category?: string | null,
      fileName?: string | null,
      fileSize?: number | null,
      introAssetId?: string | null,
      isGlobal?: boolean | null,
      name?: string | null,
      path?: string | null,
      type?: string | null,
    } | null > | null,
    presentationId?: string | null,
    products?:  Array< {
      __typename: "Product",
      category?: string | null,
      customContents?:  Array< {
        __typename: "CustomContent",
        customContentId?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
        uri?: string | null,
      } | null > | null,
      globalCustomContents?:  Array< {
        __typename: "GlobalCustomContent",
        fileName?: string | null,
        fileSize?: number | null,
        globalCustomContentId?: string | null,
        path?: string | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
      } | null > | null,
      productName: string,
    } | null > | null,
    title?: string | null,
    userId?: string | null,
  } | null,
};

export type DeleteGlobalCustomContentMutationVariables = {
  globalCustomContentId: string,
};

export type DeleteGlobalCustomContentMutation = {
  deleteGlobalCustomContent?: boolean | null,
};

export type DeleteGlobalIntroAssetMutationVariables = {
  introAssetId: string,
};

export type DeleteGlobalIntroAssetMutation = {
  deleteGlobalIntroAsset?: boolean | null,
};

export type DeletePresentationMutationVariables = {
  presentationId: string,
};

export type DeletePresentationMutation = {
  deletePresentation?: boolean | null,
};

export type DeleteReactivationRequestMutationVariables = {
  reactivationRequestId: string,
};

export type DeleteReactivationRequestMutation = {
  deleteReactivationRequest?:  {
    __typename: "AccountRequest",
    businessDivision?: string | null,
    businessNeed?: string | null,
    department?: string | null,
    email?: string | null,
    firstName?: string | null,
    id?: string | null,
    keyAccount?: string | null,
    lastName?: string | null,
    phone?: string | null,
    productCenter?: string | null,
    region?: string | null,
    requestType?: AccountRequestType | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  userId: string,
};

export type DeleteUserMutation = {
  deleteUser?: boolean | null,
};

export type EnableUserMutationVariables = {
  userId: string,
};

export type EnableUserMutation = {
  enableUser?: boolean | null,
};

export type ReleaseOfflinePresenterVersionMutation = {
  releaseOfflinePresenterVersion?: boolean | null,
};

export type RemoveIntroAssetFromPresentationMutationVariables = {
  introAssetId: string,
  presentationId: string,
};

export type RemoveIntroAssetFromPresentationMutation = {
  removeIntroAssetFromPresentation?:  {
    __typename: "Presentation",
    clientName?: string | null,
    coverImage?:  {
      __typename: "CoverImage",
      coverImageId?: string | null,
      fileName?: string | null,
      name?: string | null,
      path?: string | null,
    } | null,
    createdAt?: string | null,
    introAssets?:  Array< {
      __typename: "IntroAsset",
      category?: string | null,
      fileName?: string | null,
      fileSize?: number | null,
      introAssetId?: string | null,
      isGlobal?: boolean | null,
      name?: string | null,
      path?: string | null,
      type?: string | null,
    } | null > | null,
    presentationId?: string | null,
    products?:  Array< {
      __typename: "Product",
      category?: string | null,
      customContents?:  Array< {
        __typename: "CustomContent",
        customContentId?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
        uri?: string | null,
      } | null > | null,
      globalCustomContents?:  Array< {
        __typename: "GlobalCustomContent",
        fileName?: string | null,
        fileSize?: number | null,
        globalCustomContentId?: string | null,
        path?: string | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
      } | null > | null,
      productName: string,
    } | null > | null,
    title?: string | null,
    userId?: string | null,
  } | null,
};

export type RemoveProductFromPresentationMutationVariables = {
  presentationId: string,
  productName: string,
};

export type RemoveProductFromPresentationMutation = {
  removeProductFromPresentation?:  {
    __typename: "Presentation",
    clientName?: string | null,
    coverImage?:  {
      __typename: "CoverImage",
      coverImageId?: string | null,
      fileName?: string | null,
      name?: string | null,
      path?: string | null,
    } | null,
    createdAt?: string | null,
    introAssets?:  Array< {
      __typename: "IntroAsset",
      category?: string | null,
      fileName?: string | null,
      fileSize?: number | null,
      introAssetId?: string | null,
      isGlobal?: boolean | null,
      name?: string | null,
      path?: string | null,
      type?: string | null,
    } | null > | null,
    presentationId?: string | null,
    products?:  Array< {
      __typename: "Product",
      category?: string | null,
      customContents?:  Array< {
        __typename: "CustomContent",
        customContentId?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
        uri?: string | null,
      } | null > | null,
      globalCustomContents?:  Array< {
        __typename: "GlobalCustomContent",
        fileName?: string | null,
        fileSize?: number | null,
        globalCustomContentId?: string | null,
        path?: string | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
      } | null > | null,
      productName: string,
    } | null > | null,
    title?: string | null,
    userId?: string | null,
  } | null,
};

export type RequestAccountMutationVariables = {
  account: RequestAccountInput,
};

export type RequestAccountMutation = {
  requestAccount?:  {
    __typename: "AccountRequest",
    businessDivision?: string | null,
    businessNeed?: string | null,
    department?: string | null,
    email?: string | null,
    firstName?: string | null,
    id?: string | null,
    keyAccount?: string | null,
    lastName?: string | null,
    phone?: string | null,
    productCenter?: string | null,
    region?: string | null,
    requestType?: AccountRequestType | null,
  } | null,
};

export type RequestReactivationMutationVariables = {
  account: RequestReactivationInput,
};

export type RequestReactivationMutation = {
  requestReactivation?:  {
    __typename: "AccountRequest",
    businessDivision?: string | null,
    businessNeed?: string | null,
    department?: string | null,
    email?: string | null,
    firstName?: string | null,
    id?: string | null,
    keyAccount?: string | null,
    lastName?: string | null,
    phone?: string | null,
    productCenter?: string | null,
    region?: string | null,
    requestType?: AccountRequestType | null,
  } | null,
};

export type ResetUserMfaMutationVariables = {
  userId: string,
};

export type ResetUserMfaMutation = {
  resetUserMfa?: boolean | null,
};

export type ResetUserPasswordMutationVariables = {
  userId: string,
};

export type ResetUserPasswordMutation = {
  resetUserPassword?: boolean | null,
};

export type SetCoverImageForPresentationMutationVariables = {
  coverImageId: string,
  presentationId: string,
};

export type SetCoverImageForPresentationMutation = {
  setCoverImageForPresentation?:  {
    __typename: "Presentation",
    clientName?: string | null,
    coverImage?:  {
      __typename: "CoverImage",
      coverImageId?: string | null,
      fileName?: string | null,
      name?: string | null,
      path?: string | null,
    } | null,
    createdAt?: string | null,
    introAssets?:  Array< {
      __typename: "IntroAsset",
      category?: string | null,
      fileName?: string | null,
      fileSize?: number | null,
      introAssetId?: string | null,
      isGlobal?: boolean | null,
      name?: string | null,
      path?: string | null,
      type?: string | null,
    } | null > | null,
    presentationId?: string | null,
    products?:  Array< {
      __typename: "Product",
      category?: string | null,
      customContents?:  Array< {
        __typename: "CustomContent",
        customContentId?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
        uri?: string | null,
      } | null > | null,
      globalCustomContents?:  Array< {
        __typename: "GlobalCustomContent",
        fileName?: string | null,
        fileSize?: number | null,
        globalCustomContentId?: string | null,
        path?: string | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
      } | null > | null,
      productName: string,
    } | null > | null,
    title?: string | null,
    userId?: string | null,
  } | null,
};

export type SetCustomCoverImageForPresentationMutationVariables = {
  coverImage: CoverImageInput,
  presentationId: string,
};

export type SetCustomCoverImageForPresentationMutation = {
  setCustomCoverImageForPresentation?:  {
    __typename: "SetCustomCoverImageForPresentationResponse",
    presentation?:  {
      __typename: "Presentation",
      clientName?: string | null,
      coverImage?:  {
        __typename: "CoverImage",
        coverImageId?: string | null,
        fileName?: string | null,
        name?: string | null,
        path?: string | null,
      } | null,
      createdAt?: string | null,
      introAssets?:  Array< {
        __typename: "IntroAsset",
        category?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        introAssetId?: string | null,
        isGlobal?: boolean | null,
        name?: string | null,
        path?: string | null,
        type?: string | null,
      } | null > | null,
      presentationId?: string | null,
      products?:  Array< {
        __typename: "Product",
        category?: string | null,
        customContents?:  Array< {
          __typename: "CustomContent",
          customContentId?: string | null,
          fileName?: string | null,
          fileSize?: number | null,
          product?: string | null,
          status?: string | null,
          type?: string | null,
          uri?: string | null,
        } | null > | null,
        globalCustomContents?:  Array< {
          __typename: "GlobalCustomContent",
          fileName?: string | null,
          fileSize?: number | null,
          globalCustomContentId?: string | null,
          path?: string | null,
          product?: string | null,
          status?: string | null,
          type?: string | null,
        } | null > | null,
        productName: string,
      } | null > | null,
      title?: string | null,
      userId?: string | null,
    } | null,
    uploadUrl?: string | null,
  } | null,
};

export type SynchronizeGlobalCustomContentForProductMutationVariables = {
  presentationId: string,
  productName: string,
};

export type SynchronizeGlobalCustomContentForProductMutation = {
  synchronizeGlobalCustomContentForProduct?:  {
    __typename: "Presentation",
    clientName?: string | null,
    coverImage?:  {
      __typename: "CoverImage",
      coverImageId?: string | null,
      fileName?: string | null,
      name?: string | null,
      path?: string | null,
    } | null,
    createdAt?: string | null,
    introAssets?:  Array< {
      __typename: "IntroAsset",
      category?: string | null,
      fileName?: string | null,
      fileSize?: number | null,
      introAssetId?: string | null,
      isGlobal?: boolean | null,
      name?: string | null,
      path?: string | null,
      type?: string | null,
    } | null > | null,
    presentationId?: string | null,
    products?:  Array< {
      __typename: "Product",
      category?: string | null,
      customContents?:  Array< {
        __typename: "CustomContent",
        customContentId?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
        uri?: string | null,
      } | null > | null,
      globalCustomContents?:  Array< {
        __typename: "GlobalCustomContent",
        fileName?: string | null,
        fileSize?: number | null,
        globalCustomContentId?: string | null,
        path?: string | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
      } | null > | null,
      productName: string,
    } | null > | null,
    title?: string | null,
    userId?: string | null,
  } | null,
};

export type UpdatePresentationMutationVariables = {
  presentation: PresentationInput,
  presentationId: string,
};

export type UpdatePresentationMutation = {
  updatePresentation?:  {
    __typename: "Presentation",
    clientName?: string | null,
    coverImage?:  {
      __typename: "CoverImage",
      coverImageId?: string | null,
      fileName?: string | null,
      name?: string | null,
      path?: string | null,
    } | null,
    createdAt?: string | null,
    introAssets?:  Array< {
      __typename: "IntroAsset",
      category?: string | null,
      fileName?: string | null,
      fileSize?: number | null,
      introAssetId?: string | null,
      isGlobal?: boolean | null,
      name?: string | null,
      path?: string | null,
      type?: string | null,
    } | null > | null,
    presentationId?: string | null,
    products?:  Array< {
      __typename: "Product",
      category?: string | null,
      customContents?:  Array< {
        __typename: "CustomContent",
        customContentId?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
        uri?: string | null,
      } | null > | null,
      globalCustomContents?:  Array< {
        __typename: "GlobalCustomContent",
        fileName?: string | null,
        fileSize?: number | null,
        globalCustomContentId?: string | null,
        path?: string | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
      } | null > | null,
      productName: string,
    } | null > | null,
    title?: string | null,
    userId?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  user?: UpdateUserInput | null,
  userId: string,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    businessDivision?: string | null,
    businessNeed?: string | null,
    creationDate?: string | null,
    department?: string | null,
    email?: string | null,
    enabled?: boolean | null,
    firstName?: string | null,
    id?: string | null,
    keyAccount?: string | null,
    lastName?: string | null,
    phone?: string | null,
    productCenter?: string | null,
    region?: string | null,
    role?: string | null,
    status?: string | null,
  } | null,
};

export type CreatePresentationPdfQueryVariables = {
  presentationId: string,
};

export type CreatePresentationPdfQuery = {
  createPresentationPdf?: string | null,
};

export type GetCurrentOfflinePresenterReleaseBuildQuery = {
  getCurrentOfflinePresenterReleaseBuild?: string | null,
};

export type GetPresentationQueryVariables = {
  presentationId: string,
};

export type GetPresentationQuery = {
  getPresentation?:  {
    __typename: "Presentation",
    clientName?: string | null,
    coverImage?:  {
      __typename: "CoverImage",
      coverImageId?: string | null,
      fileName?: string | null,
      name?: string | null,
      path?: string | null,
    } | null,
    createdAt?: string | null,
    introAssets?:  Array< {
      __typename: "IntroAsset",
      category?: string | null,
      fileName?: string | null,
      fileSize?: number | null,
      introAssetId?: string | null,
      isGlobal?: boolean | null,
      name?: string | null,
      path?: string | null,
      type?: string | null,
    } | null > | null,
    presentationId?: string | null,
    products?:  Array< {
      __typename: "Product",
      category?: string | null,
      customContents?:  Array< {
        __typename: "CustomContent",
        customContentId?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
        uri?: string | null,
      } | null > | null,
      globalCustomContents?:  Array< {
        __typename: "GlobalCustomContent",
        fileName?: string | null,
        fileSize?: number | null,
        globalCustomContentId?: string | null,
        path?: string | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
      } | null > | null,
      productName: string,
    } | null > | null,
    title?: string | null,
    userId?: string | null,
  } | null,
};

export type GetProductBundlesQuery = {
  getProductBundles?:  Array< {
    __typename: "ProductBundle",
    createdAt?: string | null,
    productName?: string | null,
    size?: number | null,
    zipKey?: string | null,
  } | null > | null,
};

export type GetUserQueryVariables = {
  userId: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    businessDivision?: string | null,
    businessNeed?: string | null,
    creationDate?: string | null,
    department?: string | null,
    email?: string | null,
    enabled?: boolean | null,
    firstName?: string | null,
    id?: string | null,
    keyAccount?: string | null,
    lastName?: string | null,
    phone?: string | null,
    productCenter?: string | null,
    region?: string | null,
    role?: string | null,
    status?: string | null,
  } | null,
};

export type ListAccountRequestsQuery = {
  listAccountRequests?:  Array< {
    __typename: "AccountRequest",
    businessDivision?: string | null,
    businessNeed?: string | null,
    department?: string | null,
    email?: string | null,
    firstName?: string | null,
    id?: string | null,
    keyAccount?: string | null,
    lastName?: string | null,
    phone?: string | null,
    productCenter?: string | null,
    region?: string | null,
    requestType?: AccountRequestType | null,
  } | null > | null,
};

export type ListActiveGlobalCustomContentQuery = {
  listActiveGlobalCustomContent?:  Array< {
    __typename: "GlobalCustomContent",
    fileName?: string | null,
    fileSize?: number | null,
    globalCustomContentId?: string | null,
    path?: string | null,
    product?: string | null,
    status?: string | null,
    type?: string | null,
  } | null > | null,
};

export type ListActiveGlobalCustomContentForProductQueryVariables = {
  productName: string,
};

export type ListActiveGlobalCustomContentForProductQuery = {
  listActiveGlobalCustomContentForProduct?:  Array< {
    __typename: "GlobalCustomContent",
    fileName?: string | null,
    fileSize?: number | null,
    globalCustomContentId?: string | null,
    path?: string | null,
    product?: string | null,
    status?: string | null,
    type?: string | null,
  } | null > | null,
};

export type ListCoverImagesQuery = {
  listCoverImages?:  Array< {
    __typename: "CoverImage",
    coverImageId?: string | null,
    fileName?: string | null,
    name?: string | null,
    path?: string | null,
  } | null > | null,
};

export type ListGlobalCustomContentQuery = {
  listGlobalCustomContent?:  Array< {
    __typename: "GlobalCustomContent",
    fileName?: string | null,
    fileSize?: number | null,
    globalCustomContentId?: string | null,
    path?: string | null,
    product?: string | null,
    status?: string | null,
    type?: string | null,
  } | null > | null,
};

export type ListGlobalCustomContentForProductQueryVariables = {
  productName: string,
};

export type ListGlobalCustomContentForProductQuery = {
  listGlobalCustomContentForProduct?:  Array< {
    __typename: "GlobalCustomContent",
    fileName?: string | null,
    fileSize?: number | null,
    globalCustomContentId?: string | null,
    path?: string | null,
    product?: string | null,
    status?: string | null,
    type?: string | null,
  } | null > | null,
};

export type ListGlobalIntroAssetsQuery = {
  listGlobalIntroAssets?:  Array< {
    __typename: "IntroAsset",
    category?: string | null,
    fileName?: string | null,
    fileSize?: number | null,
    introAssetId?: string | null,
    isGlobal?: boolean | null,
    name?: string | null,
    path?: string | null,
    type?: string | null,
  } | null > | null,
};

export type ListPresentationsQuery = {
  listPresentations?:  Array< {
    __typename: "Presentation",
    clientName?: string | null,
    coverImage?:  {
      __typename: "CoverImage",
      coverImageId?: string | null,
      fileName?: string | null,
      name?: string | null,
      path?: string | null,
    } | null,
    createdAt?: string | null,
    introAssets?:  Array< {
      __typename: "IntroAsset",
      category?: string | null,
      fileName?: string | null,
      fileSize?: number | null,
      introAssetId?: string | null,
      isGlobal?: boolean | null,
      name?: string | null,
      path?: string | null,
      type?: string | null,
    } | null > | null,
    presentationId?: string | null,
    products?:  Array< {
      __typename: "Product",
      category?: string | null,
      customContents?:  Array< {
        __typename: "CustomContent",
        customContentId?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
        uri?: string | null,
      } | null > | null,
      globalCustomContents?:  Array< {
        __typename: "GlobalCustomContent",
        fileName?: string | null,
        fileSize?: number | null,
        globalCustomContentId?: string | null,
        path?: string | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
      } | null > | null,
      productName: string,
    } | null > | null,
    title?: string | null,
    userId?: string | null,
  } | null > | null,
};

export type ListPresentationsForUserQuery = {
  listPresentationsForUser?:  Array< {
    __typename: "Presentation",
    clientName?: string | null,
    coverImage?:  {
      __typename: "CoverImage",
      coverImageId?: string | null,
      fileName?: string | null,
      name?: string | null,
      path?: string | null,
    } | null,
    createdAt?: string | null,
    introAssets?:  Array< {
      __typename: "IntroAsset",
      category?: string | null,
      fileName?: string | null,
      fileSize?: number | null,
      introAssetId?: string | null,
      isGlobal?: boolean | null,
      name?: string | null,
      path?: string | null,
      type?: string | null,
    } | null > | null,
    presentationId?: string | null,
    products?:  Array< {
      __typename: "Product",
      category?: string | null,
      customContents?:  Array< {
        __typename: "CustomContent",
        customContentId?: string | null,
        fileName?: string | null,
        fileSize?: number | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
        uri?: string | null,
      } | null > | null,
      globalCustomContents?:  Array< {
        __typename: "GlobalCustomContent",
        fileName?: string | null,
        fileSize?: number | null,
        globalCustomContentId?: string | null,
        path?: string | null,
        product?: string | null,
        status?: string | null,
        type?: string | null,
      } | null > | null,
      productName: string,
    } | null > | null,
    title?: string | null,
    userId?: string | null,
  } | null > | null,
};

export type ListUsersQuery = {
  listUsers?:  Array< {
    __typename: "User",
    businessDivision?: string | null,
    businessNeed?: string | null,
    creationDate?: string | null,
    department?: string | null,
    email?: string | null,
    enabled?: boolean | null,
    firstName?: string | null,
    id?: string | null,
    keyAccount?: string | null,
    lastName?: string | null,
    phone?: string | null,
    productCenter?: string | null,
    region?: string | null,
    role?: string | null,
    status?: string | null,
  } | null > | null,
};
