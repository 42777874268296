import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useEnvironment } from "../hooks/use-environment";
import { OfflinePresenterProductService } from "../services/offline-presenter-product.service";
import { OnlinePresenterProductService } from "../services/online-presenter-product.service";
import { ProductType } from "../types/product-types";
import { AuthContext } from "./auth-context";
import { getOfflineAssetRole } from "../utils/offline-asset-roles";

export type AllProductsType = { [productName: string]: ProductType };

type ProductUserType = {
  product: string;
  userId: string; 
}

export type ProductContextProps = {
  products?: AllProductsType;
  refreshProductData(): void;
  getProductsAsArray(): ProductType[];
  getAllowedUsersForProduct(productName: string): Promise<string[] | undefined>;
  allowProductForUser(productName: string, cognitoUserId: string): Promise<string[] | undefined>;
  disallowProductForUser(productName: string, cognitoUserId: string): Promise<string[] | undefined>;
  getUserListForProduct(productName: string): Promise<string[] | undefined>;
  updateUserListForProduct(productName: string, userList: string[]): Promise<string[] | undefined>;
  newProductToUserAdded: ProductUserType;
  setNewProductToUserAdded(param: ProductUserType): void;
  productToUserRemoved: ProductUserType;
  setProductToUserRemoved(param: ProductUserType): void;
  isProductListFetched: boolean;
  setIsProductListFetched(param: boolean): void; 
}

export const ProductContext = createContext<ProductContextProps>({
  refreshProductData: () => undefined,
  getProductsAsArray: () => [],
  getAllowedUsersForProduct: async () => undefined,
  allowProductForUser: async () => undefined,
  disallowProductForUser: async () => undefined,
  getUserListForProduct: async () => undefined,
  updateUserListForProduct: async () => undefined,
  newProductToUserAdded: {
    product: '',
    userId: '' 
  },
  setNewProductToUserAdded: () => undefined,
 productToUserRemoved: {
    product: '',
    userId: '' 
  },
  setProductToUserRemoved: () => undefined,
  isProductListFetched: false, 
  setIsProductListFetched: async ()=> undefined
});

type ProductContextProviderProps = {
  children: React.ReactNode;
}

export const ProductContextProvider = ({ children }: ProductContextProviderProps) => {
  const { user } = useContext(AuthContext);
  const isLoggedIn = useRef(false);
  const { isOfflinePresenter, localApiUrl } = useEnvironment();
  const productService = useMemo(() => {
    return isOfflinePresenter
      ? (user?.attributes?.['custom:role']
        ? new OfflinePresenterProductService(localApiUrl, user.getUsername?.() || (user as any).username, getOfflineAssetRole(user.attributes['custom:role']))
        : undefined
      )
      : new OnlinePresenterProductService()
  }, [isOfflinePresenter, localApiUrl, user]);
  const [products, setProducts] = useState<AllProductsType>();

  const getProductsAsArray = () => products ? Object.values(products) : [];

  const refreshProductData = () => {
    productService?.fetchProducts().then(data => {
      if (data) {
        // const newData = [heatedRandome, ...data.filter(p => p.name !== 'HeatedRadome')]
        setProducts(data.reduce<AllProductsType>((obj, p) => {
          obj[p.name] = p;
          setIsProductListFetched(true);
          return obj;
        }, {}));
      }
    });
  };

  const getAllowedUsersForProduct = async (productName: string) => {
    return await productService?.getAllowedUsersForProduct(productName);
  };

  const allowProductForUser = async (productName: string, cognitoUserId: string) => {
    return await productService?.allowProductForUser(productName, cognitoUserId);
  }

  const disallowProductForUser = async (productName: string, cognitoUserId: string) => {
    return await productService?.disallowProductForUser(productName, cognitoUserId);
  }


  const getUserListForProduct = async (productName: string) => {
    return await productService?.getUserListForProduct(productName);
  };

  const updateUserListForProduct = async (productName: string, userList: string[]) => {
    return await productService?.updateUserListForProduct(productName, userList);
  }



  useEffect(() => {
    if (user && !isLoggedIn.current) {
      refreshProductData();
      isLoggedIn.current = true;
    } else if (!user && isLoggedIn.current) {
      isLoggedIn.current = false;
    }
    /* eslint-disable */
  }, [user]);


  const [newProductToUserAdded, setNewProductToUserAdded] = useState<ProductUserType>({product: '', userId: ''}); 
  const [productToUserRemoved, setProductToUserRemoved] = useState<ProductUserType>({product: '', userId: ''}); 
  const [isProductListFetched, setIsProductListFetched] = useState(false);

  return (
    <ProductContext.Provider value={{
      products,
      refreshProductData,
      getProductsAsArray,
      getAllowedUsersForProduct,
      allowProductForUser,
      disallowProductForUser,
      getUserListForProduct,
      updateUserListForProduct,
      newProductToUserAdded,
      setNewProductToUserAdded,
      productToUserRemoved,
      setProductToUserRemoved,
      isProductListFetched,
      setIsProductListFetched
    }}>
      {children}
    </ProductContext.Provider>
  )
}