import { PresentationType } from "./presentation-types";
import { BaseUser } from "./user-api-types";


export type ProductRateList = {
  product: string;
  n: number | undefined;
}


export type CountriesList = {
  country: string;
  n: number | undefined;
}

export type productCenterList = {
  dpt: string;
  n: number | undefined;
}


export type UsersBusinessDivisionType = {
  label: string;
  sizeBusinessDivision: number | undefined;
  businessDivisionDpt?: (BaseUser[] | undefined)[];
  businessDivisionProductCenter?: (BaseUser[] | undefined)[];
  businessNeed: (BaseUser[] | undefined)[];
  mainBusinessNeedList?: productCenterList[];
  dptListLighting: productCenterList[],
  dptListElectronics: productCenterList[],
}

export type CountryList = {
  country: string;
  n: number | undefined
}
export type ListRegions = {
  region: string;
  list: CountryList[],
  total: number;
}

export type HandleTimeTrackingType = {
  type: 'users' | 'presentations',
  data: (PresentationType | BaseUser)[],
  period: string
}

export enum Time {
  DAY = 1,
  WEEK = 7,
  MONTH = 1,
  QUATER = 3,
  YEAR = 12
}

export type BusinessGroupType = 'HELLA OE: Lighting' | 'HELLA OE: Electronics'
export enum BusinessGroupEnum {
   Lighting = 'HELLA OE: Lighting',
   Electronics = 'HELLA OE: Electronics'
}