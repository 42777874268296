import { Box, makeStyles, Typography } from '@material-ui/core';
import { HELLA_COLORS } from '../../theme';
import HomeButton from '../general/home-button';
import { AdminPageLayoutProps } from '../Layouts/admin-page-layout';
import ProductDetailsOverviewButton from '../presentation/product-usp/product-usp-overview-button';

type AdminNavbarProps = {
  openNavigation: () => void;
} & AdminPageLayoutProps;

const useStyles = makeStyles((theme) => ({
  navBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '3%',
  },
  headlineContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.common.white,
    '& > h2': {
      marginBottom: theme.spacing(10),
    },
    '& > p': {
      color: HELLA_COLORS.grey4,
      marginBottom: theme.spacing(4),
    },
  },
  homeContainer: {
    filter: 'invert(1)',
  },
  detailOverviewContainer: {
    cursor: 'pointer',
    '& > div > svg': {
      margin: '0 !important',
    },
  },
}));

const AdminNavbar = ({
  headline,
  description,
  openNavigation,
}: AdminNavbarProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.navBar}>
      <Box className={classes.homeContainer}>
        <HomeButton />
      </Box>
      <Box className={classes.headlineContainer}>
        <Typography variant="h2">{headline}</Typography>
        {typeof description === 'string' ? (
          <Typography>{description}</Typography>
        ) : (
          description.map((text) => <Typography key={text}>{text}</Typography>)
        )}
      </Box>
      <Box className={classes.detailOverviewContainer} onClick={openNavigation}>
        <ProductDetailsOverviewButton />
      </Box>
    </Box>
  );
};

export default AdminNavbar;
