import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateGlobalIntroAssetResponse, IntroAsset, IntroAssetInput } from "../API";
import { confirmGlobalIntroAssetUpload as apiConfirmGlobalIntroAssetUpload, createGlobalIntroAsset as apiCreateGlobalIntroAsset, deleteGlobalIntroAsset as apiDeleteGlobalIntroAsset,
  addGlobalIntroAssetToPresentation as apiAddGlobalIntroAssetToPresentation
} from '../graphql/mutations';
import { listGlobalIntroAssets as apiListGlobalIntroAssets } from '../graphql/queries';
import { ErrorDialogProps } from "../types/general-types";
import { CreateIntroType, IntroAssetType } from "../types/presentation-intro-types";
import { PresentationType } from "../types/presentation-types";
import { doGraphQlOperation } from "../utils/graphql-utils";
import { parseApiErrors } from "../utils/parse-api-errors";
import { usePresentationApi } from "./use-presentation-api";
import { useTracking } from "./use-tracking";

export function useGlobalIntroApi() {
  const [globalIntroAssetList, setGlobalIntroAssetList] = useState<IntroAssetType[]>();
  const [errorDialogState, setErrorDialogState] = useState<ErrorDialogProps>({
    open: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [introProgress, setProgress] = useState<number>(0);
  const lastProgressUpdate = useRef(-10);
  const { t } = useTranslation();
  const { trackSelectGlobalIntroAsset } = useTracking();
  const {setAlteredPresentation} = usePresentationApi();

  useEffect(() => {
    fetchGlobalIntroAssets();
    // eslint-disable-next-line
  }, []);

  const fetchGlobalIntroAssets = async () => {
    try {
      setIsLoading(true);
      const { data } = await doGraphQlOperation<{ listGlobalIntroAssets: IntroAsset[] }>(apiListGlobalIntroAssets);
      setGlobalIntroAssetList(data?.listGlobalIntroAssets);
      setIsLoading(false);
    } catch (err) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.e010'),
        additionalMessages: parseApiErrors(err),
      });
    }
  };

  const addGlobalIntroAssetToPresentation = async (introAssetId: string, presentationId: string) => {
    try {
        const { data } = await doGraphQlOperation<{ addGlobalIntroAssetToPresentation: PresentationType }>(
        apiAddGlobalIntroAssetToPresentation,
        { presentationId: presentationId , introAssetId: introAssetId},
        { errorOnEmpty: true }
      );
      if (data) {
        setAlteredPresentation(data as unknown as PresentationType);
      }
      await fetchGlobalIntroAssets();
    } catch (err) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.e015'),
        additionalMessages: parseApiErrors(err)
      });    
    }

  };

  const createGlobalIntroAsset = async (file: File, dto: CreateIntroType) => {
    try {
      const input: IntroAssetInput = {
        name: dto.name,
        fileName: file.name,
        category: dto.category,
        type: file.type,
      };
      setIsCreating(true);
      const { data } = await doGraphQlOperation<{ createGlobalIntroAsset: CreateGlobalIntroAssetResponse }>(
        apiCreateGlobalIntroAsset,
        { introAsset: input },
        { errorOnEmpty: true }
      );

      if (data?.createGlobalIntroAsset?.uploadUrl) {
        let request = new XMLHttpRequest();
        request.open("PUT", data?.createGlobalIntroAsset.uploadUrl, true);
        request.setRequestHeader("Content-Type", file.type);
        request.upload.onprogress = (event) => {
          const percent_completed = (event.loaded / event.total) * 100;
          if (percent_completed >= lastProgressUpdate.current + 10 || percent_completed === 100) {
            lastProgressUpdate.current = percent_completed;
            setProgress(percent_completed);
          }
        }
        request.onerror = (error) => {
          setIsCreating(false);
          setErrorDialogState({
            open: true,
            errorMessage: t('errors.e012'),
            additionalMessages: parseApiErrors(error),
          });
        };
        request.onloadend = async () => {
          setIsCreating(false);
          if (data?.createGlobalIntroAsset.introAsset && data?.createGlobalIntroAsset.introAsset.introAssetId) {
            confirmGlobalIntroAssetUpload(data?.createGlobalIntroAsset.introAsset.introAssetId);
            trackSelectGlobalIntroAsset()
          }
          lastProgressUpdate.current = -10;
        }
        request.send(file);
      } else {
        setIsCreating(false);
      }
    } catch (err) {
      setIsCreating(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.e011'),
        additionalMessages: parseApiErrors(err),
      });
    }
  };

  const deleteGlobalIntroAssets = async (introAssetId: string) => {
    try {
      setIsDeleting(true);
      await doGraphQlOperation<{ deleteGlobalIntroAsset: boolean }>(
        apiDeleteGlobalIntroAsset,
        { introAssetId: introAssetId },
        { errorOnEmpty: true }
      );
     await fetchGlobalIntroAssets();
     setIsDeleting(false);
    } catch (err) {
      setIsDeleting(false);
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.e014'),
        additionalMessages: parseApiErrors(err),
      });
    }
  };

  const confirmGlobalIntroAssetUpload = async (introAssetId: string) => {
    try {
      await doGraphQlOperation<{ confirmGlobalIntroAssetUpload: boolean }>(
        apiConfirmGlobalIntroAssetUpload,
        { introAssetId: introAssetId },
        { errorOnEmpty: true }
      );
      await fetchGlobalIntroAssets();
    } catch (err) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.e013'),
        additionalMessages: parseApiErrors(err),
      });
    }
  };

  return {
    globalIntroAssetList,
    addGlobalIntroAssetToPresentation,
    fetchGlobalIntroAssets,
    createGlobalIntroAsset,
    deleteGlobalIntroAssets,
    errorDialogState,
    isLoading,
    isCreating,
    isDeleting,
    introProgress,
  };
}