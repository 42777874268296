import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as LoadingIcon } from '../../assets/img/admin/Symbol_loading.svg';

const useStyles = makeStyles<Theme, Props>((theme) => ({
  loadingIcon: {
    width: (props) => props.width || 35,
    height: (props) => props.height || 35,
    animation: `$rotate ease-in-out 1000ms infinite`,
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

type Props = {
  color?: 'dark' | 'light';
  width?: number | string;
  height?: number | string;
}

export default function ProgressIndicator(props: Props) {
  const classes = useStyles(props);

  return (
    <LoadingIcon className={classes.loadingIcon} fill={props.color === 'light' ? 'white' : undefined} />
  );
}