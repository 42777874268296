import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, makeStyles, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from '../../../src/assets/img/close-icon.svg';
import HomeIcon from '../../../src/assets/img/presentation/home-icon.svg';
import PresentationBottomStepper from "../../components/presentation/presentation-bottom-stepper";
import { FadeInAnimation } from "../../theme";
import { CoverImagesTypes } from "../../types/global-cover-images-types";

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    background: theme.palette.common.white,
  },
  dialogCloseButton: {
    '& > span > svg': {
      width: 24,
    },
  },
  dialogTitle: {
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    '& > h2': {
      fontFamily: 'Hella-Regular',
    },
  },
  dialogContent: {
    background: theme.palette.common.white,
    padding: '2% 10%',
    paddingBottom: '5%',
  },
  dialogRoot: {
    '& > div > div': {
      height: '90%',
      borderRadius: 0,
    },
  },
  dialogPaper: {
    height: '100%',
    display: 'flex',
    background: theme.palette.common.white,
  },
  dialogDescr: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    padding: theme.spacing(16),
    paddingRight: 150,
    '& > img': {
      width: 24,
    },
  },
  dialogCoverImage: {
    width: '50%',
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  dialogStepper: {
    pointerEvents: 'none',
    width: '100%',
    position: 'relative',
    height: 25,
    '& > div': {
      position: 'absolute',
      transform: 'scale(0.75)',
      left: theme.spacing(-22),
    },
  },
  dialogDescrInfo: {
    '& > p:first-of-type': {
      marginBottom: theme.spacing(12),
    },
  },
  fadeIn: {
    animation: '$fadeIn 0.5s ease-in-out forwards',
  },
  '@keyframes fadeIn': FadeInAnimation,
}));


interface Props extends DialogProps {
  coverImage?: CoverImagesTypes;
}

export default function AdminCoverImagePreviewDialog(props: Props) {
  const { coverImage, ...dialogProps } = props;
  const classes = useStyles();
  const { t } = useTranslation(); 
  return (
    <Dialog
      {...dialogProps}
      fullWidth
      maxWidth="lg"
      className={clsx(classes.dialogRoot, classes.fadeIn)}
    >
      <DialogActions className={classes.dialogActions}>
        <IconButton
          className={classes.dialogCloseButton}
          onClick={() => dialogProps.onClose && dialogProps.onClose({}, 'backdropClick')}
        >
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogTitle className={classes.dialogTitle}>
        {t('adminCoverImages.preview.headline')}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {coverImage &&
          <Paper className={classes.dialogPaper} elevation={2}>
            <Box className={classes.dialogDescr}>
              <img src={HomeIcon} alt={HomeIcon} />
              <Typography variant="h2">
                {t('adminCoverImages.preview.title')}
              </Typography>
              <Box className={classes.dialogDescrInfo}>
                <Typography>
                  {t('adminCoverImages.preview.client')}
                </Typography>
                <Typography>YYYY - MM - DD</Typography>
              </Box>
              <Box className={classes.dialogStepper}>
                <PresentationBottomStepper currentStep={0} disableIntro={false} />
              </Box>
            </Box>
            <Box className={classes.dialogCoverImage}>
              <img
                src={coverImage.url !== null ? coverImage.url : undefined}
                alt={coverImage.name !== null ? coverImage.name : undefined}
              />
            </Box>
          </Paper>
        }
      </DialogContent>
    </Dialog>
  );
}