export const MATOMO_CATEGORIES = {
  authentication: 'Authentication',
  createEditPresentation: 'Create/Edit Presentation',
  presentation: 'Presentation',
  product: 'Product',
  deepDive: 'Deep Dive',
  offlinePresenter: 'Offline Presenter',
};

export const MATOMO_ACTIONS = {
  // authentication
  authenticationLogin: 'User logged in',
  // createEditPresentation
  createEditPresentationCreate: 'User created new presentation',
  createEditPresentationSelectCoverImage: 'User selected cover image for presentation',
  createEditPresentationUploadCoverImage: 'User uploaded new cover image to presentation',
  createEditPresentationUploadIntroAsset: 'User uploaded new intro asset to presentation',
  createEditPresentationSelectGlobalIntroAsset: 'User selected global intro asset for presentation',
  createEditPresentationAddProduct: 'User selected product to be added to presentation',
  createEditPresentationUploadCustomContent: 'User uploaded new custom content to presentation',
  // presentation
  presentationStartPresentation: 'User started presentation',
  // product
  productViewProduct: 'User clicked/viewed product in presentation',
  productViewUsp1: 'User viewed USP 1 of product',
  productViewUsp2: 'User viewed USP 2 of product',
  productViewUsp3: 'User viewed USP 3 of product',
  // deepDive
  deepDiveViewDeepDive: 'User clicked/viewed Deep Dive of product',
  deepDiveViewExplosionView: 'User viewed Explosion View of product',
  deepDiveViewCoreFeature: 'User viewed Core Feature of product',
  deepDiveViewConfigurator: 'User viewed Configurator of product',
  deepDiveViewTechnicalData: 'User viewed Technical Data of product',
  deepDiveViewCustomContents: 'User viewed Custom Contents of product',
  // offlinePresenter
  offlinePresenterDownload: 'User downloaded Offline Presenter',
}
