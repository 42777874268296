import { useTranslation } from 'react-i18next';
import {
  createCoverImage as apiCreateCoverImage,
  deleteCoverImage as apiDeleteCoverImage,
} from '../graphql/mutations';
import { listCoverImages as apiListCoverImages } from '../graphql/queries';
import {
  CoverImageType,
  CreateCoverImageResponse,
} from '../types/global-cover-images-types';

import { useEffect, useState } from 'react';
import { CoverImageInput } from '../API';
import { ErrorDialogProps } from '../types/general-types';
import { doFetch } from '../utils/fetch-utils';
import { doGraphQlOperation } from '../utils/graphql-utils';
import { parseApiErrors } from '../utils/parse-api-errors';

export type useCoverImagesApiResponse = {
  listCoverImages: CoverImageType[];
  fetchListCoverImages: () => Promise<void>;
  addCoverImage(coverImage: CoverImageInput): Promise<void>;
  deleteCoverImage: (customContentId: string) => Promise<any>;
  errorDialogState: ErrorDialogProps;
  setErrorDialogState(val: ErrorDialogProps): void;
  isLoading: boolean;
};

export function useCoverImagesApi(): useCoverImagesApiResponse {
  const { t } = useTranslation();
  const [listCoverImages, setListCoverImages] = useState<CoverImageType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDialogState, setErrorDialogState] = useState<ErrorDialogProps>({
    open: false,
  });

  useEffect(() => {
    fetchListCoverImages();
    // eslint-disable-next-line
  }, []);

  const fetchListCoverImages = async () => {
    try {
      setIsLoading(true);
      const { data } = await doGraphQlOperation<{listCoverImages: CoverImageType[]}>(apiListCoverImages, undefined, { errorOnEmpty: true });
      data && setListCoverImages([...data.listCoverImages]);
      setIsLoading(false);
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.d000'),
        additionalMessages: parseApiErrors(err),
      });
    }
  };

  const addCoverImage = async (file: File) => {
    try {
      setIsLoading(true);
      const CoverImageInput = {
        fileName: file.name,
        name: file.name,
      };

      const { data } = await doGraphQlOperation<{createCoverImage: CreateCoverImageResponse}>(
        apiCreateCoverImage,
        { coverImage: CoverImageInput },
        { errorOnEmpty: true }
      );

      const coverImageId = data?.createCoverImage?.coverImage?.coverImageId!;
      if (data && coverImageId) {
        try {
          await doFetch(data?.createCoverImage.uploadUrl,
            {
              method: 'PUT',
              body: file,
            },
            { parseResponse: 'text' }
          );
        } catch (err: any) {
          setErrorDialogState({
            open: true,
            errorMessage: t('errors.d002'),
            additionalMessages: parseApiErrors(err),
          });
        }
        await fetchListCoverImages();
      }
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.d001'),
        additionalMessages: parseApiErrors(err),
      });
    }
  };

  const deleteCoverImage = async (coverImageId: string) => {
    try {
      await doGraphQlOperation<{ deleteGlobalCustomContent: boolean }>(
        apiDeleteCoverImage,
        { coverImageId: coverImageId },
        { errorOnEmpty: true }
      );
      await fetchListCoverImages();
    } catch (err: any) {
      setErrorDialogState({
        open: true,
        errorMessage: t('errors.d003'),
        additionalMessages: parseApiErrors(err),
      });
    }
  };

  return {
    listCoverImages,
    fetchListCoverImages,
    addCoverImage,
    deleteCoverImage,
    isLoading,
    setErrorDialogState,
    errorDialogState,
  };
}
