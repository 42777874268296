import {
  CustomContentInput,
  PresentationInput,
  ProductBundle
} from '../API';
import { getProductBundles } from '../graphql/queries';
import { XMLHttpRequestProps } from '../types/general-types';
import { PresentationType } from '../types/presentation-types';
import { PresentationServiceType } from '../types/presentation.service';
import { doGraphQlOperation } from '../utils/graphql-utils';
import { parseApiErrors } from '../utils/parse-api-errors';

export const PRESENTATION_SERVICE_ERRORS = {
  NoUploadUrl: 'No upload url',
  NoCustomContentId: 'No custom content ID',
};

export class OfflinePresenterPresentationService implements PresentationServiceType {
  constructor (private localApiUrl: string, private userId: string, private userRole: string, private currentAppVersion?: string) {}

  public async listPresentations() {
    try {
      const res = await fetch(this.localApiUrl + `data-manager/${this.userId}/presentations/${this.userRole}`, {
        method: 'GET',
      });
      return await res.json() as PresentationType[] | undefined;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async getPresentation(presentationId: string) {
    try {
      return undefined;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async synchronizeLocalPresentationList(presentations: PresentationType[]) {
    if (presentations) {
      try {
        const productBundles = await doGraphQlOperation<{ getProductBundles: ProductBundle[] }>(getProductBundles);
        const res = await fetch(this.localApiUrl + 'data-manager/presentations', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: this.userId,
            userRole: this.userRole,
            presentations,
            productBundles: productBundles.data?.getProductBundles,
            currentAppVersion: this.currentAppVersion
          }),
        });
        return await res.json() as boolean;
      } catch (err) {
        console.log(err)
        return false;
      }
    }
    return false;
  }

  public async createPresentation(presentation: PresentationInput) {
    return undefined;
  }

  public async updatePresentation(
    presentationId: string,
    presentation: PresentationInput
  ) {
    return undefined;
  }

  public async deletePresentation(presentationId: string) {
    return undefined;
  }

  public async listCoverImages() {
    return undefined;
  }

  public async setCoverImage(presentationId: string, coverImageId: string) {
    return undefined;
  }

  public async uploadCoverImage(
    presentationId: string,
    file: File,
    xmlHttpRequestProps: XMLHttpRequestProps
  ) {
    return undefined;
  }

  public async addProduct(presentationId: string, productName: string) {
    return undefined;
  }

  public async addProducts(presentationId: string, productNames: string[]) {
    return undefined;
  }

  public async deleteProduct(presentationId: string, productName: string) {
    return undefined;
  }

  public async deleteProducts(presentationId: string, productNames: string[]) {
    return undefined;
  }

  public async listActiveGlobalCustomContentsForProduct(productName: string) {
    return undefined;
  }

  public async uploadCustomContent(
    presentationId: string,
    productName: string,
    file: File,
    customContentInput: CustomContentInput,
    xmlHttpRequestProps: XMLHttpRequestProps
  ) {
    return undefined;
  }

  public async deleteCustomContent(
    presentationId: string,
    productName: string,
    customContentId: string
  ) {
    return undefined;
  }

  public async synchronizeGlobalCustomContentsForProduct(
    presentationId: string,
    productName: string
  ) {
    return undefined;
  }

  public async getLatestSyncVersion() {
    const res = await fetch(this.localApiUrl + 'data-manager/app-version');
    return await res.text() as string | undefined;
  }

  public async updateLatestSyncVersion(version: string) {
    await fetch(this.localApiUrl + 'data-manager/app-version', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ version }),
    });
  }

  public async createPresentationPdf(presentationId: string) {
    return undefined;
  }
}
