import S3 from "aws-sdk/clients/s3";
import { AssetServiceType } from "../types/asset.service";

type Cache = {
  [fileName: string]: string;
};

export class OnlinePresenterAssetService implements AssetServiceType {
  private cache: Cache;

  constructor () {
    this.cache = {};
  }

  public getAssetPath(fileName?: string | null, userCredentials?: any) {
    if (!fileName || !userCredentials) {
      return '';
    }
    userCredentials.signatureVersion = 'v4';
    userCredentials.region = 'eu-central-1';

    const s3 = new S3(userCredentials);
    const s3bucket = process.env.REACT_APP_ASSET_BUCKET_ID;
    const signedUrlExpireSeconds = 60 * 5;

    if (!this.cache[fileName]) {
      const url = s3.getSignedUrl('getObject', {
        Bucket: s3bucket,
        Key: fileName,
        Expires: signedUrlExpireSeconds,
      });
      this.cache[fileName] = url;
      return url;
    } else {
      return this.cache[fileName];
    }
  }
}
