import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ReactComponent as CloseIcon } from '../../../assets/img/close-icon.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/img/presentation/intro-arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/img/presentation/intro-arrow-right.svg';
import { ReactComponent as PlayIcon } from '../../../assets/img/presentation/intro-play-icon.svg';
import PresentationIntroOverlayPdfActions, { PdfActionsProps } from "./presentation-intro-overlay-pdf-actions";

const useStyles = makeStyles((theme) => ({
  container: {

  },
  introSelectorContainer: {
    pointerEvents: 'none',
  },
  introSelectorButton: {
    pointerEvents: 'all',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  hidden: {
    opacity: 0,
  },
  arrow: {
    fill: theme.palette.common.white,
    '& > *': {
      fill: theme.palette.common.white,
    }
  },
  arrowDisabled: {
    fill: theme.palette.grey[800],
    '& > *': {
      fill: theme.palette.grey[800],
    }
  },
  label: {
    fontFamily: 'Hella-Medium',
    lineHeight: '46px',
    color: theme.palette.secondary.main,
  },
  labelSpacing: {
    marginLeft: theme.spacing(10),
  },
  videoPlayIcon: {
    pointerEvents: 'all',
    cursor: 'pointer',
  },
  closeButton: {
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    pointerEvents: 'all',
    cursor: 'pointer',
  }
}));

type Props = {
  label?: string | null;
  currentIntro?: number;
  totalIntros?: number;
  onNext(): void;
  onPrevious(): void;
  pdf?: PdfActionsProps;
  video?: {
    onPlay(): void;
    isPlaying: boolean;
  },
  onClose?: () => void;
}

function PresentationIntroOverlay({ label, currentIntro = 1, totalIntros = 1, onNext, onPrevious, onClose, pdf, video }: Props) {
  const classes = useStyles();

  return (
    <Box
      width="100%" height="100%"
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      justifyContent="center"
      position="relative"
      className={classes.container}
    >
      <Box
        position="absolute"
        top={0} left={0} right={0}
        pt={25} px={25}
        zIndex={1000}
      >
        <Box display="flex" justifyContent="flex-end">
          {onClose &&
            <Box
              width={40} height={40}
              display="flex" justifyContent="center" alignItems="center"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon width={14} height={14} />
            </Box>
          }
        </Box>
      </Box>
      <Box
        mx={25}
        className={classes.introSelectorContainer}
        zIndex={1000}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          width={48} height={48}
          display="flex" justifyContent="center" alignItems="center"
          className={clsx(
            classes.introSelectorButton,
            currentIntro > 1 ? classes.cursorPointer : classes.hidden,
          )}
          onClick={() => currentIntro > 1 && onPrevious()}
        >
          <ArrowLeft
            className={
              currentIntro > 1
                ? classes.arrow
                : classes.arrowDisabled
            }
          />
        </Box>
        {video && !video.isPlaying &&
          <Box
            className={classes.videoPlayIcon}
            onClick={video.onPlay}
          >
            <PlayIcon style={{
              filter: 'drop-shadow(0px 0px 0px rgb(0 0 0))',
            }} />
          </Box>
        }
        <Box
          width={48} height={48}
          display="flex" justifyContent="center" alignItems="center"
          className={clsx(
            classes.introSelectorButton,
            currentIntro < totalIntros ? classes.cursorPointer : classes.hidden,
          )}
          onClick={() => currentIntro < totalIntros && onNext()}
        >
          <ArrowRight
            className={
              currentIntro < totalIntros
                ? classes.arrow
                : classes.arrowDisabled
            }
          />
        </Box>
      </Box>
      {pdf &&
        <PresentationIntroOverlayPdfActions {...pdf} />
      }
    </Box>
  )
}

export default PresentationIntroOverlay;
