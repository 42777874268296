import { useEffect, useMemo, useState } from "react";
import { EnvironmentService } from "../services/environment.service";

export function useEnvironment() {
  const environmentService = useMemo(() => { return new EnvironmentService() }, []);
  const [hasInternetConnection, setHasInternetConnection] = useState(environmentService.hasInternetConnection());
  const assetBucketId = environmentService.assetBucketId;
  const offlinePresenterDownloadPath = environmentService.offlinePresenterDownloadPath;
  const offlinePresenterInstructionsDownloadPath = environmentService.offlinePresenterInstructionsDownloadPath;
  const getCurrentLocalVersion = environmentService.getCurrentLocalVersion;
  const getCurrentRemoteVersion = environmentService.getCurrentRemoteVersion;

  const isNewVersionAvailable = async () => {
    const remoteVersion = await getCurrentRemoteVersion();
    if (remoteVersion) {
      const localVersion = getCurrentLocalVersion();
      return !!localVersion && remoteVersion !== localVersion;
    }
    return false;
  };

  const deleteLocalData = async () => {
    return await environmentService.deleteLocalData();
  }

  useEffect(() => {
    const trueFunc = () => setHasInternetConnection(true);
    const falseFunc = () => setHasInternetConnection(false);
    window.addEventListener('online', trueFunc);
    window.addEventListener('offline', falseFunc);
    return () => {
      window.removeEventListener('online', trueFunc);
      window.removeEventListener('offline', falseFunc);
    };
  }, []);

  return {
    isOfflinePresenter: environmentService.isOfflinePresenter,
    localApiUrl: environmentService.localApiUrl,
    hasInternetConnection,
    assetBucketId,
    offlinePresenterDownloadPath,
    offlinePresenterInstructionsDownloadPath,
    getCurrentLocalVersion,
    getCurrentRemoteVersion,
    isNewVersionAvailable,
    deleteLocalData,
  };
}