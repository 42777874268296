import {
  Box,
  Checkbox, CheckboxProps,
  makeStyles, Theme
} from '@material-ui/core';
import clsx from 'clsx';
import LoadingIcon from '../../assets/img/admin/Symbol_loading.svg';
import { ReactComponent as TickIcon } from '../../assets/img/tick.svg';

const useStyles = makeStyles<Theme, CheckboxProps>((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: ({ color }) =>
        (color === 'secondary'
          ? theme.palette.grey[900]
          : theme.palette.grey[200]) + ' !important',
    },
  },
  border: {
    border: ({ disabled, color }) =>
      `1px solid ${color === 'secondary'
        ? disabled
          ? theme.palette.grey[900]
          : theme.palette.secondary.main
        : disabled
          ? theme.palette.grey[200]
          : theme.palette.primary.main
      }`,
  },
  borderRadius: {
    borderRadius: '50%',
  },
  backgroundColor: {
    backgroundColor: ({ disabled, color }) =>
      color === 'secondary'
        ? disabled
          ? theme.palette.grey[900]
          : theme.palette.secondary.main
        : disabled
          ? theme.palette.grey[200]
          : theme.palette.primary.main,
  },
  loadingIcon: {
    width: 40,
    height: 40,
    padding: 8,
    animation: `$rotate ease-in-out 1000ms infinite`,
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

type Props = {
  state?: 'loading' | 'disabled';
} & CheckboxProps;

function CustomCheckbox(props: Props) {
  const classes = useStyles(props);

  const LoadingIndicator = () => (
    <img src={LoadingIcon} alt="LoadingIcon" className={classes.loadingIcon} />
  );

  const CheckboxIcon = ({ checked }: { checked?: boolean }) => (
    <Box
      width={40}
      height={40}
      p={4}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor={checked ? "common.black" : undefined}
        className={clsx(classes.border, classes.borderRadius)}
      >
        {checked && (
          <TickIcon />
        )}
      </Box>
    </Box>
  );

  return (
    props.state !== 'loading' ?
      <Checkbox
        {...props}
        icon={<CheckboxIcon />}
        checkedIcon={<CheckboxIcon checked />}
        classes={!props.disabled ? { root: classes.root } : {}}
        disabled={props.state === 'disabled'}
      /> :
      <LoadingIndicator />
  );
}

export default CustomCheckbox;
