import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdminCoverImageCard from '../../components/admin/cover-images/admin-cover-image-card';
import AdminPageLayout from '../../components/Layouts/admin-page-layout';
import { useAuth } from '../../hooks/use-auth';
import { useCoverImagesApi } from '../../hooks/use-cover-images-api';
import { FadeInAnimation, HELLA_COLORS } from '../../theme';
import AdminCoverImagePreviewDialog from './admin-cover-image-preview-dialog';
import AddCircleIcon from '../../../src/assets/img/add-circle-filled.svg';
import ProgressBar from '../../components/general/progress-bar';
import ErrorDialog from '../../components/general/error-dialog';

const useStyles = makeStyles((theme) => ({
  coverImagesContainer: {
    width: '100%',
    height: '75%',
    padding: '5% 15%',
    backgroundColor: theme.palette.common.white,
  },
  coverImageCard: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minWidth: 250,
    height: 290,
    maxHeight: 320,
  },
  coverImageCards: {
    display: 'flex',
    width: '100%',
    maxHeight: 375,
    marginBottom: theme.spacing(17),
    paddingBottom: theme.spacing(10),
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      height: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 5,
      backgroundColor: HELLA_COLORS.grey1,
      transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
    },
    '&::-webkit-scrollbar-track': {
      boxSizing: 'border-box',
      backgroundColor: theme.palette.grey[500],
      borderRadius: 5,
    },
  },
  coverImageRoot: {
    display: 'flex',
    height: '100%',
    flexFlow: 'column',
  },
  coverImageCheckBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(7),
  },
  previewButton: {
    width: 250,
  },
  previewButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  uploadCard: {
    border: `1px dashed ${HELLA_COLORS.grey2}`,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0 !important',
    '& > p': {
      fontSize: 14,
      fontFamily: 'Hella-Regular',
    },
  },
  dialogActions: {
    background: theme.palette.common.white,
  },
  dialogCloseButton: {
    '& > span > svg': {
      width: 24,
    },
  },
  dialogTitle: {
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    '& > h2': {
      fontFamily: 'Hella-Regular',
    },
  },
  dialogContent: {
    background: theme.palette.common.white,
    padding: '2% 10%',
    paddingBottom: '5%',
  },
  dialogRoot: {
    '& > div > div': {
      height: '90%',
      borderRadius: 0,
    },
  },
  dialogPaper: {
    height: '100%',
    display: 'flex',
    background: theme.palette.common.white,
  },
  dialogDescr: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    padding: theme.spacing(16),
    paddingRight: 150,
    '& > img': {
      width: 24,
    },
  },
  dialogCoverImage: {
    width: '50%',
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  dialogStepper: {
    pointerEvents: 'none',
    width: '100%',
    position: 'relative',
    height: 25,
    '& > div': {
      position: 'absolute',
      transform: 'scale(0.75)',
      left: theme.spacing(-22),
    },
  },
  dialogDescrInfo: {
    '& > p:first-of-type': {
      marginBottom: theme.spacing(12),
    },
  },
  fadeIn: {
    animation: '$fadeIn 0.5s ease-in-out forwards',
  },
  '@keyframes fadeIn': FadeInAnimation,
}));

type CoverImageUrlIdType = { url: string; coverImageId: string };

const AdminCoverImagesPage = () => {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    addCoverImage,
    deleteCoverImage,
    listCoverImages,
    isLoading,
    errorDialogState,
    setErrorDialogState,
  } = useCoverImagesApi();
  const { signS3RequestSync } = useAuth();
  const imageInputRef = useRef<HTMLInputElement>(null);

  const [coverImagesS3Url, setCoverImagesS3Url] = useState<
    CoverImageUrlIdType[]
  >([]);

  useEffect(() => {
    const imgArr = listCoverImages.map<CoverImageUrlIdType>((img) => ({
      url: img.path ? signS3RequestSync(img.path, {}) : '',
      coverImageId: img.coverImageId || '',
    }));
    setCoverImagesS3Url(imgArr);
    // eslint-disable-next-line
  }, [listCoverImages]);

  const handleDeleteCoverImage = (coverImageId: string) => {
    deleteCoverImage(coverImageId)
      .then(() => {})
      .catch((err: Error) =>
        setErrorDialogState({
          open: true,
          headline: t('generic.error'),
          errorMessage: t(
            'globalCoverImagesApi.error.generalDeleteGlobalCoverImages'
          ),
        })
      );
  };
  const handleAddCoverImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length) {
      const file = target.files[0];
      addCoverImage(file)
        .then(() => {})
        .catch((err: Error) =>
          setErrorDialogState({
            open: true,
            headline: t('generic.error'),
            errorMessage: t(
              'globalCoverImagesApi.error.generalCreateGlobalCoverImages'
            ),
          })
        );
    }
  };
  return (
    <>
      <ErrorDialog
        {...errorDialogState}
        onClose={() => setErrorDialogState({ open: false })}
      />
      <ProgressBar completed={!isLoading} />
      <AdminPageLayout
        headline={t('adminCoverImages.page.headline')}
        description={[
          t('adminCoverImages.page.description1'),
          t('adminCoverImages.page.description2'),
        ]}
      >
        <>
          <Box className={classes.coverImagesContainer}>
            <Box display="flex">
              <Box
                className={clsx(classes.coverImageCards, classes.fadeIn)}
                style={
                  coverImagesS3Url
                    ? {
                        overflowX:
                          coverImagesS3Url.length >= 3 ? 'scroll' : 'hidden',
                      }
                    : {}
                }
              >
                {coverImagesS3Url &&
                  coverImagesS3Url.map((coverImg, index) => (
                    <AdminCoverImageCard
                      key={coverImg.url + '_' + index}
                      imageUrl={coverImg.url}
                      selected={selectedImage === index}
                      onSelect={() => setSelectedImage(index)}
                      onDelete={() =>
                        handleDeleteCoverImage(coverImg.coverImageId)
                      }
                      disableMarginLeft={index === 0}
                    />
                  ))}
              </Box>
              <Box
                className={clsx(classes.coverImageCard, classes.uploadCard)}
                ml={8}
              >
                {!isLoading && (
                  <IconButton
                    aria-label="Add"
                    onClick={() => imageInputRef.current?.click()}
                  >
                    <img src={AddCircleIcon} alt="Add" />
                    <input
                      ref={imageInputRef}
                      type="file"
                      name="file input image"
                      style={{ display: 'none' }}
                      accept=".jpg"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleAddCoverImage(e)
                      }
                    />
                  </IconButton>
                )}
                <Typography>{t('adminCoverImages.imageButton.add')}</Typography>
              </Box>
            </Box>
            {coverImagesS3Url && (
              <Box className={classes.previewButtonContainer}>
                <Button
                  disabled={isLoading}
                  className={classes.previewButton}
                  variant={'contained'}
                  disableRipple
                  onClick={() => {
                    setOpenPreview(true);
                  }}
                >
                  {t('generic.button.preview')}
                </Button>
              </Box>
            )}
          </Box>
          <AdminCoverImagePreviewDialog
            coverImage={coverImagesS3Url?.[selectedImage]}
            open={openPreview}
            onClose={() => setOpenPreview(false)}
          />
        </>
      </AdminPageLayout>
    </>
  );
};

export default AdminCoverImagesPage;
