import {
  Box,
  Typography,
  IconButton,
  Theme,
  makeStyles,
} from '@material-ui/core';
import { HELLA_COLORS } from '../../../theme';
import SymbolToggle from '../../../../src/assets/img/admin/Symbol_toggle.svg';
import { useState } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  toggle: {
    width: 12,
    marginTop: 6
  },
  toggleUpSideDown: {
    marginTop: 6,
    width: 12,
    transform: 'rotate(180deg)',
  },
}));

type TableHeaderProps = {
  handleSortUser: (val: boolean) => void; 
}

const TableHeader = ({handleSortUser}: TableHeaderProps) => {
  
  const [toggle, setToggle] = useState(true);
  const classes = useStyles();

  return (
    <Box
      height={80}
      width="100%"
      borderBottom={`2px solid ${HELLA_COLORS.grey9}`}
      borderTop={`2px solid ${HELLA_COLORS.grey9}`}
      paddingLeft={16}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width={120}
      >
        <Typography
          variant="body1"
          style={{ color: HELLA_COLORS.grey5, fontWeight: 'bold' }}
        >
          Select
        </Typography>
        <span
          style={{
            fontWeight: 'bold',
            height: 24,
            borderLeft: `2px solid ${HELLA_COLORS.grey8}`,
            marginLeft: 18
          }}
        ></span>
        <Box display='flex' justifyContent='center' alignItems='center' marginLeft={16}>
          <Typography
            variant="body1"
            style={{ color: HELLA_COLORS.grey5, fontWeight: 'bold' }}
          >
            User
          </Typography>

          <IconButton disableRipple onClick={() => {
            setToggle(!toggle);
            handleSortUser(toggle);            
            }}>
            <img
              src={SymbolToggle}
              alt="SymbolToggle"
              className={clsx(
                toggle ? classes.toggle : classes.toggleUpSideDown
              )}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default TableHeader;
