export const OFFLINE_ASSET_ROLES = {
  'Technical Admin': 'Contributor',
  'Product Owner': 'Contributor',
  'Power User': 'Contributor',
  'Contributor': 'Contributor',
  'Employee': 'Employee',
};

export function getOfflineAssetRole(userRole: string) {
  return OFFLINE_ASSET_ROLES[userRole] || userRole;
}