import { UserRole } from "../hooks/use-user-api";
import { ProductType } from "../types/product-types";
import { ProductServiceType } from "../types/product.service";
import { parseApiErrors } from "../utils/parse-api-errors";

export class OfflinePresenterProductService implements ProductServiceType {
  constructor (private localApiUrl: string, private userId: string, private userRole: UserRole) {}

  public async fetchProducts(): Promise<ProductType[]> {
    try {
      const res = await fetch(this.localApiUrl + `data-manager/${this.userId}/products`, {
        method: 'GET',
      });
      return await res.json() as ProductType[];
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async fetchProduct(productName: string): Promise<ProductType | undefined> {
    try {
      const res = await fetch(this.localApiUrl + `data-manager/${this.userId}/product/${productName}`, {
        method: 'GET',
      });
      return await res.json() as ProductType | undefined;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }

  public async getAllowedUsersForProduct(productName: string): Promise<undefined> {
    return undefined;
  }

  public async allowProductForUser(productName: string, cognitoUserId: string): Promise<undefined> {
    return undefined;
  }

  public async disallowProductForUser(productName: string, cognitoUserId: string): Promise<undefined> {
    return undefined;
  }

  public async updateUserListForProduct(productName: string, userList: string[]): Promise<undefined> {
    return undefined;
  }

  public async getUserListForProduct(productName: string): Promise<undefined> {
    return undefined;
  }

}
