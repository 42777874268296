import { Auth } from '@aws-amplify/auth';
import { ProductType } from "../types/product-types";
import { ProductServiceType } from "../types/product.service";

// eslint-disable-next-line
const ENV_NAMES = {
  PRODUCTS_URL: 'REACT_APP_PRODUCTS_ENDPOINT'
};

export class OnlinePresenterProductService implements ProductServiceType {
  public productsUrl = process.env.REACT_APP_ENV === 'dev'
    ? 'https://pl6f03c33c.execute-api.eu-central-1.amazonaws.com/prod/products'
    : process.env.REACT_APP_ENV === 'stage'
      ? 'https://oacskc6pri.execute-api.eu-central-1.amazonaws.com/prod/products'
      : 'https://h1wvfiew2f.execute-api.eu-central-1.amazonaws.com/prod/products';

  public async fetchProducts(): Promise<ProductType[] | undefined> {
    if (this.productsUrl) {
      try {
        const idJwt = (await Auth.currentSession()).getIdToken().getJwtToken();
        const res = await fetch(this.productsUrl, {
          method: 'GET',
          headers: new Headers({
            Authorization: `Bearer ${idJwt}`,
          }),
        });
        const data = await res.json();

        // const filteredData = data.filter(p=>p.name!== 'SustainableHeadlamp');
        // console.log("filteredData: ", filteredData);
        // return [...filteredData, SustainableHeadlamp];

        return data;
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      console.error('No products endpoint.');
    }
  }

  public async fetchProduct(productName: string): Promise<ProductType | undefined> {
    if (this.productsUrl) {
      try {
        const idJwt = (await Auth.currentSession()).getIdToken().getJwtToken();
        const res = await fetch(`${this.productsUrl}/${productName}`, {
          method: 'GET',
          headers: new Headers({
            Authorization: `Bearer ${idJwt}`,
          }),
        });
        const data = await res.json();
        return data;
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      console.error('No products endpoint.');
    }
  }

  public async getAllowedUsersForProduct(productName: string): Promise<string[] | undefined> {
    if (this.productsUrl) {
      try {
        const idJwt = (await Auth.currentSession()).getIdToken().getJwtToken();
        const res = await fetch(`${this.productsUrl}/${productName}/users`, {
          method: 'GET',
          headers: new Headers({
            Authorization: `Bearer ${idJwt}`,
          }),
        });
        const data = await res.json();
        return data;
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      console.error('No products endpoint.');
    }
  }

  public async allowProductForUser(productName: string, cognitoUserId: string): Promise<string[] | undefined> {
    if (this.productsUrl) {
      try {
        const idJwt = (await Auth.currentSession()).getIdToken().getJwtToken();
        const res = await fetch(`${this.productsUrl}/${productName}/users`, {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${idJwt}`,
          }),
          body: JSON.stringify({
            userId: cognitoUserId,
          }),
        });
        const data = await res.json();
        return data;
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      console.error('No products endpoint.');
    }
  }

  public async disallowProductForUser(productName: string, cognitoUserId: string): Promise<string[] | undefined> {
    if (this.productsUrl) {
      try {
        const idJwt = (await Auth.currentSession()).getIdToken().getJwtToken();
        const res = await fetch(`${this.productsUrl}/${productName}/users/${cognitoUserId}`, {
          method: 'DELETE',
          headers: new Headers({
            Authorization: `Bearer ${idJwt}`,
          }),
        });
        const data = await res.json();
        return data;
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      console.error('No products endpoint.');
    }
  }


  public async getUserListForProduct(productName: string): Promise<string[] | undefined> {
    if (this.productsUrl) {
      try {
        const idJwt = (await Auth.currentSession()).getIdToken().getJwtToken();
        const res = await fetch(`${this.productsUrl}/${productName}/userList`, {
          method: 'GET',
          headers: new Headers({
            Authorization: `Bearer ${idJwt}`,
          }),
        });
        const data = await res.json();
        return data;
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      console.error('No products endpoint.');
    }
  }

  public async updateUserListForProduct(productName: string, userList: string[]): Promise<string[] | undefined> {
    if (this.productsUrl) {
      try {
        const idJwt = (await Auth.currentSession()).getIdToken().getJwtToken();
        const res = await fetch(`${this.productsUrl}/${productName}/userList/`, {
          method: 'PUT',
          headers: new Headers({
            Authorization: `Bearer ${idJwt}`,
          }),
          body: JSON.stringify(userList),
        });
        const data = await res.json();
        return data;
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      console.error('No products endpoint.');
    }
  }
}
