import { Grid, GridSpacing, makeStyles, Theme } from "@material-ui/core";
import { ReactElement, ReactNode } from "react";

const useStyles = makeStyles<Theme, Props>((theme) => ({
  gridContainer: {
    overflowY: 'auto'
  },
  gridItem: {
    height: (props) => props.gridItemHeight || 330,
  }
}));

type Props = {
  items: (ReactElement | ReactNode)[] | (ReactElement | ReactNode)[][];
  gridItemHeight?: number;
  containerSpacing?: GridSpacing;
}

function AdminContentGrid(props: Props) {
  const { items, containerSpacing } = props;
  const classes = useStyles(props);

  return (
    <Grid container spacing={containerSpacing} className={classes.gridContainer}>
      {items.map(i => (
        <Grid
          item
          xl={3} lg={4} md={6}
          className={classes.gridItem}
        >
          {i}
        </Grid>
      ))}
    </Grid>
  )
}

export default AdminContentGrid;
