import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

const initI18n = (resources: Resource, lng: string) => {
  i18n.use(initReactI18next).init({
    resources,
    lng,
    interpolation: {
      escapeValue: false,
    },
  });
};

export default initI18n;
