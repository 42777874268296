import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { ReactComponent as CloseIcon } from '../../../src/assets/img/close-icon.svg';
import { FadeInAnimation, HELLA_COLORS } from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 900,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    '& > div:nth-of-type(3) > div > div': {
      padding: '64px 113px',
    }
  },
  rootContainer: {
    background: theme.palette.common.white,
    padding: theme.spacing(18),
  },
  dialogActions: {
    position: 'absolute',
    top: 18,
    right: 18,
    '& > button > span': {
      width: 13,
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(0),
    padding: '20px 24px',
    '& > h2 > span': {
      fontFamily: 'Hella-Regular',
      fontSize: 24,
    },
  },
  dialogContents: {
    //padding: '8px 20%',
  },
  dialogDescription: {
    display: 'flex',
    justifyContent: 'center',
    color: HELLA_COLORS.grey5,
    fontSize: '14px',
    fontWeight: 'normal',
    marginBottom: 80,
  },
  closeButton: {
    '& > span': {
      width: 24,
      '& > svg': {
        width: 24,
      },
    },
  },
  fadeIn: {
    animation: '$fadeIn 0.5s ease-in-out forwards',
  },
  '@keyframes fadeIn': FadeInAnimation,
}));

interface AdminDialogProps extends DialogProps {
  title: string;
  description?: string;
  children?: ReactNode;
  maxWidth?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl' | undefined;
  contentClassName?: string;
}

const AdminDialog = (props: AdminDialogProps) => {
  const {
    title,
    description,
    children,
    maxWidth,
    contentClassName,
    ...dialogProps
  } = props;
  const classes = useStyles();
  return (
    <Dialog
      {...dialogProps}
      maxWidth={maxWidth}
      className={clsx(classes.root, classes.fadeIn)}
      fullWidth
    >
      <Box className={classes.rootContainer}>
        <DialogActions className={classes.dialogActions}>
          <IconButton
            className={classes.closeButton}
            onClick={() =>
              dialogProps.onClose && dialogProps.onClose({}, 'escapeKeyDown')
            }
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogTitle className={classes.dialogTitle}>
          <span>{title}</span>
        </DialogTitle>
        {description && (
          <Typography className={classes.dialogDescription}>
            {description}
          </Typography>
        )}

        <DialogContent className={contentClassName || classes.dialogContents}>
          {children}
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default AdminDialog;
