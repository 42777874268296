import { Box } from '@material-ui/core';

type BarRateProps = {
  partial: number | undefined;
  total: number | undefined;
  color: string;
  height?: number;
}

const RateBar = ({partial, total, color, height}: BarRateProps) => {
  return (
    <Box
      style={{
        width: `${(partial! / total!) * 100}%`,
        height: height || 10,
        backgroundColor: color
      }}
    ></Box>
  );
};

export default RateBar;
