export enum Routes {
  Playground = '/playground',

  Privacy = '/privacy',

  // Auth
  Login = '/login',
  LoginStep1 = '/login/step-1',
  LoginStep2 = '/login/step-2',
  LoginStep3 = '/login/step-3',
  LoginStep4 = '/login/step-4',
  LoginChangePassword = '/login/change-password',
  Login2faError = '/login/2fa-error',
  LoginTotpSetup = '/login/totp-setup',
  LoginTotpConfirm = '/login/totp-confirm',
  LoginForgotPassword = '/login/password-reset',

  // Presentation list
  Presentations = '/presentations',

  // Presentation creator
  PresentationCreator = '/create-presentation',
  PresentationCreatorStep1 = '/create-presentation/personification',
  PresentationCreatorStep2 = '/create-presentation/introduction',
  PresentationCreatorStep3 = '/create-presentation/products',
  PresentationCreatorStep4 = '/create-presentation/custom-contents',
  PresentationCreatorStep5 = '/create-presentation/finish',

  // Presentation editor
  PresentationEditor = '/edit-presentation',
  PresentationEditorStep1 = '/edit-presentation/personification',
  PresentationEditorStep2 = '/edit-presentation/introduction',
  PresentationEditorStep3 = '/edit-presentation/products',
  PresentationEditorStep4 = '/edit-presentation/custom-contents',
  PresentationEditorStep5 = '/edit-presentation/finish',

  // Presentation
  Presentation = '/presentation',
  Products = '/presentation/products',
  PresentationStart = '/presentation/start',
  PresentationIntro = '/presentation/intro',
  PresentationIntroFactsFigures = '/presentation/intro/facts-figures',
  PresentationIntroMap = '/presentation/intro/map',
  PresentationIntroOrganization = '/presentation/intro/organization',
  PresentationIntroVideos = '/presentation/intro/videos',
  PresentationIntroOthers = '/presentation/intro/others',
  PresentationProducts = '/presentation/products',
  PresentationProductUsps = '/presentation/product-usps',
  PresentationDeepDive = '/presentation/deep-dive',

  // Deep Dive
  DeepDiveExplosionView = '/presentation/deep-dive/explosion-view',
  DeepDiveCoreFunctions = '/presentation/deep-dive/core-functions',
  DeepDiveConfigurator = '/presentation/deep-dive/configurator',
  DeepDiveTechnicalData = '/presentation/deep-dive/technical-data',
  DeepDiveCustomContents = '/presentation/deep-dive/custom-contents',
  DeepDiveXray = '/presentation/deep-dive/x-ray',
  DeepDiveFactsAndFigures = '/presentation/deep-dive/facts-and-figures',

  // Admin
  Admin = '/admin',
  AdminLogin = '/admin/login',
  AdminUserManagement = '/admin/user-management',
  AdminCoverImages = '/admin/cover-images',
  AdminGlobalIntroAssets = '/admin/global-intro-assets',
  AdminCustomContents = '/admin/custom-contents',
  AdminProductBundles = '/admin/product-bundles',
  AdminKpiDashboardView = '/admin/kpi-dashboard-view',
  AdminProductAccessibility = '/admin/product-accessibility',
  AdminReleaseOfflinePresenterVersion = '/admin/release-offline-presenter-version'
}
