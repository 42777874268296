import { Box, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdminContentCard from '../../components/admin/admin-content-card';
import CustomTab from '../../components/general/custom-tab';
import CustomTabs from '../../components/general/custom-tabs';
import ProgressBar from '../../components/general/progress-bar';
import AdminPageLayout from '../../components/Layouts/admin-page-layout';
import { ProductContext } from '../../contexts/product-context';
import { useAuth } from '../../hooks/use-auth';
import { useGlobalAdminCustomContent } from '../../hooks/use-global-custom-content-api';
import { FadeInAnimation } from '../../theme';
import { ListGlobalCustomContentProductType } from '../../types/global-custom-content-types';

const useStyles = makeStyles((theme: Theme) => ({
  tabsContainer: {
    width: '100%',
    marginBottom: theme.spacing(24),
  },
  contentRoot: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    margin: 'auto',
    paddingBottom: '50px'
  },
  loadingIcon: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    width: 60,
    height: 60,
    zIndex: 99999,
    marginRight: theme.spacing(10),
    animation: `$rotate ease-in-out 1000ms infinite`,
  },
  cardWrapper: {
    minWidth: '600px',
    padding: '20px',
    marginRight: '10px',
    paddingTop: '0',
    paddingLeft: '0',
    height: 'fit-content'
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  fadeIn: {
    animation: '$fadeIn 0.5s ease-in-out forwards',
  },
  '@keyframes fadeIn': FadeInAnimation,
  '@media (max-height: 900px)': {
    contentRoot: {
      height: 490,
      overflowY: 'hidden',
      overflowX: 'scroll',
    }
  },
}));

const businessDivisionMap = {
  'Electronics': 'GE: Electronics',
  'Lighting': 'GL: Lighting',
}

const AdminCustomContentsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { products, getProductsAsArray } = useContext(ProductContext);
  const [productsWithCustomContents, setProductsWithCustomContents] =
    useState<ListGlobalCustomContentProductType[]>();
  const filteredProducts = useMemo(() => {
    if (productsWithCustomContents) {
      return (
        selectedTab === 1
          ? productsWithCustomContents.filter(p => p.businessDivision === businessDivisionMap.Electronics)
          : selectedTab === 2
            ? productsWithCustomContents.filter(p => p.businessDivision === businessDivisionMap.Lighting)
            : productsWithCustomContents
      )
    }
  }, [selectedTab, productsWithCustomContents])

  const { signS3RequestSync } = useAuth();
  const {
    listGlobalCustomContents,
    addGlobalCustomContent,
    deleteGlobalCustomContent,
    isFileUploading,
  } = useGlobalAdminCustomContent();

  useEffect(() => {
    if (products && listGlobalCustomContents) {
      const productsWithContentsObj: Record<string, ListGlobalCustomContentProductType> = {};
      getProductsAsArray().forEach(p => {
        productsWithContentsObj[p.name] = {
          productName: p.name,
          topic: `${p.displayName || ''}${p.secondDisplayName || ''}`,
          files: null,
          businessDivision: businessDivisionMap[p.category] || businessDivisionMap.Electronics,
        }
      });
      listGlobalCustomContents.forEach(c => {
        if (c.product) {
          const foundProduct = products[c.product];
          productsWithContentsObj[c.product] = {
            ...productsWithContentsObj[c.product],
            productName: c.product,
            topic: `${foundProduct?.displayName || ''}${foundProduct?.secondDisplayName || ''}`,
            files: [
              ...(productsWithContentsObj[c.product]?.files || []),
              {
                globalCustomContentId: c?.globalCustomContentId,
                url: signS3RequestSync(c?.path, {}) || '',
                type: c.type,
                fileName: c.fileName,
                fileSize: c.fileSize || '',
              }
            ],
            businessDivision: foundProduct ? businessDivisionMap[foundProduct.category] : businessDivisionMap.Electronics,
          }
        }
      });
      setProductsWithCustomContents(Object.keys(productsWithContentsObj).map(k => productsWithContentsObj[k]));
    }
    // eslint-disable-next-line
  }, [products, listGlobalCustomContents]);

  const handleAddCustomContent = (file: File, product: string) => {
    addGlobalCustomContent(file, product);
  };

  const handleDeleteCustomContent = (globalCustomContentId: string, productName: string) => {
    deleteGlobalCustomContent(globalCustomContentId, productName);
  };

  return (
    <>
      <ProgressBar completed={!isFileUploading} time="slow" />
      {/* {isFileUploading && (
        <img
          className={classes.loadingIcon}
          src={LoadingIcon}
          alt="LoadingIcon"
        />
      )} */}
      <AdminPageLayout
        headline={t('adminCustomContents.page.headline')}
        description={[t('adminCustomContents.page.description')]}
      >
        <>
          <Box className={clsx(classes.tabsContainer, classes.fadeIn)}>
            <CustomTabs
              value={selectedTab}
              onChange={(event, value: number) => setSelectedTab(value)}
              fullWidth
              spacing={60}
              fontcolor={'white'}
            >
              <CustomTab label={t('adminCustomContents.tab.allProducts')} />
              <CustomTab label={t('adminCustomContents.tab.ge')} />
              <CustomTab label={t('adminCustomContents.tab.gl')} />
            </CustomTabs>
          </Box>

          <Box className={clsx(classes.contentRoot, classes.fadeIn)}>
            {filteredProducts?.map((product, productIdx) => (
              <Box
                key={'product_' + product.topic + productIdx}
                className={classes.cardWrapper}
              >
                <AdminContentCard
                  title={product?.topic!}
                  files={product?.files!}
                  isCustomContentUploading={isFileUploading}
                  selectPdf={(file: File) =>
                    handleAddCustomContent(file, product?.productName!)
                  }
                  selectVideo={(file: File) =>
                    handleAddCustomContent(file, product?.productName!)
                  }
                  onDelete={(id: string, productName: string) => handleDeleteCustomContent(id, productName)}
                  previewButton={false}
                  imageButton={false}
                  pdfButton={true}
                />
              </Box>
            ))}
          </Box>
        </>
      </AdminPageLayout>
    </>
  );
};

export default AdminCustomContentsPage;
