import { StepConnector, Step, StepLabel, Typography, makeStyles, Box } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { MouseEvent } from 'react';

const useStyles = makeStyles((theme) => ({
    Step: {
        padding: 0,
    },
    StepLabel: {
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'center',
        textAlign: 'center',
        cursor: 'pointer !important',
        height: 50,
    },
    circle: {
        width: 20,
        height: 20,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '50%',
        transition: theme.transitions.create('all', { duration: 300 }),
    },
    circleActive: {
        transition: theme.transitions.create('all', { duration: 300 }),
        backgroundColor: theme.palette.common.black,
        animation: '$backgroundColorFadeIn 0.3s ease-in-out forwards',
    },
    iconContainer: {
        width: '100%',
        padding: 0,
        display: 'unset',
    },
    StepConnector: {
        height: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    lineHorizontal: {
        width: '100%',
    },
    fixedWidthStepConnector: {
        flexGrow: 1,
        flex: 'none',
    },
    labelText: {
        marginTop: theme.spacing(7),
    },
    '@keyframes backgroundColorFadeIn': {
        '0%': {
            backgroundColor: theme.palette.common.white,
        },
        '100%': {
            backgroundColor: theme.palette.common.black,
        },
    },
}));

type CustomStepProps = {
    onClick: (event: MouseEvent) => void;
    activeStep: number;
    stepIndex: number;
};

type StepIconProps = {
    firstEl?: boolean;
    lastEl?: boolean;
    isActive?: boolean;
};

const ProductCustomStep = ({ onClick, activeStep, stepIndex }: CustomStepProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const StepIcon = ({ firstEl, lastEl, isActive, }: StepIconProps) => (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
        >
            {!firstEl ? (
                <StepConnector classes={{ lineHorizontal: classes.lineHorizontal }} />
            ) : (
                <div style={{ flex: '1 1 auto' }}></div>
            )}
            <div
                className={clsx(classes.circle, isActive && classes.circleActive)}
            ></div>
            {!lastEl ? (
                <StepConnector classes={{ lineHorizontal: classes.lineHorizontal }} />
            ) : (
                <div style={{ flex: '1 1 auto' }}></div>
            )}
        </Box>
    );

    return (
        <>
            {stepIndex !== 0 &&
                <StepConnector
                    className={clsx(
                        classes.StepConnector,
                        classes.fixedWidthStepConnector
                    )}
                    classes={{ lineHorizontal: classes.lineHorizontal }}
                />
            }
            <Step className={classes.Step} onClick={onClick}>
                <StepLabel
                    className={classes.StepLabel}
                    StepIconComponent={() => (
                        <StepIcon
                            firstEl={stepIndex === 0}
                            isActive={activeStep === stepIndex}
                            lastEl={stepIndex === 2}
                        />
                    )}
                    classes={{
                        iconContainer: classes.iconContainer,
                    }}
                >
                    <Typography
                        variant={'body1'}
                        color="textPrimary"
                        style={{ fontWeight: activeStep === stepIndex ? 800 : 400 }}
                        className={classes.labelText}
                    >
                        {stepIndex === 0
                            ? t('presentation.pages.start.label')
                            : stepIndex === 1
                                ? t('presentation.pages.intro.label')
                                : t('presentation.pages.products.label')
                        }
                    </Typography>
                </StepLabel>
            </Step>
        </>
    )
};

export default ProductCustomStep;