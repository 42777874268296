import { Button,  makeStyles, SvgIcon } from '@material-ui/core';
import { Box, Container, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import CompanyLogo from '../../src/assets/img/login-layout/login-logo.svg';
import { ReactComponent as ButtonArrowRightIcon } from '../../../hella-presenter-frontend/src/assets/img/button-arrow-right.svg';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#fff',
    height: '100%',
    paddingTop: 100,
    overflow: 'scroll',
  },
  content: {
    width: '75%',
    margin: 'auto',
    position: 'relative',
    '& > h2': {
      marginTop: 15,
      color: 'black',
      lineHeight: '32px',
      letterSpacing: '0em',
    },
    '& > p': {
      fontFamily: 'Hella-Regular',
      marginTop: 15,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
      color: 'black',
      fontSize: 16,
    },
    '& > h3': {
      marginTop: 15,
      fontWeight: 'bold',
      color: 'black',
      fontSize: 20,
    },
  },
  contentHeader: {
    filter: 'invert(1)',
    height: 120,
    '& > img': {
      width: 75,
      position: 'absolute',
      right: '-100px',
    },
  },
  link: {
    color: '#0000a0',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  descr: {
    margin: 0,
  },
  highlightLink: {
    textDecoration: 'underline',
    color: 'black',
  },
  closeButton: {
    '& > span': {
      width: 24,
      '& > svg': {
        width: 24,
      },
    },
  },
  backButton: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    '&:hover': {
      '& .MuiButton-startIcon': {
        animation: '$slide2 0.5s ease 1',
        animationFillMode: 'forwards',
      },
    },
  },
  backButtonArrow: {
    fill: theme.palette.common.black,
  },
  flipHorizontal: {
    transform: 'scaleX(-1)',
  },
  arrowSvg: {
    height: '15px',
    width: 'auto',
    marginBottom: '2px',
  },
  '@keyframes slide1': {
    '0%': {
      opacity: 1,
      transform: 'translate(0,0)',
    },
    '100%': {
      opacity: 1,
      transform: 'translate(6px,0)',
    },
  },
  '@keyframes slide2': {
    '0%': {
      opacity: 1,
      transform: 'translate(0,0)',
    },
    '100%': {
      opacity: 1,
      transform: 'translate(-6px,0)',
    },
  },
}));

const PrivacyPage = () => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <Container maxWidth="xl" disableGutters className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.contentHeader}>
          <img src={CompanyLogo} alt="CompanyLogo" />
        </Box>

        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 10,
          }}
        >
          <Button
            className={classes.backButton}
            startIcon={
              <SvgIcon
                className={clsx(
                  classes.flipHorizontal,
                  classes.backButtonArrow,
                  classes.arrowSvg
                )}
              >
                <ButtonArrowRightIcon />
              </SvgIcon>
            }
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </Box>

        <Typography variant="h2">
          Information concerning the provision and use of personal data in
          connection with your participation at the HELLA Product Presenter
        </Typography>
        <Typography variant="body1">
          HELLA GmbH & Co. KGaA, as the “Responsible Party” for hosting the
          HELLA Product Presenter (“the Presenter”), takes the protection of
          personal data very seriously. The protection of your privacy when
          processing personal data during your registration for and the usage of
          the Presenter is an important concern for us. We treat your personal
          data responsibly and process it only in accordance with legal
          requirements and for the purposes of running the Presenter and
          improving it, as described below.
        </Typography>

        <Typography variant="h3">
          Processing purposes and legal basis
        </Typography>
        <Typography variant="body1">
          Any personal data you enter in the registration form or non-personal
          data that is collected through your clicks (e.g. usage of different
          functionalities; most clicked content) will be stored and used by the
          Responsible Party in accordance with Art. 6 (1) a) GDPR for the
          purpose of providing secure access for the purpose of providing secure
          access to the Presenter and to improve the Presenter functionality. In
          order to fulfill the purpose, the following data will be provided by
          you and processed by the Responsible Party:
          <p>
            - Personal basic information, department and product center for the
            purpose of anonymized evaluations and improvements of the Presenter.
          </p>
          <p>
            – Personal information will only be displayed to product owners for
            handling account requests.
          </p>
        </Typography>
        <Typography variant="h3">Data access and recipients</Typography>
        <Typography variant="body1">
          To ensure proper operation, selected employees of the Responsible
          Party have access to your data. The data may not be used by these
          employees for their own purposes. The employees in charge of data
          processing are bound to secrecy and data confidentiality. In addition,
          an appropriate level of data protection is ensured. In particular,
          your data is protected against unauthorized access by encrypted
          transmission, encrypted storage, a role and authorization concept, a
          data backup concept and physical protection measures for the servers.
          Your data will be used exclusively within the Responsible Party. For
          hosting the Presenter, HELLA uses the services of the digital service
          provider madhat GmbH (“madhat”) based on a commissioned data
          processing agreement according to Art. 26 GDPR. madhat is the provider
          of the Presenter and will not use any personal data for own purposes.
          For further information regarding data privacy at the service provider
          follow the link https://madhat.de/privacy. There will be no transfer
          to other external companies or to third countries.
          <a
            href={'https://madhat.de/privacy'}
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            https://madhat.de/privacy
          </a>
          . There will be no transfer to other external companies or to third
          countries.
        </Typography>

        <Typography variant="h3">Data storage</Typography>
        <Typography variant="body1">
          The storage of your data is encrypted in a data center in Frankfurt,
          Germany. Your personal data will be stored for as long as your user
          account is available. Your account will be deleted automatically if
          you are leaving the company HELLA.
        </Typography>

        <Typography variant="h3">
          Information, correction, deletion, restriction of processing,
          objection
        </Typography>
        <Typography variant="body1">
          You have the right to obtain information about your data in written or
          electronic form at any time. With your Presenter registration, you
          agree to the regulations of this data protection declaration and you
          consent to the processing and use of your personal data as outlined
          above. In addition, you have the right to have any personal data
          deleted, to object to the processing of the data, to have incorrect
          data corrected at any time or to receive your personal data in a
          structured, commonly used and machine-readable format. (Note: If
          country-specific legal retention periods apply, the data will be
          blocked before deletion for the duration of the country-specific
          period and deleted after its expiry). Should you have any concerns or
          questions or if you would like to revoke your consent to data
          processing please contact us via e-mail to{''}
          <a
            href="mailto:dataprivacy@forvia.com."
            className={classes.link + ' ' + classes.highlightLink}
            style={{ marginLeft: 5 }}
          >
            dataprivacy@forvia.com
          </a>
          . Until this time, the processing remains lawful. If the processing of
          your data violates applicable law, you have the right to complain to a
          supervisory authority pursuant to Art. 77 GDPR. Your personal data
          will be stored for as long as your user account is available.
        </Typography>

        <Typography variant="h3">
          Obligation to provide personal data
        </Typography>
        <Typography variant="body1">
          The provision of your personal data is a pre-requisite to successfully
          register for and use the Presenter.Should you have any concerns or
          questions please contact us via e-mail to
          <a
            href="mailto:dataprivacy@forvia.com."
            style={{ marginLeft: 5 }}
            className={classes.link + ' ' + classes.highlightLink}
          >
            dataprivacy@forvia.com
          </a>
          .
        </Typography>

        <Typography variant="h3">Information about cookies</Typography>

        <Typography variant="body1">
          The website uses cookies. A cookie is a small text file that is placed
          on your device when you visit a website and helps identify your
          device. Cookies are used to store information when visiting different
          pages of a website or returning to a website. They do not contain any
          personal information and cannot run programs that could cause a virus.
          <p className={classes.descr}>Functionality:</p>
          <p className={classes.descr}>
            Cookies in this category are placed on the visitor's device to allow
            the website to function as intended.
          </p>
          <p className={classes.descr}>Statistics:</p>
          <p className={classes.descr}>
            Cookies of this category are used to collect statistics about
            visitors to the website in order to improve the visitor experience.
            Collected data is used internally and is not shared with third
            parties.
          </p>
          <p className={classes.descr}>Change settings:</p>
          <p className={classes.descr}>
            Internet browsers are usually set to accept cookies automatically.
            However, you can deactivate the storage of cookies or set your
            Internet browser to notify you as soon as cookies are sent.
          </p>
        </Typography>

        <Typography variant="h3">The website uses tracking</Typography>
        <Typography variant="body1">
          The website uses Matomo Analytics, a web analytics service provided by
          Matomo. Matomo Analytics uses "cookies" - text files that are stored
          on your computer or smartphone to help us analyze how visitors use the
          website. The information created by the cookies about visitors'
          website usage is stored and processed by Matomo Analytics on servers
          in Germany. IP addresses are fully anonymized before collected data is
          visible to us via Matomo Analytics. It is not possible to reverse the
          anonymization of IP addresses and associate IP addresses with
          collected data. The following data will be collected in form of
          anonymized information to improve system’s functionality:
          <br />
          <br />
          - Anonymized user information (e.g., active users, region, department
          etc.)
          <br />
          - Anonymized content usage information (e.g., custom content creation,
          product usage rate)
          <br />
          - Anonymized functionality usage (e.g., usage rate of deep dive
          functionalities)
          <br />
          <br />
          We will use this information to evaluate and report on the user
          behavior of our website visitors and ultimately to improve the website
          experience for our visitors. Matomo will not share this information
          with third parties or use it for marketing or promotional purposes of
          any kind.
        </Typography>

        <Typography variant="h3">
          Contact details of the data protection officer
        </Typography>
        <Typography variant="body1">
          For suggestions and complaints regarding the processing of your
          personal data, you can contact our data protection officer by e-mail
          or letter to:
          <br />
          HELLA GmbH & Co. KGaA
          <br />
          – Data Protection Officer
          <br />
          - Rixbecker Straße 75 59552 Lippstadt, Deutschland
          <br />
          E-Mail:
          <a
            href="mailto:dataprivacy@forvia.com."
            style={{ marginLeft: 5 }}
            className={classes.link}
          >
            dataprivacy@forvia.com
          </a>
          <br />
          Status: July 2022
        </Typography>

        <Typography variant="h3">
          Data protection declaration of consent
        </Typography>
        <Typography variant="h3">
          By starting and completing the registration process for the Presenter,
          you consent to the above points. Your consent will be stored and can
          be revoked in writing at any time before the start of the Presenter.
        </Typography>
        <Typography variant="h3">
          If you do not consent or wish to revoke your consent, please contact{' '}
          <a
            href="mailto:dataprivacy@forvia.com."
            className={classes.link + ' ' + classes.highlightLink}
          >
            dataprivacy@forvia.com
          </a>{' '}
          participation in the Presenter is not possible without your consent.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPage;
