import { lazy, ReactElement } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { CodeOtptContextProvider } from '../../contexts/code-otp-context';
import { useEnvironment } from '../../hooks/use-environment';
import AdminCoverImagesPage from '../../Pages/admin/admin-cover-images-page';
import AdminCustomContentsPage from '../../Pages/admin/admin-custom-contents-page';
import AdminGlobalIntroAssetsPage from '../../Pages/admin/admin-global-intro-assets-page';
import AdminKpiDashboardView from '../../Pages/admin/admin-kpi-dashboard-view';
import AdminProductBundles from '../../Pages/admin/admin-product-bundles';
import AdminReleaseOfflinePresenterVersionPage from '../../Pages/admin/admin-release-offline-presenter-version-page';
import PrivacyPage from '../../Pages/privacy';
import AdminAuthRoute from './admin-route';
import AuthRoute from './auth-route';
import { Routes } from './routes';
import AdminProductAccessibilityPage from '../../Pages/admin/admin-product-accessibility-page';
import AuthCheck from './auth-check';

const LoginPage = lazy(() => import('../../Pages/login/login-page'));
const PresentationListPage = lazy(() => import('../../Pages/presentation-list/presentation-list-page'));
const PresentationListOfflinePage = lazy(() => import('../../Pages/presentation-list-offline/presentation-list-offline-page'));
const PresentationCreatorPage = lazy(() => import('../../Pages/presentation-creator/presentation-creator-page'));
const PresentationPage = lazy(() => import('../../Pages/presentation/presentation-page'));

const AdminUserManagementPage = lazy(() => import('../../Pages/admin/admin-user-management-page'));

export default function Router(): ReactElement {
  const { isOfflinePresenter } = useEnvironment();

  return (
    <HashRouter>
      <AuthCheck />
      <Switch>
        <AuthRoute path={Routes.Presentations} exact>
          {!isOfflinePresenter ? (
            <PresentationListPage />
          ) : (
            <PresentationListOfflinePage />
          )}
        </AuthRoute>

        {!isOfflinePresenter && (
          <AuthRoute path={Routes.PresentationCreator}>
            <PresentationCreatorPage mode="create" />
          </AuthRoute>
        )}

        {!isOfflinePresenter && (
          <AuthRoute path={Routes.PresentationEditor}>
            <PresentationCreatorPage mode="edit" />
          </AuthRoute>
        )}

        <AuthRoute path={Routes.Presentation}>
          <PresentationPage />
        </AuthRoute>

        <Route path={Routes.Login}>
          <CodeOtptContextProvider>
            <LoginPage />
          </CodeOtptContextProvider>
        </Route>

        <Route path={Routes.Privacy}>
          <PrivacyPage />
        </Route>

        {!isOfflinePresenter && (
          <AdminAuthRoute path={Routes.AdminUserManagement} exact>
            <AdminUserManagementPage />
          </AdminAuthRoute>
        )}

        {!isOfflinePresenter && (
          <AdminAuthRoute path={Routes.AdminCoverImages} exact>
            <AdminCoverImagesPage />
          </AdminAuthRoute>
        )}

        <AdminAuthRoute path={Routes.AdminGlobalIntroAssets} exact>
          <AdminGlobalIntroAssetsPage />
        </AdminAuthRoute>

        {!isOfflinePresenter && (
          <AdminAuthRoute path={Routes.AdminCustomContents} exact>
            <AdminCustomContentsPage />
          </AdminAuthRoute>
        )}

        {!isOfflinePresenter && (
          <AdminAuthRoute path={Routes.AdminKpiDashboardView} exact>
            <AdminKpiDashboardView />
          </AdminAuthRoute>
        )}

        {!isOfflinePresenter && (
          <AdminAuthRoute path={Routes.AdminProductAccessibility} exact>
            <AdminProductAccessibilityPage />
          </AdminAuthRoute>
        )}

        {!isOfflinePresenter && (
          <Route path={Routes.Admin} exact>
            <Redirect to={Routes.AdminUserManagement} />
          </Route>
        )}

        <AdminAuthRoute path={Routes.AdminProductBundles} exact>
          <AdminProductBundles />
        </AdminAuthRoute>

        <AdminAuthRoute path={Routes.AdminReleaseOfflinePresenterVersion} exact>
          <AdminReleaseOfflinePresenterVersionPage />
        </AdminAuthRoute>

        <Redirect to={Routes.Presentations} />
      </Switch>
    </HashRouter>
  );
}
