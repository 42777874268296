import {
  Box,
  Button,
  DialogProps,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from '../../../assets/img/add-icon.svg';
import { ReactComponent as PdfIcon } from '../../../assets/img/admin/pdf-icon.svg';
import { ReactComponent as VideoIcon } from '../../../assets/img/admin/Union.svg';
import { ReactComponent as ImageIcon } from '../../../assets/img/admin/Symbol_Images.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/admin/delete-icon.svg';
import { HELLA_COLORS } from '../../../theme';
import AdminDialog from '../admin-dialog';
import DropDownIcon from '../../../../src/assets/img/admin/arrow-down.svg';

const useStyles = makeStyles((theme) => ({
  createDialog: {
    minWidth: 400,
    width: '100%',
  },
  dialogTextField: {
    width: '100%',
    '& > div': {
      borderBottom: `1px solid ${HELLA_COLORS.grey5}`,
      '&::before': {
        content: 'none',
      },
      '&::after': {
        content: 'none',
      },
    },
    '&:first-of-type': {
      marginBottom: 48,
    }
  },
  buttonContainer: {
    width: '100%',
    height: 72,
    border: `1px dashed ${HELLA_COLORS.grey4}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 60,
    marginBottom: 58,
    cursor: 'pointer',
    '&:first-of-type': {
      marginBottom: theme.spacing(12),
    },
    '& > p': {
      fontFamily: 'Hella-Regular',
    },
  },
  addButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'transparent !important',
    '& > span > img': {
      filter: 'invert(1)',
    },
    '&:hover': {
      background: theme.palette.common.black,
    },
  },
  addButtonDisabled: {
    background: HELLA_COLORS.grey8,
  },
  uploadButton: {
    width: 'fit-content'
  },
  fileWrapper: {
    width: '100%',
    display: 'flex',
    cursor: 'default',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    }
  },
  dialogFormSelect: {
    width: '100%',
    '& > label': {
      zIndex: 1,
    },
    '& > div': {
      borderBottom: `1px solid ${HELLA_COLORS.grey5}`,
      '&::before': {
        content: 'none',
      },
      '&::after': {
        content: 'none',
      },
      '& > input': {
        border: 0,
      },
    },
    '& > div > svg': {
      color: HELLA_COLORS.grey5,
    },
    '& > div:hover': {
      borderColor: 'none',
    },
  },
  popoverList: {
    '& > li': {
      borderBottom: `1px solid ${HELLA_COLORS.grey5}`,
    },
    '& > .MuiListSubheader-root': {
      fontFamily: 'Hella-Regular',
      paddingLeft: theme.spacing(7),
    },
    '& > .MuiListItem-root': {
      paddingLeft: theme.spacing(8),
    },
  },
  paperClass: {
    background: 'white',
  },
}));

export type CreateIntroAssetForm = {
  name: string;
  category?: string;
  file: File;
};

export interface AdminCreateGlobalIntroDialogProps extends Omit<DialogProps, 'onSubmit'> {
  onSubmit?: (data: CreateIntroFileData) => void;
  submitDisabled?: boolean;
  onHandleClose?: () => void;
  preSelectCategory?: string;
}

export type CreateIntroFileData = {
  name?: string;
  category?: string;
  file?: File;
}

function AdminCreateGlobalIntroDialog({
  onSubmit,
  submitDisabled,
  onHandleClose,
  preSelectCategory,
  ...dialogProps
}: AdminCreateGlobalIntroDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const fileRef = useRef<HTMLInputElement>(null);

  const [introFile, setIntroFile] = useState<any>(null);

  const [data, setData] = useState<CreateIntroFileData>({});
  useEffect(() => {
    setIntroFile(null);
  }, [onHandleClose])

  function bytesToMegabytes(bytes) {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2);
  }

  const nameInput = data.name;

  return (
    <AdminDialog
      {...dialogProps}
      title={t('adminGlobalIntroAssets.createIntro.label')}
      description={t('adminGlobalIntroAssets.createIntro.description')}
      contentClassName={classes.createDialog}
      onClose={onHandleClose}
    >
      <Box display="flex" flexDirection="column" alignItems={'center'}>
        <TextField
          className={classes.dialogTextField}
          label={t('adminGlobalIntroAssets.createIntro.name')}
          // {...register('name', { required: true })}
          onChange={(e) => setData({ ...data, name: e.target.value })}
        />
        <div className={classes.dialogFormSelect}>
          <InputLabel>
            {t('adminGlobalIntroAssets.createIntro.category')}
          </InputLabel>
          <AdminSelect
            IconComponent={() => (
              <img src={DropDownIcon} alt="DropDownIcon" />
            )}
            fullWidth
            MenuProps={{
              classes: {
                paper: classes.paperClass,
                list: classes.popoverList,
              },
            }}
            onChange={(e: any) => setData({ ...data, category: e.target.value })}
            value={preSelectCategory || data.category}
            disabled={!!preSelectCategory}
          >
            <MenuItem value={t('adminGlobalIntroAssets.categories.factsFigures')}>
              {t('adminGlobalIntroAssets.categories.factsFigures')}
            </MenuItem>
            <MenuItem value={t('adminGlobalIntroAssets.categories.map')}>
              {t('adminGlobalIntroAssets.categories.map')}
            </MenuItem>
            <MenuItem value={t('adminGlobalIntroAssets.categories.organization')}>
              {t('adminGlobalIntroAssets.categories.organization')}
            </MenuItem>
            <MenuItem value={t('adminGlobalIntroAssets.categories.videos')}>
              {t('adminGlobalIntroAssets.categories.videos')}
            </MenuItem>
            <MenuItem value={t('adminGlobalIntroAssets.categories.others')}>
              {t('adminGlobalIntroAssets.categories.others')}
            </MenuItem>
          </AdminSelect>
        </div>
        <Box
          className={classes.buttonContainer}
          my={8}
          onClick={() => { if (!introFile) fileRef.current?.click() }}
        >
          {introFile ? (
            <Box className={classes.fileWrapper}>
              <Box marginLeft={11.5} width={'100%'}>
                <Box marginRight={7.5} >
                  {
                    introFile.type === 'image/jpeg'
                      ? <ImageIcon style={{ width: 40, filter: 'opacity(0.5)' }} />
                      : introFile.type === 'video/mp4'
                        ? <VideoIcon />
                        : <PdfIcon />
                  }
                </Box>
                <Box>
                  <Typography>{introFile.name}</Typography>
                  <Typography style={{ color: HELLA_COLORS.grey4 }}>{bytesToMegabytes(introFile.size) + ' '} mb</Typography>
                </Box>
              </Box>
              <Box marginRight={6}>
                <IconButton onClick={() => {
                  setData({ ...data, file: undefined })
                  setIntroFile(undefined);
                }}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <>
              <IconButton
                className={clsx(classes.addButton)}
              >
                <AddIcon />
                <input
                  ref={fileRef}
                  type="file"
                  style={{ display: 'none' }}
                  accept=".pdf, .mp4, .jpg, .jpeg, .png"
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    setData({ ...data, category: preSelectCategory || data.category, file: e.target.files?.[0] });
                    setIntroFile(e.target.files?.[0]);
                  }}
                />
              </IconButton>
              <Typography>{t('adminGlobalIntroAssets.createIntro.file')}</Typography>
            </>
          )}
        </Box>

        <Button
          onClick={(e) => {
            if (onSubmit) {
              onSubmit(data);
              setIntroFile(null);
            }
          }}
          disabled={!(nameInput && introFile) || submitDisabled}
          className={classes.uploadButton}
          style={{
            background: (!(nameInput && introFile) || submitDisabled) ? HELLA_COLORS.grey9 : HELLA_COLORS.grey2,
            color: (!(nameInput && introFile) || submitDisabled) ? HELLA_COLORS.grey4 : HELLA_COLORS.white,
          }}
        >
          {t('adminGlobalIntroAssets.uploadButton.label')}
        </Button>
      </Box>
    </AdminDialog>
  );
}

export default AdminCreateGlobalIntroDialog;

const AdminSelect = withStyles({
  root: {
    '& .MuiMenu-paper': {
      background: 'white',
    },
    '.MuiPopover-paper': {
      background: 'red',
    },
  },
  selectMenu: {},
})(Select);
