import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import Circle from './circle-util';

const useStyles = makeStyles<Theme, TrackingCellProps>((theme) => ({
  cellContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellText: {
    marginTop: 10,
    minWidth: 130,
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 20
  },

}));

type TrackingCellProps = {
  value?: number;
  circleColor?: string;
  label?: string;
  circle?: boolean;
};

const TrackingCell = ({
  value,
  circleColor,
  label,
  circle = true,
}: TrackingCellProps) => {
  const classes = useStyles({ circleColor });

  return (
    <Box className={classes.cellContainer}>
      <Typography variant="h1">{value}</Typography>
      <Box className={classes.cellText}>
        {circle && <Circle circleColor={circleColor} />}
        <Typography variant="body1"> {label}</Typography>
      </Box>
    </Box>
  );
};

export default TrackingCell;
