import { createContext, ReactNode, useState } from 'react';

type CodeOtpContextProps = {
  codeOtp: string;
  setCodeOtp(arg?: any): void;
};

const CodeOtpContext = createContext<CodeOtpContextProps>({
  codeOtp: '',
  setCodeOtp: () => undefined,
});

type Props = {
  children: ReactNode;
};

export function CodeOtptContextProvider({ children }: Props) {
  const [codeOtp, setCodeOtp] = useState('');

  return (
    <CodeOtpContext.Provider
      value={{ codeOtp, setCodeOtp }}
    >
      {children}
    </CodeOtpContext.Provider>
  );
}

export default CodeOtpContext;
