import {
  Box,
  Card,
  CardContent,
  CardMedia, Grid,
  makeStyles,
  SvgIcon,
  Theme,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { ReactElement, useEffect, useState } from 'react';
import DefaultBg from '../../assets/img/presentation-image.png';
import { ReactComponent as PlayArrowIcon } from '../../assets/img/presentation-tile/play-arrow-icon.svg';
import { useAuth } from '../../hooks/use-auth';
import { useEnvironment } from '../../hooks/use-environment';
import { PresentationType } from '../../types/presentation-types';
import { encodeUrl } from '../../utils/encode-url';

export const HOVER_DURATION = 200;
export const TILE_HEIGHT = 440;

const useStyles = makeStyles<Theme, Props>((theme) => ({
  Card: {
    width: '100%',
    height: TILE_HEIGHT,
  },
  CardContent: {
    padding: theme.spacing(10),
    height: '30%',
  },
  CardMedia: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
  },
  gridContainer: {
    height: '100%',
  },
  title: {
    flexGrow: 1,
    padding: theme.spacing(4, 0),
  },
  iconBox: {
    top: 12.5,
    transition: theme.transitions.create('top', { duration: HOVER_DURATION }),
  },
  textBox: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      duration: HOVER_DURATION,
    }),
    '&:hover': {
      opacity: 1,
    },
  },
  playIcon: {
    width: '50px',
    height: '50px',
    cursor: (props) => (!props.isLoading ? 'pointer' : 'unset'),
    opacity: (props) => (!props.isLoading ? 1 : 0.5),
  },
  fadeIn: {
    animation: '$fadeIn 0.5s ease-in-out forwards',
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },

    '100%': {
      opacity: 1,
    },
  },
  loadingIcon: {
    width: 50,
    height: 50,
    animation: `$rotate ease-in-out 1000ms infinite`,
    fill: theme.palette.common.white,
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  downloadingText: {
    marginTop: theme.spacing(4),
  },
}));

type Props = {
  presentation: PresentationType;
  cardClassName?: string;
  onClickStart?(): void;
  hoverElement?: ReactElement | ReactElement[];
  isLoading?: boolean;
  showPlayIcon?: boolean;
  isOfflinePresenter?: boolean;
};

function PresentationBaseTile(props: Props) {
  const {
    presentation,
    cardClassName,
    onClickStart,
    hoverElement,
    isLoading,
    showPlayIcon,
    isOfflinePresenter,
  } = props;
  const { createdAt, title, clientName } = presentation;
  const classes = useStyles(props);
  const { signS3RequestSync } = useAuth();
  const [coverImageSrc, setCoverImageSrc] = useState<string | null>();
  const { hasInternetConnection } = useEnvironment();

  useEffect(() => {
    if (presentation.coverImage?.path) {
      setCoverImageSrc(presentation.coverImage?.path);
    }
  }, [presentation]);

  const getRemoteOrDefaultCoverImage = () => {
    if (hasInternetConnection && presentation.coverImage?.path) {
      return signS3RequestSync(presentation.coverImage.path, {}, true);
    }
    return DefaultBg;
  };

  return (
    <Card className={clsx(classes.Card, classes.fadeIn, cardClassName)}>
      <Box height="70%" width="100%" position="relative">
        <CardMedia
          component="img"
          image={
            (coverImageSrc === null || !presentation.coverImage?.path) ? getRemoteOrDefaultCoverImage() :
              coverImageSrc === undefined ? encodeUrl(signS3RequestSync(presentation.coverImage?.path, {}, true), isOfflinePresenter) :
              encodeUrl(signS3RequestSync(coverImageSrc, {}, true), isOfflinePresenter)
          }
          className={classes.CardMedia}
          onError={() => setCoverImageSrc(null)}
        />
        {Array.isArray(hoverElement) ? hoverElement.map(e => e) : hoverElement}
      </Box>
      <CardContent className={classes.CardContent}>
        <Grid container className={classes.gridContainer}>
          <Grid item md={10}>
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Typography variant="body2" color="textSecondary">
                {createdAt &&
                  DateTime.fromMillis(parseInt(createdAt)).toFormat(
                    'yyyy-MM-dd'
                  )}
              </Typography>
              <Typography
                variant="h4"
                color="textPrimary"
                className={classes.title}
              >
                {title}
              </Typography>
              <Typography variant="body2" color="textPrimary">
                {clientName}
              </Typography>
            </Box>
          </Grid>
          {showPlayIcon && (
            <Grid item md={2}>
              <Box
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-end"
                onClick={() => !isLoading && onClickStart?.()}
              >
                <SvgIcon className={classes.playIcon}>
                  <PlayArrowIcon />
                </SvgIcon>
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default PresentationBaseTile;
