import { getCurrentOfflinePresenterReleaseBuild } from "../graphql/queries";
import { doGraphQlOperation } from "../utils/graphql-utils";
import { parseApiErrors } from "../utils/parse-api-errors";

const ENV_NAMES = {
  IS_OFFLINE_PRESENTER: 'REACT_APP_IS_OFFLINE_PRESENTER',
  LOCAL_API_URL: 'REACT_APP_OFFLINE_API_URL',
  ASSET_BUCKET_ID: 'REACT_APP_ASSET_BUCKET_ID',
  BUILD_ID: 'REACT_APP_BUILD_ID',
}

export class EnvironmentService {
  public isOfflinePresenter = !!process.env[ENV_NAMES.IS_OFFLINE_PRESENTER];
  public localApiUrl = process.env[ENV_NAMES.LOCAL_API_URL] || 'http://localhost:54093/local-api/';
  public hasInternetConnection = () => !!window.navigator?.onLine;
  public assetBucketId = process.env[ENV_NAMES.ASSET_BUCKET_ID] || '';
  public offlinePresenterDownloadPath = 'OfflinePresenter/install-hella-presenter.exe';
  public offlinePresenterInstructionsDownloadPath = 'instructions/OfflinePresenter_StepbyStep.pdf';

  public getCurrentLocalVersion() {
    return process.env[ENV_NAMES.BUILD_ID];
  }

  public async deleteLocalData() {
    try {
      const res = await fetch(this.localApiUrl + 'data-manager/delete-local-data');
      if (!res.ok) {
        console.error(res.statusText);
      }
      return await res.json();
    } catch (err) {
      console.error(err);
    }
  }

  public async getCurrentRemoteVersion() {
    try {
      const res = await doGraphQlOperation<{
        getCurrentOfflinePresenterReleaseBuild: string
      }>(getCurrentOfflinePresenterReleaseBuild);
      if (res.errors?.length) {
        throw res.errors;
      }
      return res.data?.getCurrentOfflinePresenterReleaseBuild;
    } catch (err: any) {
      throw parseApiErrors(err);
    }
  }
}