import { Box, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowLeft } from '../../../assets/img/presentation/intro-arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/img/presentation/intro-arrow-right.svg';

const useStyles = makeStyles((theme) => ({
  arrow: {
    fill: theme.palette.common.white,
    '& > *': {
      fill: theme.palette.common.white,
    }
  },
  arrowDisabled: {
    fill: theme.palette.grey[800],
    '& > *': {
      fill: theme.palette.grey[800],
    }
  },
  pdfContainer: {
    zIndex: 1000,
    pointerEvents: 'all',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
}));

export type PdfActionsProps = {
  currentPage: number;
  totalPages: number;
  onNext(): void;
  onPrevious(): void;
}

function PresentationIntroOverlayPdfActions({ currentPage, totalPages, onNext, onPrevious }: PdfActionsProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      position="absolute"
      bottom={0} left={0} right={0}
      width="100%"
      py={13}
      px={24}
      className={classes.pdfContainer}
    >
      <Box width="100%" display="flex" justifyContent="center" alignItems="center" flexWrap="nowrap">
        <Box
          width={28} height={28}
          display="flex" justifyContent="center" alignItems="center"
          style={{
            marginRight: 20,
            cursor: currentPage > 1 ? 'pointer' : 'unset',
          }}
          onClick={() => currentPage > 1 && onPrevious()}
        >
          <ArrowLeft
            className={
              currentPage > 1
                ? classes.arrow
                : classes.arrowDisabled
            }
          />
        </Box>
        <Typography variant="subtitle2" color="secondary">
          {t('presentation.pages.intro.pdf.pageLabel')} {currentPage}/{totalPages}
        </Typography>
        <Box
          width={28} height={28}
          display="flex" justifyContent="center" alignItems="center"
          style={{
            marginLeft: 20,
            cursor: currentPage < totalPages ? 'pointer' : 'unset',
          }}
          onClick={() => currentPage < totalPages && onNext()}
        >
          <ArrowRight
            className={
              currentPage < totalPages
                ? classes.arrow
                : classes.arrowDisabled
            }
          />
        </Box>
      </Box>
    </Box>
  )
}

export default PresentationIntroOverlayPdfActions;
