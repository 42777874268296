import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { hasUserRole } from "../utils/user-utils";

const useAdminRole = (role: 'admin' | 'super-admin')=>{

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    (async function checkAdminUserSession() {
      try {
        const currentUserSession = await Auth.currentSession();
        setIsAdmin(currentUserSession.isValid() && hasUserRole(role, currentUserSession));
      } catch (error) {
        setIsAdmin(false);
      }
    })();
  }, [role]);

  return {isAdmin};
  
}

export default useAdminRole;