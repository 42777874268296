import { Box, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AdminPageLayout from "../../components/Layouts/admin-page-layout";
import AdminAddContentCard from "../../components/admin/admin-add-content-card";
import AdminContentGrid from "../../components/admin/admin-content-grid";
import AdminCreateGlobalIntroDialog from "../../components/admin/global-intro-assets/admin-create-global-intro-dialog";
import AdminGlobalIntroCard from "../../components/admin/global-intro-assets/admin-global-intro-card";
import PreviewDialog from "../../components/general/preview-modal";
import ProgressBar from "../../components/general/progress-bar";
import { useAuth } from "../../hooks/use-auth";
import { useGlobalIntroApi } from "../../hooks/use-global-intro-api";
import { FileType } from "../../utils/get-FileType";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    overflowY: 'auto',
  },
}));

function AdminGlobalIntroAssetsPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { signS3RequestSync } = useAuth();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const {
    globalIntroAssetList,
    createGlobalIntroAsset,
    deleteGlobalIntroAssets,
    introProgress,
    isDeleting,
    isCreating,
  } = useGlobalIntroApi();
  const [previewDialogState, setPreviewDialogState] = useState<{ open: boolean; name?: string; src?: string; type?: FileType }>({ open: false });

  const handleCreateIntro = async ({ file, ...data }: any) => {
    await createGlobalIntroAsset(file, data);
    setCreateDialogOpen(false);
  };

  const handleDelete = (introId: string) => {
    deleteGlobalIntroAssets(introId);
  };

  const handlePreview = (name: string, src: string, type: FileType) => {
    setPreviewDialogState({ open: true, name, src, type });
  };

  return (
    <>
      <AdminPageLayout
        headline={t('adminGlobalIntroAssets.page.headline')}
        description={[t('adminGlobalIntroAssets.page.description')]}
        enableFlexBox
      >
        <Box
          width="100%"
          flex={1}
          p={32}
          className={classes.container}
        >
          <AdminContentGrid
            containerSpacing={8}
            items={[
              <AdminAddContentCard
                label={t('adminGlobalIntroAssets.createButton.label')}
                onButtonClick={() => setCreateDialogOpen(true)}
              />,
              ...(globalIntroAssetList || []).map(a => (
                <AdminGlobalIntroCard
                  introAsset={a}
                  handleDelete={() => a.introAssetId && handleDelete(a.introAssetId)}
                  handlePreview={(type) => handlePreview(a.name || '', signS3RequestSync(a.path, {}), type)}
                  isDeleting={isDeleting}
                />
              ))
            ]}
          />
        </Box>
      </AdminPageLayout>
      <AdminCreateGlobalIntroDialog
        open={createDialogOpen}
        onHandleClose={() => setCreateDialogOpen(false)}
        onSubmit={handleCreateIntro}
        submitDisabled={isCreating}
      />
      <ProgressBar
        completed={introProgress >= 100}
        trackProgress={introProgress}
      />
      <PreviewDialog
        {...previewDialogState}
        onClose={() => setPreviewDialogState({ ...previewDialogState, open: false })}
      />
    </>
  );
}

export default AdminGlobalIntroAssetsPage;
