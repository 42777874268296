import { SvgIcon } from '@material-ui/core';
import { ReactComponent as ProductOverviewIcon } from '../../../assets/img/presentation/product-overview-icon.svg';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  icon: {
    '&:hover':{
      cursor: 'pointer'
    }
  }
}));

type ProductDetailsOverviewButtonProps = {
  onClick?: ()=> void;
}
function ProductDetailsOverviewButton({onClick}: ProductDetailsOverviewButtonProps)  {

  const classes = useStyles();

  return (
    <div onClick={onClick} className={classes.icon}>
      <SvgIcon
        style={{
          width: '24px',
          height: 'auto',
          fill: 'white',
          // margin: '37px 37px 0 0',
        }}
      >
        <ProductOverviewIcon />
      </SvgIcon>
    </div>
  );
}

export default ProductDetailsOverviewButton;

