import {
  Box,
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  Paper,
  SvgIcon,
  Theme,
  Typography
} from '@material-ui/core';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ErrorIcon } from '../../assets/img/login-layout/login-error-modal-icon.svg';
import { HELLA_COLORS } from '../../theme';
import { ErrorDialogProps } from '../../types/general-types';

const useStyles = makeStyles((theme: Theme) => ({
  Paper: {
    width: 596,
    height: 492,
    backgroundColor: theme.palette.common.white,
  },
  svgIcon: {
    fill: theme.palette.error.main,
    width: 128,
    height: 111,
  },
  additionalMsg: {
    color: HELLA_COLORS.black,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function ErrorDialog(props: ErrorDialogProps) {
  const {
    headline,
    subtitle,
    errorMessage,
    infoMessage,
    additionalMessages,
    buttonAction,
    buttonText,
    forceDisplayCancelButton,
    errorSymbol = true,
    ...dialogProps
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const parsedAdditionalMessages = useMemo(() => {
    if (additionalMessages) {
      return typeof additionalMessages === 'string'
        ? [additionalMessages]
        : additionalMessages.errors?.map(e => e.message || e.errorType);
    }
    return undefined;
  }, [additionalMessages]);

  const handleClose = () => {
    if (buttonAction) {
      buttonAction();
    }
    if (dialogProps.onClose) {
      dialogProps.onClose();
    }
  };

  return (
    <Dialog {...dialogProps} onClose={() => handleClose()}>
      {!errorSymbol ? <DialogContent style={{height: 250, width: 500, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center'}}> 
         <Typography
            align="center"
            variant="body1"
            style={{ marginBottom: 12 }}
         >
            {infoMessage}
        </Typography>

       <Box display="flex">
              <Button variant="contained" onClick={() => handleClose()}>
                {buttonText || t('login.error.modal.button')}
              </Button>
       </Box>
       </DialogContent>
        :
      <DialogContent>
        <Paper className={classes.Paper} elevation={0}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Box>
              <Box width="100%">
                <Box
                  mb={20}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h2">
                    {headline || t('login.error.modal.headline')}
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    {subtitle}
                  </Typography>
                </Box>
                <Box mb={20} display="flex" justifyContent="center">
                  <SvgIcon className={classes.svgIcon}>
                    <ErrorIcon />
                  </SvgIcon>
                </Box>
              </Box>
              <Box color="error.main">
                <Typography
                  align="center"
                  variant="body1"
                  style={{ marginBottom: 12 }}
                >
                  {errorMessage}
                </Typography>

                <Box
                  height="100px"
                  minWidth="400px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  {parsedAdditionalMessages && (
                    <>
                      {isExpanded && parsedAdditionalMessages.map(e => (
                        <Typography
                          align="center"
                          variant="body1"
                        >
                          {e}
                        </Typography>
                      ))}
                      {!isExpanded ? (
                        <Typography
                          onClick={() => setIsExpanded(true)}
                          className={classes.additionalMsg}
                        >
                          show more
                        </Typography>
                      ) : (
                        <Typography
                          onClick={() => setIsExpanded(false)}
                          className={classes.additionalMsg}
                          style={{ marginTop: 12 }}
                        >
                          show less
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            <Box display="flex">
              <Button variant="contained" onClick={() => handleClose()}>
                {buttonText || t('login.error.modal.button')}
              </Button>
              {forceDisplayCancelButton &&
                <Button
                  variant="contained"
                  onClick={() => handleClose()}
                  style={{ marginLeft: 16 }}
                >
                  {t('login.error.modal.button')}
                </Button>
              }
            </Box>
          </Box>
        </Paper>
      </DialogContent>
      }
    </Dialog>
  );
}

export default ErrorDialog;
