import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { HELLA_COLORS } from '../../../theme';
import {
  CountryList,
  ListRegions,
  ProductRateList,
  UsersBusinessDivisionType
} from '../../../types/kpi-dashboard-types';
import Circle from './circle-util';
import PercentUtil from './percent-util';
import RateBar from './ratebar-util';
import TrackingCell from './tracking-cell';
import clsx from 'clsx';

const useStyles = makeStyles<Theme, TrackingCardProps>((theme) => ({
  cardContainer: {
    minWidth: '100%',
    backgroundColor: HELLA_COLORS.grey10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 20,
    height: (props) => props.cardHeight || 256,
  },
  result: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  businessGroupContainer:{
    height: 180,
    overflowY: 'hidden',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.common.black,
      borderRadius: '0px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#878587',
    },
    '&::-webkit-scrollbar-track': {
      background: '#A8A6A8',
      borderRadius: '0px',
      boxShadow: 'inset 0px 0px 0px 0px #F0F0F0',
  }
}
}));

type TrackingCardProps = {
  card?: string;
  cardHeight?: number | string;
  title?: string;
  userTotal?: number;
  userActive?: number;
  userNew?: number;
  businessDivision?: UsersBusinessDivisionType[];
  presentationTotal?: number;
  presentationNew?: number;
  presentationWithoutIntros?: number;
  presentationWithIntros?: number;
  customContentTotal?: number;
  customContentPdfTotal?: number;
  asiaList?: ListRegions;
  europeList?: ListRegions;
  northAmericaList?: ListRegions;
  totalUsersRegion?: number;
  productRateList?: ProductRateList[];
};

const TrackingCard = ({
  card,
  title,
  userTotal,
  userActive,
  userNew,
  businessDivision,
  presentationTotal,
  presentationNew,
  cardHeight,
  presentationWithoutIntros,
  presentationWithIntros,
  customContentTotal,
  customContentPdfTotal,
  asiaList,
  europeList,
  northAmericaList,
  totalUsersRegion,
  productRateList,
}: TrackingCardProps) => {
  const classes = useStyles({ cardHeight });

  type RegionsProps = {
    data: ListRegions | undefined;
    totalUsersRegion: number;
  };
  const Regions = ({ data, totalUsersRegion }: RegionsProps) => {
    return (
      <Box style={{ height: '100%', marginTop: 60 }}>
        <Typography
          style={{ marginBottom: 10, color: HELLA_COLORS.grey5 }}
          variant="h5"
        >
          {data?.region}
        </Typography>
        {data?.list?.map((c: CountryList, idx: number) => {
          return (
            <Box
              key={idx}
              display="flex"
              style={{
                marginBottom: 10,
                width: '180px',
                display: 'flex',
              }}
            >
              <Typography variant="h5" style={{ marginRight: 4, width: 80 }}>
                {c.country}
              </Typography>
              <Typography variant="h4" style={{ marginRight: 2 }}>
                {c.n}
              </Typography>
              <Typography variant="h4">
                (
                {totalUsersRegion &&
                  ((c?.n! / totalUsersRegion) * 100).toFixed(1) + `%`}
                )
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };

  const businessDivisionTotal: number | undefined = businessDivision?.reduce(
    (previousValue: number, currentValue: UsersBusinessDivisionType) => previousValue + currentValue?.sizeBusinessDivision!, 0);

  return (
    <>
      {card === 'generalActivity' && (
        <Box className={classes.cardContainer} position='relative'>
          <Typography variant="h4"> {title} </Typography>
          <Box className={classes.result}>
            <TrackingCell
              value={userTotal}
              label={'Total count'}
              circleColor="tertiaryDarker"
            />
            <PercentUtil partialValue={userActive!} totalValue={userTotal!}>
              <TrackingCell
                value={userActive}
                label={'Active user*'}
                circleColor="tertiaryMain"
              />
            </PercentUtil>

            <PercentUtil partialValue={userNew!} totalValue={userTotal!}>
              <TrackingCell
                value={userNew}
                label={'New users'}
                circleColor="tertiaryLight"
              />
            </PercentUtil>
          </Box>
          {(userActive || userActive === 0) &&
            (userTotal || userTotal === 0) &&
            (userNew || userNew === 0) && (
              <Box>
                <RateBar
                  partial={userNew}
                  total={userTotal}
                  color={HELLA_COLORS.tertiaryLight}
                />
                <RateBar
                  partial={userActive}
                  total={userTotal}
                  color={HELLA_COLORS.tertiaryMain}
                />
                <RateBar
                  partial={1}
                  total={1}
                  color={HELLA_COLORS.tertiaryDarker}
                />
              </Box>
            )}
            <Typography variant='caption' style={{marginBottom: 0, position: 'absolute', bottom: 4, right: 20}}> *User has been confirmed </Typography>
        </Box>
      )}

      {card === 'businessDivision' && (
        <Box className={classes.cardContainer}>
          <Typography variant="h4"> {title} </Typography>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box className={clsx(classes.result, classes.businessGroupContainer)} style={{ marginBottom: 24 }}>
              {businessDivision?.map((b: UsersBusinessDivisionType, idx: number) => {
                return (
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      key={idx}
                    >
                      <PercentUtil
                        partialValue={b?.sizeBusinessDivision!}
                        totalValue={businessDivisionTotal!}
                      >
                        <TrackingCell
                          key={idx}
                          value={b?.sizeBusinessDivision!}
                          label={b?.label!}
                          circleColor={
                               b?.label === 'HELLA OE: Lighting'
                              ? 'tertiaryDarkest'
                              : b?.label === 'HELLA OE: Electronics'
                              ? 'tertiaryDarker'
                              : b?.label === 'HELLA Lifecycle Solutions: IAM'
                              ? 'tertiaryDark'
                              : b?.label === 'HELLA Lifecycle Solutions: SOE'
                              ? 'tertiaryMain'
                              : b?.label === 'Faurecia Clean Mobility: FCM'
                              ? 'tertiaryLight'
                              : undefined
                          }
                        />
                      </PercentUtil>
                    </Box>
                  </>
                );
              })}
            </Box>

            <Box display="flex">
              <RateBar
                partial={
                  businessDivision?.filter((b: any) => b.label === 'GL: Lighting')[0]?.sizeBusinessDivision
                }
                total={businessDivisionTotal}
                color={HELLA_COLORS.tertiaryDarker}
                height={20}
              />
              <RateBar
                partial={
                  businessDivision?.filter(
                    (b: any) => b.label === 'GE: Electronics'
                  )[0]?.sizeBusinessDivision
                }
                total={businessDivisionTotal!}
                color={HELLA_COLORS.tertiaryMain}
                height={20}
              />
              <RateBar
                partial={
                  businessDivision?.filter((b: any) => b.label === 'Others')[0]
                    ?.sizeBusinessDivision
                }
                total={businessDivisionTotal!}
                color={HELLA_COLORS.tertiaryLight}
                height={20}
              />
            </Box>
          </Box>
        </Box>
      )}

      {card === 'region' && (
        <Box className={classes.cardContainer}>
          <Typography variant="h4"> {title} </Typography>
          <Box className={classes.result} style={{ height: '100%' }}>
            {totalUsersRegion && (
              <>
                <Regions data={asiaList} totalUsersRegion={totalUsersRegion} />
                <Regions
                  data={europeList}
                  totalUsersRegion={totalUsersRegion}
                />
                <Regions
                  data={northAmericaList}
                  totalUsersRegion={totalUsersRegion}
                />
              </>
            )}
          </Box>
        </Box>
      )}

      {card === 'generalActivityPresentations' && (
        <Box className={classes.cardContainer}>
          <Typography variant="h4"> {title} </Typography>
          <Box className={classes.result}>
            <TrackingCell
              value={presentationTotal}
              label={'Total count'}
              circleColor="tertiaryDarker"
            />
            <PercentUtil
              partialValue={presentationNew!}
              totalValue={presentationTotal!}
            >
              <TrackingCell
                value={presentationNew}
                label={'New presentations'}
              />
            </PercentUtil>
          </Box>
          {(presentationNew || presentationNew === 0) &&
            (presentationTotal || presentationTotal === 0) && (
              <Box>
                <RateBar
                  partial={presentationNew}
                  total={presentationTotal}
                  color={HELLA_COLORS.tertiaryLight}
                />
                <RateBar
                  partial={1}
                  total={1}
                  color={HELLA_COLORS.tertiaryDarker}
                />
              </Box>
            )}
        </Box>
      )}

      {card === 'intros' && (
        <Box className={classes.cardContainer}>
          <Typography variant="h4"> {title} </Typography>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Box className={classes.result} style={{ marginBottom: 24 }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <PercentUtil
                  partialValue={presentationWithoutIntros!}
                  totalValue={
                    presentationWithoutIntros! + presentationWithIntros!
                  }
                >
                  <TrackingCell
                    value={presentationWithoutIntros}
                    label={'With intro'}
                    circleColor="tertiaryDarker"
                  />
                </PercentUtil>
              </Box>
              <PercentUtil
                partialValue={presentationWithIntros!}
                totalValue={
                  presentationWithoutIntros! + presentationWithIntros!
                }
              >
                <TrackingCell
                  value={presentationWithIntros}
                  label={'Without intro'}
                  circleColor="tertiaryLight"
                />
              </PercentUtil>
            </Box>

            {(presentationWithoutIntros || presentationWithoutIntros === 0) &&
              (presentationWithIntros || presentationWithIntros === 0) && (
                <Box display="flex">
                  <RateBar
                    partial={presentationWithoutIntros}
                    total={presentationWithoutIntros + presentationWithIntros}
                    color={HELLA_COLORS.tertiaryDarker}
                    height={20}
                  />
                  <RateBar
                    partial={presentationWithIntros}
                    total={presentationWithoutIntros + presentationWithIntros}
                    color={HELLA_COLORS.tertiaryLight}
                    height={20}
                  />
                </Box>
              )}
          </Box>
        </Box>
      )}

      {card === 'customContent' && (
        <Box className={classes.cardContainer}>
          <Typography variant="h4"> {title} </Typography>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Box className={classes.result} style={{ marginBottom: 24 }}>
              <TrackingCell
                value={customContentTotal}
                label={'Total count'}
                circle={false}
              />

              <Box>
                <Box display="flex">
                  <Circle circleColor="tertiaryDarker" />
                  <Typography variant="h5" style={{ width: 100 }}>
                    <Box
                      style={{
                        width: 80,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span style={{ width: 30 }}>PDF:</span>{' '}
                      <strong>{customContentPdfTotal}</strong>{' '}
                    </Box>
                  </Typography>
                </Box>

                <Box display="flex" style={{ marginTop: 6 }}>
                  <Circle circleColor="tertiaryLighter" />
                  <Typography variant="h5">
                    <Box
                      style={{
                        width: 80,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span style={{ width: 30 }}>Video:</span>
                      <strong>
                        {customContentTotal &&
                          customContentPdfTotal &&
                          customContentTotal - customContentPdfTotal}
                      </strong>
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>

            {(customContentTotal || customContentTotal === 0) &&
              (customContentPdfTotal || customContentPdfTotal === 0) && (
                <Box display="flex">
                  <RateBar
                    partial={customContentPdfTotal}
                    total={customContentTotal}
                    color={HELLA_COLORS.tertiaryDarker}
                    height={20}
                  />
                  <RateBar
                    partial={customContentTotal - customContentPdfTotal}
                    total={customContentTotal}
                    color={HELLA_COLORS.tertiaryLight}
                    height={20}
                  />
                </Box>
              )}
          </Box>
        </Box>
      )}

      {card === 'productRate' && (
        <Box className={classes.cardContainer}>
          <Typography variant="h4"> {title} </Typography>

          <Box
            className={classes.result}
            style={{
              marginTop: 30,
              color: HELLA_COLORS.grey5,
              paddingLeft: 50,
              paddingRight: 50,
            }}
          >
            <Typography style={{ width: 100 }}> Product Name </Typography>
            <Typography>Total Usage</Typography>
            <Typography>Usage Rate</Typography>
          </Box>

          <Box
            height="100%"
            style={{
              marginTop: 30,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingLeft: 50,
              paddingRight: 50,
            }}
          >
            {productRateList?.map((product: any) => {
              return (
                <Box className={classes.result}>
                  <Typography style={{ width: 100 }}>
                    {product.product}
                  </Typography>
                  <Typography>
                    <strong>{product?.n + ' x'}</strong>{' '}
                  </Typography>
                  <Typography>
                    <strong>
                      {((product?.n / presentationTotal!) * 100).toFixed(1) +
                        `%`}
                    </strong>
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
};

export default TrackingCard;
