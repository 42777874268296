import { UserAttributesConfig } from "../types/user-attribute-types";

export function useUserAttributes(): UserAttributesConfig {
  return {
    region: {
      select: [
        {
          name: 'Asia',
          subSelect: ['China', 'India', 'Japan', 'Korea', 'Others'],
        },
        {
          name: 'Europe',
          subSelect: ['Europe'],
        },
        {
          name: 'North and South America',
          subSelect: ['USA', 'Mexico', 'Others']
        },
        // {
        //   name: 'Others',
        //   customValue: true,
        // }
      ],
    },
    businessDivision: {
      select: [
        { name: 'HELLA OE: Electronics' },
        { name: 'HELLA OE: Lighting' },
        { name: 'HELLA Lifecycle Solutions: IAM' },
        { name: 'HELLA Lifecycle Solutions: SOE' },
        { name: 'Forvia Faurecia' },
      ]
    },
    businessNeed: {
      select: [
        { name: 'Customer Presentation - Product Intro' },
        { name: 'Customer Presentation - Technology deep-dive' },
        { name: 'Presentation to supplier / partner' },
        { name: 'Internal Presentation / Training' },
        { name: 'Public Presentation' },
      ]
    },
    department: {
      dependsOn: [
        { item: 'businessDivision', value: 'HELLA OE: Electronics' },
        { item: 'businessDivision', value: 'HELLA OE: Lighting' },
      ],
      select: [
        // Electronics
        { name: 'E-AD: Automated Driving', dependsOn: [{ item: 'businessDivision', value: 'HELLA OE: Electronics' }] },
        { name: 'E-AE: Advanced Engineering', dependsOn: [{ item: 'businessDivision', value: 'HELLA OE: Electronics' }] },
        { name: 'E-B: Body', dependsOn: [{ item: 'businessDivision', value: 'HELLA OE: Electronics' }] },
        { name: 'E-C-AC: Actuators', dependsOn: [{ item: 'businessDivision', value: 'HELLA OE: Electronics' }] },
        { name: 'E-C-SE: Sensors', dependsOn: [{ item: 'businessDivision', value: 'HELLA OE: Electronics' }] },
        { name: 'E-EN: Energy', dependsOn: [{ item: 'businessDivision', value: 'HELLA OE: Electronics' }] },
        { name: 'E-S: Sales', dependsOn: [{ item: 'businessDivision', value: 'HELLA OE: Electronics' }] },
        { name: 'Regional Technical Center', dependsOn: [{ item: 'businessDivision', value: 'HELLA OE: Electronics' }] },

        // Lighting
        { name: 'L-TH: Lighting Technology Hub', dependsOn: [{ item: 'businessDivision', value: 'HELLA OE: Lighting' }] },
        { name: 'L-CBL: Car Body Lighting', dependsOn: [{ item: 'businessDivision', value: 'HELLA OE: Lighting' }] },
        { name: 'L-H: Headlamps', dependsOn: [{ item: 'businessDivision', value: 'HELLA OE: Lighting' }] },
        { name: 'L-IL: Interior Lighting', dependsOn: [{ item: 'businessDivision', value: 'HELLA OE: Lighting' }] },
        { name: 'L-R: Rear Lamps', dependsOn: [{ item: 'businessDivision', value: 'HELLA OE: Lighting' }] },
        { name: 'L-S: Sales', dependsOn: [{ item: 'businessDivision', value: 'HELLA OE: Lighting' }] },
        { name: 'Regional Technical Center', dependsOn: [{ item: 'businessDivision', value: 'HELLA OE: Lighting' }] },

        { name: 'Others', customValue: true },
      ]
    }
  }
}