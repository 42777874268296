import { ApiError } from "../types/user-api-types";

export function parseApiErrors(errors: any): ApiError {
  console.log(errors)
  switch (true) {
    case Array.isArray(errors):
      return {
        errors: errors?.map((e: any) => ({
          errorType: 'Error',
          message: e.message,
        }))
      };
    case typeof errors === 'object' && Array.isArray(errors.errors):
      return {
        errors: errors.errors?.map((e: any) => ({
          errorType: 'Error',
          message: e.message,
        })) || []
      }
    default:
      return { errors: [{ errorType: 'Error', message: JSON.stringify(errors) }] }
  }
}